export class patientSetModel {
  religionList?: dataObj[];
  nationalityList?: dataObj[];
  raceList?: dataObj[];
  occupationList?: dataObj[];
  patientCategoryList?: dataObj[];
  contactRelationList?: dataObj[];
  martialStatusList?: dataObj[];
  bloodGroupList?: dataObj[];
  preferedLanguageList?: dataObj[];
  facultyList?: dataObj[];
  primaryIdentifierList?: IdentifierList[];
  secondaryIdentifierList?: IdentifierList[];
  educationList?: dataObj[];
  birthLocation?: dataObj[];
  tinNo?:string;
  motherName?: string;
  personalFax?: string;
  workFax?: string;
  registerAgainst?:any;
  emailId?: string;
  homeContactNo?: string;
  workContactNo?: string;
  mobileNumber?: mobileNumber;
  title?: string;
  genderList?: dataObj[];
  extendedAddress: address;
  correspondingAddress: address;
  prefixList?: dataObj[];
  dateOfBirth?: Date;
  birthTime?:string
  firstName?: string;
  patientName?:string;
  middleName?:string;
  lastName?:string;
  familyName?:string
  idNo?: string;
  secondaryNo?: string;
  vip?: string;
  deceasedPatient: deceased;
  real: boolean;
  idIssueCountryList?: dataObj[];
  patientIdentifierList?:any[];
  expiryDate: Date;
  patientID: string;
  ethnicList:any[];
  blocked:boolean;
  ctos:boolean;
  sameAddress:boolean;
  newBornBaby:boolean;
  motherReference:motherReference;
  temporaryPatientReference?:dataObj;
  countryCode:string;
  photo:any;
  patientNotes:patientNotes[];
  source:dataObj[];
  primaryIdentifierListModified: boolean;
	secondaryIdentifierListModified: boolean;
  employeeDetails?: employeeDetails;
  employeeStatusList?: dataObj[];
  healthTouristDetailsDTO?: dataObj[];
  isStaffActive?:boolean;
  extendedAdmissionRequestId?:string;
}

export class IdentifierList {
  system?: string;
  code?: string;
  display?: string;
  value?: string;
  status?: boolean;
  expiryDate?: Date;
  issuingCountryList?:any[];
  staffReferenceId?:string;
}

export class address {
  addressLine?: string;
  countryList?: any[];
  cityList?: any[];
  stateList?: any[];
  areaList?: any[];
  postCode?: string;
}
export class deceased{
  deceased?: boolean=false;
  markedBy?: string="";
  markedOn?: Date
} 

export class mobileNumber {
  countryCode?:string="";
  mobileNumber: string="";
  relationList?: dataObj[]
}

export class dataObj {
  system?: string;
  code?: string;
  display?: string;
}

export class motherReference{
  referenceId: string="";
	referenceName: string="";
	referenceNumber: string="";
}

export class patientNotes{
  note: string="";
  addedOn: Date;
  addedBy: string="";
  status: boolean
}

export class employeeDetails {
  employmentStatus?: dataObj[];
  employer?: dataObj[];
  other?: string;
  employmentNo?: string;
  workingFrom?: number;
  contactNo?: string;
  employeeAdress?: employeeAdress;
}

export class employeeAdress {
  addressLine?: string;
  postCode?: string;
  countryList?: dataObj[];
  stateList?: dataObj[];
  cityList?: dataObj[];
  areaList?: dataObj[];
}
export const secondIdType = {
  Staff : "Staff",
  Doctor : "Doctor",
}
