<!-- First home screen -->
<div class="home-div" *ngIf="currentStep == 'Step 1'">
    <h1>Welcome to <br> HATI International</h1>
    <div class="footer">
        <img src="../../../../../assets/images/hati-logo.png" alt="Check-In Icon" class="icon"/>
        <a class="start-btn" (click)="currentStep = 'Step 2'">Click Here To Start</a>
    </div>
</div>

<!-- step 1 -->
<div *ngIf="currentStep == 'Step 2'" class="background-div">
      <header>
        <div class="logo">
          <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
        </div>
        <div class="language-selector">
          <mat-form-field appearance="outline" floatLabel="never" class="compact-select">
            <mat-select [(ngModel)]="selectedLanguage">
              <mat-select-trigger>
                <mat-icon *ngIf="selectedLanguage" aria-hidden="false" aria-label="{{ getSelectedIcon() }}">
                  {{ getSelectedIcon() }}
                </mat-icon>
                <span>{{ getSelectedLabel() }}</span>
              </mat-select-trigger>
              <mat-option *ngFor="let language of languages" [value]="language.code">
                <mat-icon>{{ language.icon }}</mat-icon>
                {{ language.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <select [(ngModel)]="selectedLanguage">
            <option *ngFor="let option of languages" [value]="option.code">
              <mat-icon>language</mat-icon>{{ option.name }}
            </option>
          </select> -->
        </div>
      </header>  
      <div class="step2-div">
        <div class="checkin-options">
          <h2 style="letter-spacing: 30px;">Welcome</h2>
          <div class="cards">
            <mat-card class="checkin-card" style="background-color: #5f686f;" (click)="nextStep('check-in')">
              <img src="../../../../../assets/images/CheckIn.png" alt="Check In">
              <h3>Check In</h3>
            </mat-card>
            <mat-card class="checkin-card" style="background-color: #1f4966;" (click)="nextStep('walk-in')">
              <img src="../../../../../assets/images/walking.png" alt="Walk In">
              <h3>Walk In</h3>
            </mat-card>
          </div>
        </div>
      </div>
      <footer class="step-footer">
        <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
      </footer>
</div>

<!-- check in step -->
<div *ngIf="currentStep == 'check-in'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button class="hover-button" (click)="currentStep = 'Step 2'">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="checkin-div">
    <div class="checkin-options">
      <h2 style="color:#fff;">Select the check in option</h2>
      <div class="checkin-cards">
        <mat-card class="option-card" style="background: #9adbcc;">
          <img src="../../../../../assets/images/IC_card.png" alt="Scan IC/Passport" class="default-image">
          <img src="../../../../../assets/images/Hover_IC.png" alt="Scan IC/Passport" class="hover-image">
          <h3>Scan IC/Passport</h3>
        </mat-card>
        <mat-card class="option-card" (click)="openBarcodeScanDialog()" style="background: #e3ffd6;">
          <img src="../../../../../assets/images/qr-scan.png" alt="Scan QR" class="default-image">
          <img src="../../../../../assets/images/hoverqr-scan.png" alt="Scan QR" class="hover-image">
          <h3>Scan QR</h3>
        </mat-card>
        <mat-card class="option-card" (click)="currentStep = 'manual-search'" style="background: #ffe5ec;">
          <img src="../../../../../assets/images/Search_hover.png" alt="Manual Search" class="default-image">
          <img src="../../../../../assets/images/Search.png" alt="Manual Search" class="hover-image">
          <h3>Manual Search</h3>
        </mat-card>
      </div>
    </div>
  </div>
  <footer class="step-footer">
    <p style="color:#fff;">© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- walk in step -->
<div *ngIf="currentStep == 'walk-in'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="currentStep = 'Step 2'">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="checkin-div">
    <div class="checkin-options">
      <h2 style="color:#fff;">Select the walk in option</h2>
      <div class="checkin-cards">
        <mat-card class="option-card" style="background: #9adbcc;">
          <img src="../../../../../assets/images/IC_card.png" alt="Scan IC/Passport" class="default-image">
          <img src="../../../../../assets/images/Hover_IC.png" alt="Scan IC/Passport" class="hover-image">
          <h3>Scan IC/Passport</h3>
        </mat-card>
        <mat-card class="option-card" (click)="getNewPatientForm();getAppConfigList();patientType='TEMP'" style="background: #e3ffd6;">
          <img src="../../../../../assets/images/HoverNew-user.png" alt="Scan QR" class="default-image">
          <img src="../../../../../assets/images/New-user.png" alt="Scan QR" class="hover-image">
          <h3>New Patient</h3>
        </mat-card>
        <mat-card class="option-card" (click)="currentStep = 'manual-search';patientType='REG'" style="background: #ffe5ec;">
          <img src="../../../../../assets/images/Search_hover.png" alt="Manual Search" class="default-image">
          <img src="../../../../../assets/images/Search.png" alt="Manual Search" class="hover-image">
          <h3>Manual Search</h3>
        </mat-card>
      </div>
    </div>
  </div>
  <footer class="step-footer">
    <p style="color:#fff;">© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>


<!-- manual search  -->
<div *ngIf="currentStep == 'manual-search'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="backStep()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="manual-search-div">
    <div class="patient-box" style="display: flex;justify-content: center;align-items: center;" *ngIf="!isShown">
      <a (click)="getNewPatientForm();getAppConfigList();patientType='TEMP'"><h2 style="color: #1f4966;">New Patient</h2></a>
    </div>
    
    <ng-container *ngFor="let patient of patientDetails">
    <div class="patient-box patient-data" *ngIf="isShown">
      <img [src]="patient.image" alt="Patient Image" class="patient-image" />
      <div class="card-content">
        <table class="detail-table">
          <tr>
            <td>Name &nbsp;&nbsp;</td>
            <td>: &nbsp;&nbsp;{{ patient.patientName }}</td>
          </tr>
          <tr>
            <td>IC/Passport &nbsp;&nbsp;</td>
            <td>: &nbsp;&nbsp;{{patient.identificationNo}}</td>
          </tr>
          <tr>
            <td>Phone Number &nbsp;&nbsp;</td>
            <td>: &nbsp;&nbsp;{{ patient.mobileNo }}</td>
          </tr>
          <tr>
            <td>Age &nbsp;&nbsp;</td>
            <td>: &nbsp;&nbsp;{{ patient.age }}</td>
          </tr>
          <tr>
            <td>MRN Number &nbsp;&nbsp;</td>
            <td>: &nbsp;&nbsp;{{ patient.mrn }}</td>
          </tr>
        </table>
        <button mat-raised-button class="confirm-button" (click)="getConfirmation()" style="margin-top: 10vh;">CONFIRM</button>
      </div>
        
    </div>
    </ng-container>

    <div class="search-bar">
      <mat-form-field appearance="outline" class="mrn-search">
        <input [(ngModel)]="mrnNumber" matInput placeholder="Insert NRIC / Passport / MRN number" />
        <button mat-icon-button matSuffix (click)="getPatient()">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- new patient -->
<div *ngIf="currentStep == 'new-patient'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="currentStep = 'Step 2'">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="manual-search-div">
    <!-- registration form -->
    <div class="registration-container">
      <h2 style="text-align: left;">Registration Page</h2>
      <div class="image-upload">
        <img src="../../../../../assets/images/noavatar_new.png" alt="Upload Icon" class="upload-icon" />
      </div>
      <form [formGroup]="appointmentForm">
        
        <mat-form-field appearance="outline" class="full-width search-field">
          <mat-label>Name</mat-label>
         <input matInput formControlName="name" type="text" maxlength="100"  
          oninput="this.value = this.value.toUpperCase()"/>
          <!-- <mat-error *ngIf="registrationForm.form.controls['name']?.invalid && registrationForm.form.controls['name']?.touched">
            Name is required
          </mat-error> -->
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="full-width search-field">
          <mat-label>ID Type</mat-label>
          <mat-select formControlName="idType"
          (selectionChange)="primaryIdentificationOnChange($event)">
            <ngx-mat-select-search
              [formControl]="identificationTypeCtrl"
              ngDefaultControl
              placeholderLabel="Search"
            ></ngx-mat-select-search>
            <mat-option>None</mat-option>
            <mat-option *ngFor="let type of filterIdentifictionTypeList" [value]="type">
              {{ type.desc }}
            </mat-option>
          </mat-select>
          <!-- <mat-error *ngIf="registrationForm.form.controls['icNumber']?.invalid && registrationForm.form.controls['icNumber']?.touched">
            IC/Passport Number is required
          </mat-error> -->
        </mat-form-field>
    
        <mat-form-field appearance="outline" class="full-width search-field">
          <mat-label>ID No.</mat-label>
          <input matInput formControlName="idNo" type="text" maxlength="25"
                      [pattern]="primaryIdRegex" 
                      (keyup)="primaryNumberOnKeyUp($event.target.value)"/>
        </mat-form-field>

        <div class="form-group-inline">
          <mat-form-field appearance="outline" class="half-width search-field">
            <mat-label>Code</mat-label>
            <mat-select formControlName="countryCode" 
            (itemSelected)="setPatternToMobileNumber($event)">
              <ngx-mat-select-search
                [formControl]="countryCodeCtrl"
                ngDefaultControl
                placeholderLabel="Search"
              ></ngx-mat-select-search>
              <mat-option>None</mat-option>
              <mat-option *ngFor="let type of filterCountryCodeList" [value]="type.code">
                {{ type.desc }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="half-width search-field">
            <mat-label>Mob No</mat-label>
            <input matInput formControlName="mobNo" [minlength]="mobileNoMin"
            [maxlength]="mobileNoMax" oninput="this.value = this.value.replace(/[^0-9]/g, '')"/>
          </mat-form-field>
        </div>

          <!-- <mat-form-field appearance="outline" class="half-width">
            <mat-label>Age</mat-label>
            <mat-select [(ngModel)]="userData.age" name="age" required>
              <mat-option *ngFor="let age of ages" [value]="age">{{ age }}</mat-option>
            </mat-select>
            <!-- <mat-error *ngIf="registrationForm.form.controls['age']?.invalid && registrationForm.form.controls['age']?.touched">
              Age is required
            </mat-error> --
          </mat-form-field> -->
    
          <mat-form-field appearance="outline" class="half-width search-field">
            <mat-label>Gender</mat-label>
            <mat-select formControlName="gender">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let type of genderList" [value]="type">
                {{ type.desc }}
              </mat-option>
              </mat-select>
            <!-- <mat-error *ngIf="registrationForm.form.controls['gender']?.invalid && registrationForm.form.controls['gender']?.touched">
              Gender is required
            </mat-error> -->
          </mat-form-field>
    
        <button mat-raised-button type="button" class="submit-button" (click)="createTempPatient()">
          SUBMIT
        </button>
      </form>
    </div>
  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- appointment list -->
<div *ngIf="currentStep == 'appointment-list'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="goBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="booking-div">
    <!-- appointment list cards -->
    <h2 style="color: #455261;">Here is your booking</h2>
    <div class="booking-list">
      <div *ngFor="let booking of bookings" class="booking-card" (click)="getBookingDetail(booking)">
        <div class="booking-icon">
          <mat-icon>account_circle</mat-icon>
        </div>
        <div class="booking-details">
          <h3 style="margin-bottom:1vh;">#{{ booking.appointmentNo }}</h3>
          <div class="details-columns">
            <div class="left-column">
              <table class="detail-table">
                <tr>
                  <td>Name &nbsp;&nbsp;</td>
                  <td>: &nbsp;&nbsp;{{ booking.patientName }}</td>
                </tr>
                <tr>
                  <td>IC/Passport Number&nbsp;&nbsp;</td>
                  <td>: &nbsp;&nbsp;{{ booking.identificationNo }}</td>
                </tr>
                <tr>
                  <td>Contact Number &nbsp;&nbsp;</td>
                  <td>: &nbsp;&nbsp;{{ booking.mobileNo }}</td>
                </tr>
              </table>
            </div>
            <div class="right-column">
              <table class="detail-table">
                <tr>
                  <td>Date &nbsp;&nbsp;</td>
                  <td>: &nbsp;&nbsp;{{ booking.appointmentDateTime | date: 'dd-MM-yyyy' }}</td>
                </tr>
                <tr>
                  <td>Slot Time &nbsp;&nbsp;</td>
                  <td>: &nbsp;&nbsp;{{ booking.appointmentDateTime | date: 'HH:mm'}}</td>
                </tr>
                <tr>
                  <!-- <td>Reason for visit &nbsp;&nbsp;</td>
                  <td>: &nbsp;&nbsp;{{ booking.reason }}</td> -->
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- appointment list -->
<div *ngIf="currentStep == 'booking-confirmation'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="goBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="manual-search-div">
    <!-- booking confirmation -->
    <mat-card class="booking-confirm-card">
      <mat-card-header>
        <mat-card-title class="card-title">Booking Informations</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <table class="detail-table">
          <tr>
            <td class="info-item">Name &nbsp;&nbsp;</td>
            <td class="value">: &nbsp;&nbsp;{{bookingDetail.patientName}}</td>
          </tr>
          <tr>
            <td class="info-item">IC/Passport &nbsp;&nbsp;</td>
            <td class="value">: &nbsp;&nbsp;{{bookingDetail.identificationNo}}</td>
          </tr>
          <tr>
            <td class="info-item">Age &nbsp;&nbsp;</td>
            <td class="value">: &nbsp;&nbsp;{{bookingDetail.age}}</td>
          </tr>
          <tr>
            <td class="info-item">Gender &nbsp;&nbsp;</td>
            <td class="value">: &nbsp;&nbsp;{{bookingDetail.gender}}</td>
          </tr>
          <tr>
            <td class="info-item">Contact No. &nbsp;&nbsp;</td>
            <td class="value">: &nbsp;&nbsp;{{bookingDetail.mobileNo}}</td>
          </tr>
          <tr>
            <td class="info-item">Doctor &nbsp;&nbsp;</td>
            <td class="value">: &nbsp;&nbsp;{{bookingDetail.doctor?.desc}}</td>
          </tr>
          <tr>
            <td class="info-item">Department &nbsp;&nbsp;</td>
            <td class="value">: &nbsp;&nbsp;{{bookingDetail.department?.desc}}</td>
          </tr>
          <tr>
            <td class="info-item"> Slot Time &nbsp;&nbsp;</td>
            <td class="value">: &nbsp;&nbsp;{{bookingDetail.appointmentDateTime | date: 'HH:mm'}}</td>
          </tr>
        </table>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button class="confirm-button" (click)="getToken(bookingDetail)">CONFIRM</button>
        <br/>
      </mat-card-actions>
    </mat-card>
    
  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- book-appointment -->
<div *ngIf="currentStep == 'book-appointment'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="currentStep = 'walk-in'">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="manual-search-div">
    <!-- book an appointment & consultation buttons add -->
    <div class="checkin-options">
      <h3 class="title-text">Choose your preferences</h3>
      <div class="appointment-cards">
        <mat-card class="appointment-card" (click)="getServices(true)" style="background-color: #1f4966;">
          <h3 class="card-text">Book an appointment</h3>
        </mat-card>
        <mat-card class="appointment-card" (click)="getServices()" style="background-color: #5f686f;">
          <h3 class="card-text">Consultation</h3>
        </mat-card>
      </div>
    </div>
  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- service list -->
<div *ngIf="currentStep == 'select-service'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="goBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="manual-search-div">
    <!-- service list -->
    <div class="header">
      <mat-form-field appearance="outline" class="search-field">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" [(ngModel)]="searchService" (input)="onSearchServiceChange()"/>
      </mat-form-field>
    </div>
    
    <h2>Choose any of the service below to proceed</h2>
    
    <div class="service-div">
    <div class="service-cards">
      <ng-container *ngFor="let service of filteredServices;let i= index">
      <div class="service-card" (click)="getWalkIntoken(service)" 
      [ngStyle]="{ 'background-color': backgroundColors[i % backgroundColors.length] }">
        <img src="../../../../../assets/images/master-icons/billing.png" alt="Billing Icon" class="service-icon" />
        <div class="service-info">
          <h3>{{service.desc}}</h3>
          <p>Blling, invoicing, payment processing</p>
        </div>
      </div>
    </ng-container>
    </div>
    </div>
    
  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- Department list -->
<div *ngIf="currentStep == 'select-department'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="goBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="manual-search-div">
    <!-- Department list -->
    <div class="header">
      <mat-form-field appearance="outline" class="search-field">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" [(ngModel)]="searchDept" (input)="onSearchDeptChange()"/>
      </mat-form-field>
    </div>
    
    <h2>Choose any of the department below to proceed</h2>
    
    <div class="service-div">
    <div class="service-cards">
      <ng-container *ngFor="let dept of filteredDepartments;let i= index">
      <div class="service-card" (click)="getDoctorList(dept)"
      [ngStyle]="{ 'background-color': backgroundColors[i % backgroundColors.length] }">
        <img src="../../../../../assets/images/master-icons/billing.png" alt="Billing Icon" class="service-icon" />
        <div class="service-info">
          <h3>{{dept.desc}}</h3>
          <p>Blling, invoicing, payment processing</p>
        </div>
      </div>
    </ng-container>
    </div>
    </div>
  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- Doctor List -->

<div *ngIf="currentStep == 'select-doctor'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="goBack()">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="manual-search-div" style="padding: 1vw 2vw;
  flex-direction: row;
  align-items: normal;">
    <!-- Doctor Selection Column -->
    <div class="doctor-selection">
      <mat-form-field appearance="outline" class="search-field">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" [(ngModel)]="searchDoctor" (input)="onSearchDoctorChange()"/>
      </mat-form-field>

      <h5>Find your doctor here:</h5>
      <div class="doctor-list">
        <div *ngFor="let doctor of filteredDoctors; let i=index" class="doctor-card"
        [ngClass]="{'selected-doctor': selectedDoctorIndex === i}">
          <mat-card class="doctor-card-content" (click)="getSlot(doctor, i)"
          [ngStyle]="{ 'background-color': backgroundColors[i % backgroundColors.length] }">
            <mat-icon>person</mat-icon>
            <div class="doctor-info">
              <p>Name : &nbsp; {{ doctor.desc }}</p>
              <p class="availability">{{ doctor.available }}</p>
            </div>
          </mat-card>
        </div>
      </div>
    </div>

    <!-- Time Slot Selection Column -->
    <div class="time-slot-section">
      <h2>Description</h2>
      <p *ngIf="selectedDoctor" style="font-size: 1.2vw;">Select time to visit Dr. {{selectedDoctor?.desc}}</p>

      <h2 *ngIf="selectedDoctor">Time Slot</h2>
      <div class="time-slot-grid" *ngIf="selectedDoctor && slotList.length > 0"
        [ngStyle]="needsScrolling ? { height: '58vh', overflowY: 'auto' } : {}">
        <div class="time-slot"*ngFor="let slot of slotList; let i=index" (click)="selectSlot(i, slot)"
        [ngClass]="{'selected-slot': selectedSlotIndex == i}" >
          {{ slot.start  | date:'HH:mm aa'  }}
        </div>
      </div>
      <div *ngIf="selectedDoctor && !isSlots">
        <h2 style="font-size: 1.5rem;">No Slots Available</h2>
      </div>
    
      <button mat-raised-button class="confirm-button slot-confirm" *ngIf="selectedDoctor && slotList.length > 0"
        (click)="getDetails()">CONFIRM</button>
    </div>
  </div>
  <!-- <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer> -->
</div>

<!-- print token -->
<div *ngIf="currentStep == 'print-token'" class="background-div" style="padding: 0px;">
  <div class="manual-search-div">
    <!-- <mat-card class="ticket-card"> -->
      <div style="float: right;" *ngIf="loading">
        <mat-progress-spinner
        class="progress-spinner"
          diameter="50"
          [value]="value"
          mode="determinate">
          </mat-progress-spinner>
          
      </div>
      <div class="ticket-content">
        <div class="ticket-number">
          <span class="number">{{ tokenNo }}</span> 
        </div>
        <div class="qr-code">
          <!-- Add QR Code here, for example using an image or Angular QR code library -->
          <img src="../../../../../assets/images/qr-code1.png" alt="QR Code" />
        </div>
      </div>
      <p class="message">You will be attend soon. Thank you!</p>
      <button mat-raised-button class="confirm-button">PRINT</button>
    <!-- </mat-card> -->
  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>

<!-- scan qr -->
<div *ngIf="currentStep == 'scan-qr'" class="background-div">
  <header>
    <div class="logo">
      <img src="../../../../../assets/images/unsplash/Medcare-Logo.png" style="height: 7vh;"/>
    </div>
    <div class="back-button">
      <button mat-raised-button (click)="currentStep = 'check-in'">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>
  </header>  
  <div class="scan-qr-div">
    
    <h2>Already Book?</h2>
    <div class="bodyCreatePasscode">
      <button mat-raised-button color="accent" (click)="onCameraStart(action)" *ngIf="!action.isStart" style="width: 100%;">Open Camera</button>
      <button mat-raised-button color="warn" (click)="action.stop()" *ngIf="action.isStart" style="width: 100%;">Stop Camera</button>
      <mat-card class="matcard-camera mat-elevation-z4">
        <ngx-scanner-qrcode
          #action="scanner"
          (event)="onBarcodeScanned(action, $event)"
          [isBeep]="false"
          [constraints]="{audio:false,video:true}"
          [canvasStyles]="[{ lineWidth: 1, strokeStyle: 'green', fillStyle: '#55f02880' },{ font: '15px serif', strokeStyle: '#fff0', fillStyle: '#ff0000' }]"
        ></ngx-scanner-qrcode>
        <p *ngIf="action.isLoading"> ⌛ Loading... </p>
      </mat-card>
    </div>
    
    <h2 style="font-size: 1.5rem;">Hold your QR code in front of the scanner.</h2>

  </div>
  <footer class="step-footer">
    <p>© 2024 Hati International Sdn Bhd. All Rights Reserved.</p>
  </footer>
</div>