import { throwError, Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { map, catchError, switchMap, filter, take, finalize } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";

import {
  HttpClient,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { EnvoirnmentService } from "../services/envoirnment.service";
import { HttpHeaders } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { APPLICATIONSERVICE } from "../constants/application-service";
import { MICROSERVICES } from "medcare-core-ui";

@Injectable()
export class AppBaseService implements HttpInterceptor {
  protected resourceUrl: string;
  private baseUrl: string;
  private isRefreshingToken = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  httpOptions = {
    headers: new HttpHeaders({
      responseType: "json",
    }),
  };

  patchTempResource: any;
  putTempResource: any;
  

  constructor(
    private http: HttpClient,
    public snackBar: MatSnackBar,
    private toastr: ToastrService,
    private router: Router,
    private env: EnvoirnmentService, //@Inject("env") private env
  ) {
    this.baseUrl = env.apiUrl;
  }

 
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Apply custom headers to the request
    request = this.addCustomHeaders(request);
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401) {
          return this.handleUnauthorizedError(request, next);
        } else {
          this.displayToastMessage(error, request.url);
          return throwError(error);
        }
      })
    );
  }

  private addCustomHeaders(request: HttpRequest<any>): HttpRequest<any> {
    let url = request.url;
    if (
      !request.url.includes("integration/token") &&
      !request.url.includes("user/login") && 
      !request.url.includes("relay") &&
      !request.url.includes("MyKad/") &&
      !request.url.includes("generateTokenByRefreshToken") &&
      !request.url.includes(APPLICATIONSERVICE.GET_MACHINE_IP)
    ) {
      
      const headers = {
        "X-API-VERSION": "1",
        "X-ORGID": this.getStoredValue("orgId"),
        "X-ORGCODE": this.getStoredValue("orgCode"),
        "X-UNITID": this.getStoredValue("unitId"),
        "X-UNITCODE": this.getStoredValue("unitCode"),
        "X-USERID": this.getStoredValue("userId"),
        "x-EMPLOYEEID": localStorage.getItem("employeeId") || "",
        "X-USERNAME": this.getStoredValue("userName"),
        "X-COUNTERNAME": localStorage.getItem("counterName") || "e",
        "X-ACTIVESHIFTID": localStorage.getItem("activeShiftId") || "open",
        "X-LANGUAGE": (localStorage.getItem("lan") || "EN").toUpperCase(),
        Authorization: localStorage.getItem("authorization") || "",
        "X-SHIFTID": localStorage.getItem("activeShiftId") || "0",
        "X-COUNTERID": localStorage.getItem("counterId") || "0",
         "X-MACHINE-IP": localStorage.getItem("machineIP") || ""
      };
      return request.clone({ setHeaders: headers });
    }
    else {
          if(request.url.includes("relay")) {
            const authorization = localStorage.getItem("authorization")
            ? localStorage.getItem("authorization")
            : "";
            request = request.clone({
              setHeaders: {
                "X-API-VERSION": "1",
                Authorization: authorization,
                "access-token":this.env.clinicalAcessToken
              },
            });
          }
         }
    return request;
  }
  private getStoredValue(key: string): string {
    const value = localStorage.getItem(key);
    return value ? atob(value) : "";
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.refreshTokenSubject.next(null); 

      return this.handleTokenExpired().pipe(
        switchMap((token: string) => {
          this.isRefreshingToken = false;
          this.refreshTokenSubject.next(token); 
          return next.handle(this.addCustomHeaders(request));
        }),
        catchError((err) => {
          this.isRefreshingToken = false;
          this.router.navigate(["/"]);
          return throwError(err);
        }),
        finalize(() => {
          this.isRefreshingToken = false;
        })
      );
    }
     else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(() => next.handle(this.addCustomHeaders(request)))
      );
    }
  }

  setAuditTrailAction(action) {
    const roleName = localStorage.getItem("roleName");
    this.httpOptions = {
      headers: new HttpHeaders({
        "X-ACTION": action,
        "X-ROLE": atob(roleName),
      }),
    };
  }

  setResourceURL(url) {
    this.resourceUrl = url;
  }

  getAutoSearch(url) {
    return this.http.get(this.baseUrl + url, this.httpOptions).pipe(
      tap((response: any[]) => {
        return response;
      })
    );
  }

  displayCMSToastMessage(message) {
    this.toastr.info(message);
  }

  getExternalResource(externalUrl: string) {
    return this.http.get(externalUrl).pipe(map((res: any) => res));
  }

  getResource(params) {
    const data = this.baseUrl + this.resourceUrl + params;
    return this.http.get(data, this.httpOptions).pipe(map((res: any) => res));
  }

  postResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  putResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .put(url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  patchResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .patch(url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  patchServiceRequest(params) {
    const url = this.baseUrl + params;
    return this.http.patch(url, this.httpOptions).pipe(map((res: any) => res));
  }

  deleteResource(params) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http.delete(url, this.httpOptions).pipe(map((res: any) => res));
  }

  getBlobFileResource(params) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http.get(url, { responseType: "blob" as "json" }).pipe(
      map((res: any) => {
        return new Blob([res], { type: "application/pdf" });
      })
    );
  }

  getMIMETypeResource(params) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .get(url, { responseType: "blob" as "json", observe: "response" })
      .pipe(
        map((res: any) => {
          let myHeader = res.headers.get("Content-Type");
          return new Blob([res.body], { type: myHeader });
        })
      );
  }

  postFileResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, { responseType: "blob" as "json" })
      .pipe(
        map((res: any) => {
          return new Blob([res], { type: "application/pdf" });
        })
      );
  }
  postReportResource(params, payload, type) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, { responseType: "blob" as "json" })
      .pipe(
        map((res: any) => {
          return new Blob([res], { type: type });
        })
      );
  }

  //EMRLite API STARTS

  getLikeSearchDataUrl(url, payload) {
    return this.http.post(this.baseUrl + url, payload, this.httpOptions).pipe(
      tap((response: any[]) => {
        return response;
      })
    );
  }

  postCMSresource(url, payload) {
    return this.http
      .post(this.baseUrl + url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  patchCRMResource(url, payload) {
    return this.http
      .patch(this.baseUrl + url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

  getCMSResource(url) {
    const data = this.baseUrl + url;
    return this.http.get(data, this.httpOptions).pipe(map((res: any) => res));
  }

  deleteCMSResource(url) {
    return this.http.delete(this.baseUrl + url).pipe(map((res: any) => res));
  }

  // EMRLite API ENDS

  getFileResource(params) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .get(url, { responseType: "json" })
      .pipe(map((res: any) => res));
  }

  uploadResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, { responseType: "json" })
      .pipe(map((res: any) => res));
  }

  setMultiPartHeader() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "multipart/form-data",
      }),
    };
  }

  uploadMultiResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    return this.http
      .post(url, payload, this.httpOptions)
      .pipe(map((res: any) => res));
  }

 
  private displayToastMessage(response, url) {
    let statusText = response.statusText;
    let displayToast = !response.url.includes(APPLICATIONSERVICE.USER_UPDATE_PASSWORD);
    if ((response.status == 0 || response.status >= 300) && displayToast) {
      let message = "Oops something went wrong. Contact Admin";
      if (response.status == 409) {
        statusText = "Validation";
        message = response.error?.message || response.error?.statusMessage || response.error;
        this.toastr.info(message, statusText);
      } else if (response.status == 401) {
        //this.handleTokenExpired();
        statusText = "Unauthorized Access";
        message = "Invalid User Credentials";
        this.toastr.info(message, statusText);
      } else if (response.status == 503) {
        statusText = "ERROR";
        message = response.error;
        this.toastr.info(message, `${statusText} (${response.status})`);
      } else if (response.status == 0 && statusText == "Unknown Error") {
        statusText = `${this.getMicroServiceName(url)} ERROR`;
        message = "Above microservice is down. Please contact DevOps Admin";
        this.toastr.error(message, `${statusText} (404)`);
      } else if (response.message && this.env.logMessages) {
        message = response.message;
        this.toastr.error(message, `${statusText} (${response.status})`);
      }
    }
  }

  getMicroServiceName(url: string): string {
    if (url && url.split("/").length > 3) {
      return url.split("/")[3].toUpperCase();
    } else return "";
  }

  loginResource(params, payload) {
    const url = this.baseUrl + this.resourceUrl + params;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      observe: "response" as "response",
    };
    return this.http
      .post(url, payload, httpOptions)
      .pipe(map((res: HttpResponse<any>) => res));
  }
  
  private handleTokenExpired(): Observable<any> {
    const refreshToken = localStorage.getItem('refreshtoken');
    return this.http
      .post(
        `${this.baseUrl}${MICROSERVICES.IDENTITY_SERVICE}auth/generateTokenByRefreshToken?refreshToken=${refreshToken}`,
        {},
        { observe: 'response' }
      )
      .pipe(
        tap((res: any) => {
          const authorization = res.headers.get("Authorization");
          if (authorization) {
            localStorage.setItem("authorization", authorization);
          }
          else
          {
         this.toastr.info("Invalid User Credentials", "Unauthorized Access");
         this.router.navigate(["/"]);
         localStorage.setItem("isLoggedin", "false");
          }
        }),
        map((res) => res.headers.get("Authorization") as string)
      );
  }
  
}
