import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QUEUE_STEPS } from '../../properties/queue-status-properties';
import { AppointmentDetails, IDoctorList } from '../../Interface/Itoken-Generation';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppBaseService, AuthService, EnvoirnmentService, PatientService } from 'medcare-core-ui';
import { interval, Subject, Subscription, takeUntil } from 'rxjs';
import { GeneralUrlService } from '../../services/generalUrl.service';
import {
  MICROSERVICES,
  MICROSERVICESURL,
} from "../../properties/qms-url-properties";
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxScannerQrcodeComponent } from 'ngx-scanner-qrcode';
import { BaseObject } from '../../Interface/Ibase';
import { BarcodeScannerComponent } from 'projects/medcare-core-ui/src/app/core/barcode-scanner/barcode-scanner.component';

@Component({
  selector: 'app-new-qms-token-generation',
  templateUrl: './new-qms-token-generation.component.html',
  styleUrls: ['./new-qms-token-generation.component.scss'],
  providers: [GeneralUrlService]
})
export class NewQmsTokenGenerationComponent implements OnInit, OnDestroy {
  @ViewChild(NgxScannerQrcodeComponent) scanner: NgxScannerQrcodeComponent;

  currentStep: string = QUEUE_STEPS.STEP1;
  languages = [
    { code: 'en', name: 'English' , icon:'language'},
    { code: 'my', name: 'Malay', icon:'language' },
    // Add more languages here
  ];

  selectedLanguage = 'en';  // default to English
  mrnNumber: string = '';
  appointmentDetails:AppointmentDetails[]=[];
  patientDetails: any=[];
  _onDestroy: Subject<boolean> = new Subject<boolean>();

  tokenNo:string='';
  // selectedService: BaseObject;
  // selectedDept: string = "";
  serviceList: BaseObject[] = [];
  deptList: any[] = [];
  // selectedCardIndex: number | null = null;
  // selectedDeptIndex: number | null = null;
  doctorList:IDoctorList[]=[]
  slotList:any[]=[];
  selectedSlots: { [doctorIndex: number]: string } = {}; // Object to store selected slots for each doctor
  unitCode: string='';
  unitName: string='';
  orgCode: string='';
  selectedDoctorIndex:number=-1;
  selectedSlotIndex: number=-1;
  selectedSlot:any;
  selectedDoctor: any;
  identificationTypeList: BaseObject[] = [];
  genderList:BaseObject[]=[];
  countryCodeList: BaseObject[]=[];
  filterCountryCodeList: BaseObject[] = [];
  filterIdentifictionTypeList: BaseObject[] = [];

  public identificationTypeCtrl: FormControl = new FormControl();
  public countryCodeCtrl: FormControl = new FormControl();
  
  appointmentForm: FormGroup;
  trnNo: string='';
  patientType:string='';
  mobileNoMin: number;
  mobileNoMax: number;
  primaryIdRegex: string;
  isShown: boolean = false;
  checkInFlag: boolean = false;
  walkInFlag: boolean = false;
  bookingDetail: any;
  bookings: any[];
  value: number = 0;
  loading: boolean = false;
  selectedDept: string = "";
  searchDept: string = "";
  searchDoctor: string = "";
  searchService: string = "";
  filteredServices: BaseObject[];
  filteredDepartments: any;
  filteredDoctors: IDoctorList[];
  isSlots: boolean;
  backgroundColors: string[] = ['#e3ffd6', '#fffbf0', '#f1fff3', '#d0f2f7', '#f0ebd2', '#edf2fb', '#e2e2e2', '#f1ffc4' , '#ffe5ec'];
  appointmentNo: string;
  private subscriptionList = new Subscription();
  public qrOutput: string = "";
  isScanQr: boolean = false;

  constructor( 
    public dialog: MatDialog,
    public generalApiService: GeneralUrlService,
    public snackBar: MatSnackBar,
    public appBaseService: AppBaseService,
    public changeDetector: ChangeDetectorRef,
    public authService: AuthService,
    public env: EnvoirnmentService,
    public patientService:PatientService
  ) { }

  ngOnDestroy() {
    this._onDestroy.next(true);
    this._onDestroy.complete();
    this.subscriptionList.unsubscribe();
    if(this.scanner) this.scanner.stop();
  }

  ngOnInit(): void {
    this.appointmentForm = new FormGroup({
      idType: new FormControl('', Validators.required),
      idNo: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      gender: new FormControl('', Validators.required),
      countryCode: new FormControl('', Validators.required),
      mobNo: new FormControl('', Validators.required),
    });
    this.getUserDetails();
    // this.getGreetingText();
    this.identificationTypeCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterIdentificationTypeMasterList();
    });
    this.countryCodeCtrl.valueChanges
    .pipe(takeUntil(this._onDestroy))
    .subscribe(() => {
      this.filterCountryCodeMasterList();
    });
  }

  get needsScrolling(): boolean {
    const slotHeight = 8;
    const maxVisibleSlots = 58 / slotHeight;
    return this.slotList.length > maxVisibleSlots;
  }

  getSelectedLabel() {
    const selected = this.languages.find(option => option.code == this.selectedLanguage);
    return selected ? selected.name : '';
  }

  getSelectedIcon() {
    const selected = this.languages.find(option => option.code == this.selectedLanguage);
    return selected ? selected.icon : '';
  }

  nextStep(value){
    if(value == 'check-in'){
      this.checkInFlag = true;
      this.walkInFlag = false;
      this.currentStep = 'check-in';
    }
    else if(value == 'walk-in'){
      this.currentStep = 'walk-in';
      this.walkInFlag = true;
      this.checkInFlag = false;
    }
  }

  backStep(){
    if(this.checkInFlag){
      this.currentStep = 'check-in';
    }
    else if(this.walkInFlag){
      this.currentStep = 'walk-in';
    }
  }

  getAppointments() {
      let payload={
        // appointmentNo: this.appointmentIdValue,
        // mobileNo: this.patientDetails[0].mobileNo,
        identificationNo: this.patientDetails[0].identificationNo
      }
      this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_APPOINTMENT,payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res.length > 0) {
          this.bookings = res;
          this.currentStep = 'appointment-list';
          this.patientDetails = [];
        }else{
          this.snackBar.open("Appointment Not found","Error",{duration:2000});
          this.isShown = false;
        }
      });
  }

  clearSearchValue(){
    this.mrnNumber = "";
  } 

  getPatient(){
    if(this.mrnNumber){
      let payload={
        mrn: this.mrnNumber,
        offset: 0,
        limit: 10
      }
      this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_PATIENT,payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res.patientResponseList.length>0) {
          res.patientResponseList.map((data)=>{
            let patientAge: any = this.patientService.calculateAge( data.dob );
            data.age = data.dob ? patientAge.years + " " + patientAge.months + " " + patientAge.days : "";
            if(!data.image){
              data.image = "../assets/images/" + this.getGenderValue(data.gender);
            }
          })
          this.patientDetails=res.patientResponseList;
          this.isShown = true;
          this.clearSearchValue();
        }else{
          this.snackBar.open("Patient not found","Error",{duration:2000});
          this.isShown = false;
        }
      });
    }else{
      this.snackBar.open("Please enter MRN Number to search Patient","Validation",{duration:2000});
    }
  }

  goBack(){
    this.isShown = false;
    this.selectedDoctor = null;
    this.selectedDept = "";
    this.selectedSlot = null;
    this.bookingDetail = null;
    this.doctorList = [];
    this.deptList = [];
    this.slotList = [];
    this.selectedSlotIndex = -1;
    this.selectedDoctorIndex = -1;
    if(this.checkInFlag){
      if(this.isScanQr){
        this.currentStep = 'check-in';
        this.isScanQr = false;
      }
      else{
        this.currentStep = 'manual-search';
      }
    }
    else if(this.walkInFlag){
      this.currentStep = 'book-appointment';
    }
  }

  getBookingDetail(booking){
    let patientAge: any = this.patientService.calculateAge( booking.dob );
    booking.age = booking.dob ? patientAge.years + " " + patientAge.months + " " + patientAge.days : "";
    this.bookingDetail = booking;
    this.currentStep = 'booking-confirmation';
  }

  getDetails(){
    if(this.selectedDoctor && this.selectedSlot){
      if(this.patientDetails.length == 0 && this.trnNo){
        this.bookingDetail= {
          patientName : this.appointmentForm.value.name,
          identificationNo :this.appointmentForm.value.idNo,
          age : "",
          gender :this.appointmentForm.value.gender ? this.appointmentForm.value.gender.desc : "",
          mobileNo :this.appointmentForm.value.mobNo,
          doctor : this.selectedDoctor,
          department : this.deptList.filter((data)=> data.code == this.selectedDept)[0],
          appointmentDateTime : this.selectedSlot?.start
        }
      }
      else{
        this.bookingDetail= {
          patientName : this.patientDetails[0].patientName,
          identificationNo : this.patientDetails[0].identificationNo,
          age : this.patientDetails[0].age,
          gender : this.patientDetails[0].gender,
          mobileNo : this.patientDetails[0].mobileNo,
          doctor : this.selectedDoctor,
          department : this.deptList.filter((data)=> data.code == this.selectedDept)[0],
          appointmentDateTime : this.selectedSlot?.start
        }
      }
      this.currentStep = 'booking-confirmation';
    }
    else{
      this.snackBar.open("Please select Doctor and any one Slot to proceed","Validation",{duration:2000});
    }
  }

  getNewPatient() {
    let payload={
      mrn: this.trnNo,
      offset: 0,
      limit: 10
    }
    this.generalApiService
    .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_PATIENT,payload)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if (res.patientResponseList.length>0) {
        res.patientResponseList.map((data)=>{
          let patientAge: any = this.patientService.calculateAge( data.dob );
          data.age = data.dob ? patientAge.years + " " + patientAge.months + " " + patientAge.days : "";
          if(!data.image){
            data.image = "../assets/images/" + this.getGenderValue(data.gender);
          }
        })
        this.patientDetails=res.patientResponseList;
        this.isShown = true;
        this.clearSearchValue();
      }else{
        this.snackBar.open("Patient not found","Error",{duration:2000});
        this.isShown = false;
      }
    });
  }

  getGenderValue(value: string): string {
    switch (value) {
      case "MALE":
        return "male-blue.png";
      case "FEMALE":
        return "female-pink.png";
      default:
        return "male-blue.png";
    }
  }

  getConfirmation(){
    if(this.checkInFlag){
      this.getAppointments();
      // this.currentStep = 'appointment-list';
    }
    else if(this.walkInFlag){
      this.currentStep = 'book-appointment';
    }
  }

  getUserDetails() {
    this.authService.getUnit().then((value: any) => {
    this.unitCode=value.unitCode;
    this.unitName = value.unitDescription;
    this.orgCode=value.orgCode;
    });
  }
  getAppConfigList(){
    let mrdConfig;
    this.appBaseService.setResourceURL(MICROSERVICES.APP_CONFIG_SERVICE);
      this.appBaseService
        .getResource(MICROSERVICESURL.APP_CONFIG+this.unitCode+
          "&orgCode="+this.orgCode+"&moduleName=Patient_Management&keyName=")
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          if (res) {
            mrdConfig = res.keys;
            mrdConfig.forEach((element) => {
              if (element.key == "Default Country Code"){
                if (element.valueList.length > 0) {
                  this.appointmentForm.controls['countryCode'].setValue(element.valueList[0].code);
                  let defaultCountryCode=this.countryCodeList.find((i) => i.code == element.valueList[0].code);
                  this.setPatternToMobileNumber(defaultCountryCode);
                }
              }
            });
          }
        })
  }

  getToken(patient){
    if(this.checkInFlag){
    let payload={
     appointmentNo:patient.appointmentNo?patient.appointmentNo:'',
     mrn:patient.mrn?patient.mrn:''
    }
    this.generalApiService
    .postTypeApi(MICROSERVICES.OPD_SERVICE, MICROSERVICESURL.CREATE_ENCOUNTER,payload)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res.tokenNo){
      this.tokenNo=res.tokenNo?res.tokenNo:'';
      this.currentStep = 'print-token';
      this.getLoading();
      }
    });
  }
  else if(this.walkInFlag){
    this.createAppointment();
  }
  }
  
  getWalkIntoken(service){
    let Payload;
    if(this.patientType=="REG"){
    Payload ={
        mrn: this.patientDetails[0].mrn,
        qmServiceCode: service?.code,
        qmLabelIdList: []
    }
  }else{
    Payload={
      trn: this.trnNo,
      qmServiceCode: service?.code,
      qmLabelIdList: []
  }
  }
    this.generalApiService
    .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.ADD_VISITOR,Payload)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      this.tokenNo = res.tokenNo;
      this.currentStep = 'print-token';
      this.getLoading();
    })
  }

  getLoading() {
    this.loading = true;
    this.value = 0; 

    const subs$: Subscription = interval(50).subscribe(() => {
      this.value += 100/(5000/50);
      if (this.value >= 100 && this.currentStep !==QUEUE_STEPS.STEP1) {
        subs$.unsubscribe();
        this.loading = false;
        this.value = 0;
        this.isShown = false;
        this.tokenNo = "";
        this.currentStep = QUEUE_STEPS.STEP1;
        this.clearAll();
      }
    });
}

  getSlot(doctor,index){
    this.selectedSlotIndex = -1;
    this.selectedDoctorIndex = index;
    this.selectedDoctor = doctor;
    const day = new Date();
    const startTime = Math.floor(day.getTime() / 1000); 
    day.setHours(23, 59, 59, 999); 
    const endTime = Math.floor(day.getTime() / 1000); 
    this.slotList=[];
    this.generalApiService
    .getTypeApi(MICROSERVICES.OPD_SERVICE, MICROSERVICESURL.GET_SLOT+doctor.code+"&departmentCode="+
      this.selectedDept+"&startTime="+startTime+"&endTime="+endTime)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res && res[0]){
        res[0].Slots.forEach(element => {
          if(element.businessStatus=="free")
          this.slotList.push(element);
          this.isSlots = true;
        });
      }else{
        this.slotList=[];
        this.isSlots = false;
      }
      
    })
  }
  getServices(flag?){
    this.generalApiService
    .getTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_SERVICES)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res.length > 0){
        if(flag){
          this.getDepartments();
        }
        else{
          this.serviceList = res.filter((item)=> item.isConsultation == false);
          this.filteredServices = this.serviceList;
          this.currentStep = QUEUE_STEPS.SELECT_SERVICE;
        }
      }
      else{
        this.snackBar.open("Please select Service","Error",{duration:2000});
      }
    });
  }
  createTempPatient(){
    if(this.appointmentForm.valid){
    let formValue=this.appointmentForm.controls;
    let countryCodeObj=this.getObjByCode(this.countryCodeList,formValue.countryCode.value);
   let payload={
      idTypeList: formValue.idType.value?this.createObjectList(formValue.idType.value):[],
      idNumber: formValue.idNo?formValue.idNo.value:'',
      patientName: formValue.name.value?formValue.name.value:'',
      genderList:formValue.gender.value?this.createObjectList(formValue.gender.value): [ ],
      countryCode: {
          code: countryCodeObj.code,
          display: countryCodeObj.desc,
          system: "EN"
      },
      mobileNo: formValue.mobNo.value?formValue.mobNo.value:''
  }
  this.generalApiService
  .postTypeApi(MICROSERVICES.OPD_SERVICE, MICROSERVICESURL.CRATE_TEMP_PATIENT,payload)
  .pipe(takeUntil(this._onDestroy))
  .subscribe((res: any) => {
    if(res.trn){
      // this.getServices();
      // this.appointmentForm.reset();
      this.trnNo=res.trn;
      this.currentStep = 'book-appointment';
    }
  });
  }else{
    this.snackBar.open("Please fill all the fields","Error",{duration:2000});
    this.appointmentForm.markAllAsTouched();
  }
  }

  getDepartments(){
    this.generalApiService
    .getTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.GET_DEPARTMENTS)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res.length > 0){
        this.deptList = res;
        this.filteredDepartments = res;
        this.currentStep = QUEUE_STEPS.SELECT_DEPARTMENT;
      }
      else{
        this.snackBar.open("Please select Department","Error",{duration:2000});
      }
    });
  }

  getDoctorList(dept){
    this.selectedDept = dept? dept.code: "";
    let payload={
      limit: 0,
      unitCode: this.unitCode,
      departmentCode: dept?.code,
      isIncludeDoctorSlot: true,
      typeCodeList: [
          "DR"
      ]
  }
    this.generalApiService
    .postTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.GET_EMPLOYEE_LIST,payload)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      if(res.payload && res.payload.employeeResponseList){
        let list : IDoctorList[]=[];
        res.payload.employeeResponseList.forEach((element) => {
          list.push({
            id: element.id,
            code: element.code,
            desc: element.desc,
            gender:element.gender,
           imagePath:element.aws3FilePath ? element.aws3FilePath : (element.empImagePath ?this.env.apiUrl+element.empImagePath : ""),
            available:
              element.doctoreAvailable === "No slots available"
                ? element.doctoreAvailable
                : "Next Availablity is " + element.doctoreAvailable,
                l1:element.l1,
                l2:element.l2,
                l3:element.l3
          });
          this.doctorList=list;
          this.filteredDoctors = list;
        });       
    // go to select doctor step
    this.currentStep =QUEUE_STEPS.SELECT_DOCTOR;
      }else{
        this.snackBar.open("Doctor not preasent in the selected department.","Validation",{duration:2000});
      }
    })
  }
  
  selectSlot(slotIndex, slot) {
    this.selectedSlotIndex =slotIndex;
    this.selectedSlot=slot;
  }
  createAppointment(){
   if(this.selectedSlot && this.selectedDoctor){
    let specialityObj=this.getObjByCode(this.deptList,this.selectedDept);
    let Payload;
    if(this.patientType=="REG"){
  Payload={
      patientType: {
          code: "REG",
          display: "registered",
          system: "EN"
      },
      patient: {
          code: this.patientDetails[0].mrn,
          display: this.patientDetails[0].patientId,
          system: "ExtendedPatient"
      },
      specialityList:specialityObj?this.createObjectList(specialityObj):[],
      doctorList: this.selectedDoctor?this.createObjectList(this.selectedDoctor):[],
      startTimeEpoch:this.selectedSlot? this.patientService.dateToEpochConverter(this.selectedSlot.start):0,
      endtimeEpoch: this.selectedSlot?this.patientService.dateToEpochConverter(this.selectedSlot.end):0,
      serviceList: [
          {
              system: "EN",
              code: "1",
              display: "Doctor"
          },
          {
              system: "MY",
              code: "1",
              display: "Doktor"
          }
      ],
      serviceCategory: {
          code: "1",
          display: "Doctor",
          system: "EN"
      },
      slots: this.selectedSlot?[
        this.selectedSlot.id
      ]:[],
      appointmentType: {
          code: "1",
          display: "Doctor",
          system: "EN"
      },
      consultationType: null
      // {
      //     code:this.selectedService.code?this.selectedService.code:'',
      //     display: this.selectedService.desc?this.selectedService.desc:'',
      //     system: "EN"
      // }
  }
  }else{
  Payload={
    patientType: {
        code: "TEMP",
        display: "temporary",
        system: "EN"
    },
    patient: {
        code: "",
        display: "",
        system: ""
    },
    specialityList:specialityObj?this.createObjectList(specialityObj):[],
    doctorList: this.selectedDoctor?this.createObjectList(this.selectedDoctor):[],
    startTimeEpoch:this.selectedSlot? this.patientService.dateToEpochConverter(this.selectedSlot.start):0,
    endtimeEpoch: this.selectedSlot?this.patientService.dateToEpochConverter(this.selectedSlot.end):0,
    serviceList: [
        {
            system: "EN",
            code: "1",
            display: "Doctor"
        },
        {
            system: "MY",
            code: "1",
            display: "Doktor"
        }
    ],
    serviceCategory: {
        code: "1",
        display: "Doctor",
        system: "EN"
    },
    slots: this.selectedSlot?[
      this.selectedSlot.id
    ]:[],
    appointmentType: {
        code: "1",
        display: "Doctor",
        system: "EN"
    },
    consultationType: null
  //   {
  //     code:this.selectedService.code?this.selectedService.code:'',
  //     display: this.selectedService.desc?this.selectedService.desc:'',
  //     system: "EN"
  // }
  ,
    trn:this.trnNo
  }
  }
  this.generalApiService
  .postTypeApi(MICROSERVICES.OPD_SERVICE, MICROSERVICESURL.CREATE_APPOINTMENT,Payload)
  .pipe(takeUntil(this._onDestroy))
  .subscribe((res: any) => {
    if(res.appointment.tokenNo){
      this.tokenNo = res.appointment.tokenNo;
      this.currentStep = 'print-token';
      this.getLoading();
      // this.clearSelectedAll();
    }
  })
   }else{
    this.snackBar.open("Please select doctor and booking slot.","Validation",{duration:2000});
   }
  }
  // clearSelectedAll(){
  //   this.patientDetails=[];
  //   this.selectedSlot=null;
  //   this.selectedDoctor=null;
  //   this.slotList=[];
  //   this.serviceList=[];
  //   this.doctorList=[];
  //   this.filteredServices= [];
  //   this.filteredDepartments = [];
  //   this.filteredDoctors = [];
  //   this.selectedDept='';
  //   this.selectedSlotIndex = -1;
  //   this.selectedDoctorIndex = -1;
  //   this.tokenNo='';
  //   this.trnNo='';
  //   this.patientType='';
  // }

  getNewPatientForm(){
    this.getIdTypeList();
    this.getGenderList();
    this.getCountyCodeList();
  }
  getIdTypeList(){
    this.generalApiService
    .getTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.M_IDENTIFICATION_TYPE)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      this.identificationTypeList=res;
      this.filterIdentifictionTypeList=res;
    })
  }
  filterIdentificationTypeMasterList(){
    const searchTerm = this.identificationTypeCtrl.value ? this.identificationTypeCtrl.value.toLowerCase() : '';
    
    this.filterIdentifictionTypeList = this.identificationTypeList.filter(type =>
      type.desc.toLowerCase().includes(searchTerm)
    );
  }
  getGenderList(){
    this.generalApiService
    .getTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.M_GENDER)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      this.genderList=res;
    })
  }
  getCountyCodeList(){
    this.generalApiService
    .getTypeApi(MICROSERVICES.MASTER_SERVICE, MICROSERVICESURL.M_COUNTRY_CODE)
    .pipe(takeUntil(this._onDestroy))
    .subscribe((res: any) => {
      this.countryCodeList=res;
      this.filterCountryCodeList=res;
        this.currentStep = QUEUE_STEPS.NEW_PATIENT;
    })
  }
  filterCountryCodeMasterList() {
    const searchTerm = this.countryCodeCtrl.value ? this.countryCodeCtrl.value.toLowerCase() : '';
    
    this.filterCountryCodeList = this.countryCodeList.filter(type =>
      type.desc.toLowerCase().includes(searchTerm)
    );
  }
  // getBackToSearch(){
  //   if(this.patientType=="REG"){
  //     this.currentStep = QUEUE_STEPS.PATIENT_DETAILS;
  //   }else{
  //     this.currentStep = QUEUE_STEPS.NEW_PATIENT;
  //   }
  // }
  clearAll(){
    this.patientDetails=[];
    this.selectedSlot=null;
    this.selectedDoctor=null;
    this.slotList=[];
    this.serviceList=[];
    this.doctorList=[];
    this.filteredServices= [];
    this.filteredDepartments = [];
    this.filteredDoctors = [];
    this.selectedSlotIndex=-1;
    this.selectedDept='';
    this.tokenNo='';
    this.trnNo='';
    this.patientType='';
    this.isScanQr = false;
  }
  public createObjectList(object) {
    let languageList = JSON.parse(localStorage.getItem("langList"));
    let list = [];
    for(let langKey in languageList) {
        for(let objKey in object) {
            if(langKey == objKey) {
                let listItem = {
                    system: languageList[langKey].toString(),
                    code: object.code,
                    display: object[objKey].toString()
                }
  
                list.push(listItem);
            }
        }
    }
    if(list.length==0){
        return list=null;
    }
    return list;
  }
  getObjByCode(list: any[], code: string) {
    let value;
    if (list && list.length > 0) {
      value = list.find((i) => i.code == code);
    }
    return value;
  }
  //  maskString(inputString: string): string {
  //   const visibleChars = 2;
  //   const inputLength = inputString.length;
  
  //   if (inputLength <= visibleChars * 2) {
  //     return inputString;
  //   }
  
  //   const maskedLength = inputLength - visibleChars * 2; 
  //   const maskedPart = 'X'.repeat(maskedLength); 
    
  //   return inputString.substring(0, visibleChars) + maskedPart + inputString.slice(-visibleChars);
  // }
  setPatternToMobileNumber(event){
    if (event.regex) {
      const regexPattern = event.regex.replace(/^\/|\/$/g, '');
      this.appointmentForm.controls.mobNo.setValidators([
        Validators.required,
        Validators.pattern(new RegExp(regexPattern))
      ]);
      const matches = event.regex.toString().match(/\{(\d+),(\d+)\}/);
      if (matches && matches.length > 1) {
        this.mobileNoMax = Number(matches[2]);
        this.mobileNoMin = Number(matches[1]);
      }else{
      this.appointmentForm.controls.mobNo.clearValidators();
      }
    } else {
      this.appointmentForm.controls.mobNo.clearValidators();
      this.appointmentForm.controls.mobNo.setValidators([
        Validators.required,
      ]);
    }
    this.appointmentForm.controls.mobNo.updateValueAndValidity();
  }
   /**
     *primaryIdentification onchange check multiple conditions
     * @param idTypeCode
     */
     primaryIdentificationOnChange(idTypeCode: any) {
      let form = this.appointmentForm.controls;
      if (idTypeCode) {
        let primaryIdCode = this.identificationTypeList.find(
          (s) => s == idTypeCode.value
        );
        if (primaryIdCode) {
          if (primaryIdCode.isMyKad == true) {
            this.primaryIdRegex = "^[0-9 -]+$";
          } else {
            this.primaryIdRegex = primaryIdCode.validation
              ? primaryIdCode.validation
              : "";
          }
        }
        this.primaryNumberOnKeyUp(form.idNo.value);
       
      }
    }
  
    /**
     * primaryNumber onchange check multiple conditions
     * @param value
     */
    primaryNumberOnKeyUp(value: string) {
      try {
        let form = this.appointmentForm.controls;
        let primaryIdCode = form.idType.value;
  
        value = value.replace(/\s/g, '');
        form.idNo.setValue(value);
        if (primaryIdCode) {
          if (primaryIdCode.isMyKad) {
          }
  
          let primaryIdentify = this.identificationTypeList.find(
            (i) => i.code == primaryIdCode.code
          );
          if (primaryIdentify) {
            if (primaryIdentify.isMyKad) {
              var r = /(\D+)/g,
                npa = "",
                nxx = "",
                last4 = "";
              value = value.replace(r, "");
              var c: string = value;
              npa = value.substr(0, 6);
              nxx = value.substr(6, 2);
              last4 = value.substr(8, 4);
              value = npa + "-" + nxx + "-" + last4;
              form.idNo.setValue(value ? value : "");
              this.primaryIdRegex = "^[0-9 -]+$";
            } else if (primaryIdentify.isMyKad == false) {
              this.primaryIdRegex = "[A-Za-z0-9]*";
              form.idNo.setValue(value ? value : "");
            }
  
            if (value.length >= 12 && primaryIdentify.isMyKad) {
              const array = Array.from(c);
              const gen_val = array[11];
  
              const lastDigit = Number(gen_val);
              let genderVal = lastDigit % 2 == 0 ? "FEMALE" : "MALE";
              let gender=this.genderList.find((i)=>i.code===genderVal);
              form.gender.setValue(gender)
  
            }
          }
        }
      } catch (ex) {}
    }

    onSearchDoctorChange(){
      this.filteredDoctors = this.doctorList.filter(doc =>
        doc.desc.toLowerCase().includes(this.searchDoctor.toLowerCase())
      );
    }

    onSearchDeptChange(){
      this.filteredDepartments = this.deptList.filter(dept =>
        dept.desc.toLowerCase().includes(this.searchDept.toLowerCase())
      );
    }

    onSearchServiceChange(){
      this.filteredServices = this.serviceList.filter(service =>
        service.desc.toLowerCase().includes(this.searchService.toLowerCase())
      );
    }

    openBarcodeScanDialog() {
      // this.scanner.start();
      this.isScanQr = true;
      this.currentStep = 'scan-qr';
    }
  
    /**
     *
     * @param BarCode
     */
    applyFilter(BarCode) {
      this.appointmentNo=BarCode;
      this.getAppointment();
    }

    getAppointment(){
      let payload={
        appointmentNo: this.appointmentNo,
        // mobileNo: this.patientDetails[0].mobileNo,
        // identificationNo: this.patientDetails[0].identificationNo
      }
      this.generalApiService
      .postTypeApi(MICROSERVICES.PATIENT_INTEGRATION_SERVICE, MICROSERVICESURL.GET_APPOINTMENT,payload)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res.length > 0) {
          this.getBookingDetail(res[0]);
        }else{
          this.snackBar.open("Appointment Not found","Error",{duration:2000});
          this.isShown = false;
        }
      });
    }

    // scan qr

    onCameraStart(action) {
      if(!this.scanner) this.scanner = action;
      action.start();
    }
  
    onBarcodeScanned(action, $event): void {
      this.qrOutput = $event? $event[0].value : null;
      // this.onClose();
      if(this.scanner) this.scanner.stop();
      this.applyFilter(this.qrOutput);
    }
}
