import { Component, NgZone, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import {
  AppBaseService,
  ApplicationConfigService,
  APPLICATIONSERVICE,
  AuthService,
  ConfirmDialogComponent,
  ConfirmDynamicDialogComponent,
  EnvoirnmentService,
  InfoDialogComponent,
  MasterKeys,
  MasterMenus,
  MASTERSSERVICES,
  MasterSubMenus,
  MICROSERVICES,
  PatientEncounter,
  PatientEncounterPayer,
  PatientNextOfKin,
  PatientRegistration,
  PatientService,
  ReportService,
  SearchService,
  UtilsService,
} from "medcare-core-ui";
import { MultiLanguageService } from "projects/medcare-core-ui/src/app/core/multi-language/multi-language.service";
import { PatientNotesDialogComponent } from "../core/patient-notes-dialog/patient-notes-dialog.component";
import { elementAt, map, take, takeUntil } from "rxjs/operators";
import { PatientRegistrationService } from "../regservices/Spatient-registration.service";
import { DatePipe } from "@angular/common";
import { mandatoryFields } from "../regproperties/field-mandatory-properties";
import { opdEnvironment } from "../regproperties/opd-field-properties";
import { opdNavigation } from "../regproperties/opd-navigation-properties";
import { OPD_API_URL } from "../regproperties/opd-url-properties";

import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import moment from "moment";
import { Subject } from "rxjs";
import { Subscription } from "rxjs/internal/Subscription";
import { removeSpaces } from "../regservices/helper.service"; 
import { MyKadData } from "../regmodels/MmyKadData";
import { PatientSearchModel } from "../regmodels/MpatientSearchModel";
import { OPD_CONST } from "../regproperties/opd-static-properties";
import { GeneralUrlService } from "../regservices/SgeneralUrl.service";
import { mandatoryFieldsService } from "../regservices/SmandatoryFields.service";
import { MasterUrlService } from "../regservices/SmasterUrl.service";
import { patientGetDataservice } from "../regservices/SpatientGetData.service";
import { SearchHeaderService } from "../regservices/SsearchHeader.service";
import { CameraDialogComponent } from "../regshared/camera-dialog/camera-dialog.component";
import { RegsuccessDialogComponent } from "../regshared/regsuccess-dialog/regsuccess-dialog.component";
import { UploadfileComponent } from "../regshared/uploadfile/uploadfile.component";
import {
  OPD_REPORT_NAME,
  OPD_REPORT_TYPE,
} from "../regproperties/opd-report-properties";
import { PatientAppointmentDialogComponent } from "./patient-appointment-dialog/patient-appointment-dialog.component";
import { saveAs } from "file-saver";
import { FileUploadService } from "projects/medcare-core-ui/src/app/services/file-upload.service";
import { SigninModalComponent } from "projects/medcare-core-ui/src/app/authentication/signin-modal/signin-modal.component";
import { BirthPlaceList } from "../regproperties/birth-place";
import { ManditoryKayEnum } from "../constants/configuration-modules.constants";
import { secondIdType } from "../regmodels/MpatientRegistration";
import { PatientLableService } from "../regservices/patient-lable.service";
import { EncounterSlipService } from "../regservices/encounter-slip.service";
import { PdpaFormService } from "../regservices/pdpa-form.service";
import { DocumentUploadEnum } from "../enums/configuration-modules.enum";
import { ViewDocumentComponent } from "../regshared/view-document/view-document.component";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { CopDecimalCalculateService } from "../services/cop-decimal-calculate.service";
import { STATICCODESERVICE } from "../constants/static-code-service";
import { statisGlApprovalType } from "../update-sponsor/encounter-status.enum";
import { HttpClient, HttpClientModule, HttpHeaders } from "@angular/common/http";

@Component({
  selector: "app-patient-registration",
  templateUrl: "./patient-registration.component.html",
  styleUrls: ["./patient-registration.component.scss"],
  providers: [DatePipe],
})
export class PatientRegistrationComponent implements OnInit, OnDestroy {
  formRegistration: FormGroup = this.formBuilder.group({
    tinNo: [""],
    id: [""],
    active:[false],
    isVip: [false],
    isNewborn: [false],
    mrn: [""],
    primaryId: [""],
    primaryNumber: [null, [removeSpaces]],
    expiryDate: [""],
    issuedCountryPrimary: [""],
    secondaryId: [""],
    secondaryNumber: [null, [removeSpaces]],
    issuedCountrySecondary: [""],
    faculty: [""],
    firstName: [null, [removeSpaces]],
    dateOfBirth: [""],
    realdob: [false],
    prefix: [""],
    gender: [""],
    race: [""],
    nation: [""],
    patientCategory: [""],
    martial: [""],
    occupation: [""],
    preferredLanguage: [""],
    bloodGroup: [""],
    religion: [""],
    isDeceased: [false],
    sourceType: [""],
    patientNotes: [null, [removeSpaces]],
    addressPrimary: [null, [removeSpaces]],
    countryPrimary: [""],
    statePrimary: [""],
    cityPrimary: [""],
    subDistrictPrimary: [""],
    homeContact: [""],
    personalFax: [""],
    education: [""],
    birthplace: [""],
    workFax: [""],
    motherName: [""],
    ethnic: [""],
    mobileNumber: [""],
    addressSecondary: [null, [removeSpaces]],
    countrySecondary: [""],
    stateSecondary: [""],
    citySecondary: [""],
    subDistrictSecondary: [""],
    workContact: [""],
    contactRelation: [""],
    pincodePrimary: ["", [Validators.pattern(/^-?([0-9]\d*)?$/)]],
    pincodeSecondary: ["", [Validators.pattern(/^-?([0-9]\d*)?$/)]],
    emailId: [
      "",
      Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.]+[a-zA-Z0-9-.]+$"),
    ],
    birthTime: [""],
    countryCodemobile: [""],
    secondaryExpirydate: [""],
    employmentStatus: [""],
    employerName: [""],
    employerNameOthers: [""],
    employmentNo: [""],
    workingFrom: [""],
    employmentContactNo: [""],
    employmentAddress: [null, [removeSpaces]],
    employmentCountry: [""],
    employmentState: [""],
    employmentCity: [""],
    employmentPostCode: ["", [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
  });
  selectedOption = [];

  formEncounter: FormGroup = this.formBuilder.group({
    visitToDepartment: [""],
    encounterType: ["", Validators.required],
    department: ["", Validators.required],
    doctor: ["", Validators.required],
    clinic: [""],
    appointmentSlots: [""],
    cashCounter: [""],
    remarks: [null, [removeSpaces]],
    tokenNo: [""],
    queueNumber: [""],
    referralType: [""],
    referral: [""],
    patientCategory: [""],
    practiceNotes: [null, [removeSpaces]],
    visittype: [""],
    referralRemarks: [null, [removeSpaces]],
    reportRemarks: [null, [removeSpaces]],
    healthPackage: [this.selectedOption, false],
    reportRemarkMrd: [""],
    reportTypeMrd: [""],
    reportResonMrd: [""],
  });

  formNextOfKin: FormGroup = this.formBuilder.group({
    prefix: [""],
    name: ["", Validators.required],
    identificationType: [""],
    identificationNumber: [""],
    relation: [""],
    phoneNumber: [""],
    isGuarantor: [""],
    address: [""],
    country: [""],
    state: [""],
    city: [""],
    district: [""],
    postcode: [""],
  });

  formHealthTourist: FormGroup = this.formBuilder.group({
    code: [""],
    identificationType: ["", Validators.required],
    identificationNumber: ["", Validators.required],
    agentName: ["", Validators.required],
    startDate: ["", Validators.required],
    endDate: ["", Validators.required],
    contactNo: ["", Validators.required],
    emailId: [
      "",
      Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.]+[a-zA-Z0-9-.]+$"),
    ],
    address: [""],
    country: [""],
    state: [""],
    city: [""],
    postcode: ["", [Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
  });

  //Registration Fields
  primaryIdList: any[] = [];
  secondaryIdList: any[] = [];
  secondaryIdFilteredList: any[] = [];
  prefixList: any[] = [];
  genderList: any[] = [];
  raceList: any[] = [];
  religionList: any[] = [];
  relationList: any[] = [];
  countryPrimaryList: any[] = [];
  statePrimaryList: any[] = [];
  cityPrimaryList: any[] = [];
  subDistrictPrimaryList: any[] = [];
  countrySecondaryList: any[] = [];
  stateSecondaryList: any[] = [];
  citySecondaryList: any[] = [];
  isDisabled: boolean = false;
  healthTouristList: any[] = [];
  healthTouristDetailsDTO: any[] = [];
  countryEmploymentList: any[] = [];
  stateEmploymentList: any[] = [];
  cityEmploymentList: any[] = [];
  kinData: any[] = [];
  newPrefixList: any[] = [];
  raceMasterSearch:FormControl=new FormControl();
  raceFilterList:any[]=[];
  subDistrictSecondaryList: any[] = [];
  employerList: any[] = [];
  primaryIdSearch: FormControl = new FormControl();
  secondaryIdSearch: FormControl = new FormControl();
  reportResonMrdSearch: FormControl = new FormControl();
  pendingOrderCount: any = 0;
  showBadge:boolean=false;
  //Encounte Fields
  healthPackageList: any[] = [];
  multiLangDoctorList: any[] = [];
  doctorFilteredList: any[] = [];
  payerList: any[] = [];
  payerTypeList: any[] = [];
  docTypeList: any[] = [];
  docTypeFilteredList: any[] = [];
  payerTypeFilteredList: any;
  tariffList: any[] = [];
  associatedCompanyList: any[] = [];
  glTypeList: any[] = [];
  nokList: any[] = [];
  departmentList:any[]=[];
  departmentFilterList:any[]=[];
  dipartmentSearch: FormControl = new FormControl()
  doctorSearch: FormControl = new FormControl();
  docTypeSearch: FormControl = new FormControl();
  payerTypeSearch: FormControl = new FormControl();
  payerSearch: FormControl = new FormControl();
  tariffSearch: FormControl = new FormControl();
  contractCodeSearch: FormControl = new FormControl();
  associatedCompanySearch: FormControl = new FormControl();
  //**Declaration variables */
  masterAPI: any = MASTERSSERVICES;
  mandatoryField: any = mandatoryFields;
  patientImage: string = "";
  patientForm: boolean = false;
  isDepartment: boolean = true;
  isVip: boolean;
  guarantor: string = "";
  nationalityWiseRace: any[] = [];
  hidePatientSearch = false;
  patientInfoDisplay: string = "";
  now = new Date();
  year = this.now.getFullYear();
  month = this.now.getMonth();
  day = this.now.getDate();
  minDate = moment().add(-200, "year");
  maxDate = new Date(this.year, this.month, this.day);
  maxExpiry = new Date(this.year + 20, this.month, this.day);

  private subscriptionList = new Subscription();
  _onDestroy: Subject<boolean> = new Subject<boolean>();
  search: PatientSearchModel = new PatientSearchModel();
  mandatorykeys: mandatoryFieldsService = new mandatoryFieldsService();
  searchCriteria: any = null;
  patientList: any[] = [];
  previousEncounterList: any[] = [];
  fileUploadList: any[] = [];
  fileUploadRegList: any[] = [];
  fileType: string = "";
  refreshTime: string = "";
  healthPackage: any = [];
  reportReasonList: any = [];
  reportReasonFilterdList: any = ([] = []);
  primaryIdRegex: string = OPD_CONST.primaryIdRegix;
  secondaryIdeRegex: string = OPD_CONST.primaryIdRegix;
  faxRegex: string = OPD_CONST.faxRegex;
  tempPatientRef: {
    system: string;
    code: String;
    display: string;
  } = {
    system: "",
    code: "",
    display: "",
  };
  patientAge: {
    years: number;
    months: number;
    days: number;
  } = {
    years: null,
    months: null,
    days: null,
  };

  appointmentReference: {
    appointmentId: string;
    tempPatientId: string;
    trnNumber: string;
    doctorCode: string;
    departmentCode: string;
    healthPackage: any;
    bookingType: string;
    appointmentNo?:string;
  } = {
    appointmentId: "",
    tempPatientId: "",
    trnNumber: "",
    doctorCode: "",
    departmentCode: "",
    healthPackage: [],
    bookingType: "",
  };

  displayedColumnsEncounters: string[] = OPD_CONST.displayColumnEncounters;
  dataSourceEncounters = new MatTableDataSource<any>([]);

  displayedColumnsPayers: string[] = OPD_CONST.displayColumnPayers;
  dataSourcePayers = new MatTableDataSource<PatientEncounterPayer>([]);

  displayedColumnsNOKs: string[] = OPD_CONST.displayColumnNok;
  dataSourceNextOfKin = new MatTableDataSource<PatientNextOfKin>([]);

  healthTouristColumns = OPD_CONST.healthTouristColumns;
  healthTouristSource = new MatTableDataSource();

  fileUploadColumns = OPD_CONST.fileUploadColumns;
  fileSource = new MatTableDataSource();
  displayColumnsPendingOrders: string[] = OPD_CONST.displayColumnsPendingOrders;
  groupedItems: any = [];
  isSelected: boolean = false;
  fileSourceReg = new MatTableDataSource();
  formData: FormData = new FormData();
  selectedPatient: any = null;
  patientId: string = "";
  encounterid: string = "";
  visitno: string = "";
  patientTRN: string = "";
  private socket: WebSocket;
  username: any;
  userId: any;
  usermasterid: any;
  userRightList: any = [];
  isPatientEditRights: boolean = false;
  selected: string;
  isDeceased: boolean = false;

  isPrimaryId: boolean = false;
  CountryCode: any = null;
  StateCode: any = null;
  CityCode: any = null;

  secondaryCountryCode: any = null;
  secondaryStateCode: any = null;
  secondaryDistrictCode: any = null;

  employmentCountryCode: any = null;
  employmentStateCode: any = null;

  nokCountryCode: any = null;
  nokStateCode: any = null;
  nokCityCode: any = null;
  autoSecondarySubDistrictCode: string = "";

  internalNotesList: any[] = [];
  previousInternalNotes: any[] = [];
  internotesEncounterid: any;

  payeeInfoList: {};
  payeeInfoResult: any[] = [];
  payeePrevInfoResult: any[] = [];

  contractList: any[] = [];
  selectedContact: any;

  isRegistered: boolean = false;
  isSameAddress: boolean = false;
  isexpiryDate: boolean = false;
  isSeondaryExpiryDate: boolean = false;

  countryValue: any = null;
  stateValue: any = null;
  cityValue: any = null;
  districtValue: any = null;
  countryCodeValue: any = null;

  getCountryValue: any = null;
  getStateValue: any = null;
  getCityValue: any = null;
  getDistrictValue: any = null;

  genderValue: any = null;
  identifierTypeValue: any = null;
  titleValue: any = null;
  patientCategory: any = null;
  religionValue: any = null;
  raceValue: any = null;

  countrySecondaryValue: any = null;
  stateSecondaryValue: any = null;
  citySecondaryValue: any = null;
  districtSeoncdaryValue: any = null;

  countryEmploymentValue: any = null;
  stateEmploymentValue: any = null;
  cityEmploymentValue: any = null;

  primaryIdentifierListModified: boolean = false;
  secondaryIdentifierListModified: boolean = false;
  patientNotesUpdated: boolean = false;
  referralTypeCode: any = null;
  documentTypelist: any[] = [];
  docFileType: any;
  encounterTypeValue: any = null;
  departmentValue: any = null;
  doctorValue: any = null;
  doctorValueappointment: any = null;

  isAdmissionRequest: boolean = false;
  isAppointmentHsc: boolean = false;
  admissionReqload: any;
  isBothAddressSame: boolean = false;
  @ViewChild(MatTable) table: MatTable<any>;
  unitName: string;
  unitCode: string;
  resdata: any;
  appointmentData: any = null;
  mrdDepartment: any;

  medicalReport: boolean = false;
  employer: boolean = false;
  payerFilteredList: any;
  tariffFilteredList: any;
  contractCodeList: any;
  associatedCompanyCodeList: any;
  docList: any;
  primaryIdFilteredList: any[];
  reportTypeMrd: null;
  reportResonMrd: null;
  secondaryExpirydate: any;
  admissionStatus: boolean;
  identificationTypeList: any[];
  isToken: boolean = false;
  isUpdateRequired: boolean = false;
  isAddeassByPostCode: boolean = false;
  isGetAddress: boolean = false;
  tokenNoValue: string = "";
  isPatientType: string = "";
  isPatientCategory: boolean = false;
  existingPayerList: any;
  imageUrl: string;
  MasterStaffCode: string;
  MasterStudentCode: string;
  defaultGender: {
    id: number;
    code: string;
    desc: string;
  };
  isNOKRequired:boolean=false;
  isBackAllow:boolean=false;
  isStaffActive:boolean=false;
  isEncounterAllow:boolean=false;
  isNationDependant:boolean;
  isFirstPayerPriority:boolean;
  mobileNoMin:number=8;
  mobileNoMax:number=14;
  isDefaultDeposit:boolean = false;
  isClinicReqired: boolean;
  isGetClinicElement:boolean;
  orgCode: string='';
  PatientData: any[]=[];
  isPatientRequired:boolean=false;
  defaultCountry:string='';
  defaultNationality:string='';
  docListRegistration: any[]=[];
  docListEncounter: any[]=[];
  contractValue: any=[];
  pdpaForm: boolean = false;
  defaultDeposit: string = "";
  showDefaultDeposit: boolean = true;
  reasonCode: object;
  selectedPrimaryId: string;
  output: string;
  isPrimaryNoEditable: boolean=false;

  constructor(
    private sfileUploadService: FileUploadService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private searchService: SearchService,
    private appBaseService: AppBaseService,
    public translate: TranslateService,
    public authService: AuthService,
    private patientService: PatientService,
    public applicationConfig: ApplicationConfigService,
    private ngZone: NgZone,
    private patientRegService: PatientRegistrationService,
    private langService: MultiLanguageService,
    public masterApiService: MasterUrlService,
    public searchHead: SearchHeaderService,
    public generalApiService: GeneralUrlService,
    public patientDataService: patientGetDataservice,
    private router: Router,
    private datepipe: DatePipe,
    private utilsService: UtilsService,
    private reportService: ReportService,
    private applicationConfigService: ApplicationConfigService,
    private env: EnvoirnmentService,
    private patientLableService:PatientLableService,
    private encounterSlipService:EncounterSlipService,
    private pdpaFormService:PdpaFormService,
    private inputValidation:CopDecimalCalculateService,
    private http: HttpClientModule
  ) {}

  /**
   *Loading method
   *
   * @memberof PatientRegistrationComponent
   */
  ngOnInit() {
    this.getGenderMaster();
    this.imageUrl = this.env.apiUrl;
    this.reasonCode = OPD_CONST.reasonCode;
    this.getUnitData();
    this.fillDropDownLists();
    this.onChanges();
    this.initSearchFilter();
    this.checkRequestHasAdmission();
    this.checkRequestHasAdmissionForm();
    //this.checkRequestHasAppointment();
    this.getOrgAppConfigList();
    // this.getAppConfigList();
    this.getOrgMrdAppConfigList();
    this.getScreenConfigurations();
    this.initMyKadCardReader();
    this.getUserInfo();
  }

  /**
   *mat table update
   *
   * @type {MatTable<any>}
   * @memberof PatientRegistrationComponent
   */
  ngAfterViewInit() {
    if (this.table)
      this.subscriptionList.add(
        this.ngZone.onMicrotaskEmpty
          .pipe(take(3))
          .subscribe(() => this.table.updateStickyColumnStyles())
      );
  }

  /**
   *get user info details
   *
   * @memberof PatientRegistrationComponent
   */
  getUserInfo() {
    this.authService.getUserInfo().then((value: any) => {
      this.username = value.userName;
      this.userId = value.userId;
      this.usermasterid = value.user_code;
      this.userRightList = value.userSpecialRightList;
      if (this.userRightList && this.userRightList.length > 0) {
        this.userRightList.forEach((element) => {
          if (element.specialRightCode == OPD_CONST.splRightPatient)
            this.isPatientEditRights = true;
        });
      }
    });
  }

  /**
   * To get unit data
   * @memberof PatientRegistrationComponent
   */
  getUnitData() {
    this.authService.getUnit().then((value: any) => {
      this.unitCode = value.unitCode;
      this.unitName = value.unitDescription;
      this.orgCode=value.orgCode;
      this.getAppConfigList();
    });
  }

  /**
   *Destroy method
   *
   * @memberof PatientRegistrationComponent
   */
  ngOnDestroy() {
    this.searchService.setSearch([]);
    this.subscriptionList.unsubscribe();
    this.socket.close();
  }

  /**
   *Reeading values from Mykad
   *
   * @memberof PatientRegistrationComponent
   */
  initMyKadCardReader() {
    try {
      this.socket = new WebSocket("ws://localhost:8888");
      this.getMyKadMasters();

      this.socket.onopen = () => {
        console.log("WebSocket connection established.");
      };

      this.socket.onmessage = (event) => {
        console.log("Received message:", event.data);
        this.loadMyKadData(event);
      };

      this.socket.onclose = (event) => {
        console.log("WebSocket connection closed:", event);
      };

      this.socket.onerror = (error) => {
        console.error("WebSocket error:", error);
      };
    } catch (e) {}
  }

  /**
   *
   *
   * @param {*} event
   * @memberof PatientRegistrationComponent
   */
  loadMyKadData(event) {
    let myKadData: MyKadData = JSON.parse(event.data);
    // let myKadData: MyKadData = event;
    if (myKadData.Name) {
      
      const GenderCode = this.patientRegService.getCodeBySystemCode(
        this.genderList,
        myKadData.Gender ? myKadData.Gender : ""
      );

      const RaceCode = this.patientRegService.getCodeBySystemCode(
        this.raceList,
        myKadData.Race ? myKadData.Race : ""
      );

      const ReligionCode = this.patientRegService.getCodeBySystemCode(
        this.religionList,
        myKadData.Religion ? myKadData.Religion : ""
      );

      const CountryCode = this.patientRegService.getCodeByStaticCode(
        this.countryPrimaryList,
        STATICCODESERVICE.COUNTRY_MALAYSIA ?
          STATICCODESERVICE.COUNTRY_MALAYSIA :
          "MYS"
      );

      const cardType = (myKadData.CardType && myKadData.CardType == STATICCODESERVICE.IDENTIFICATION_TYPE_MYKAD)
        ? STATICCODESERVICE.IDENTIFICATION_TYPE_MYKAD
        : STATICCODESERVICE.IDENTIFICATION_TYPE_MYKID

      const IdType = this.patientRegService.getCodeByStaticCode(
        this.identificationTypeList,
        cardType
      );

      const dob = moment(myKadData.BirthDate, "DD/MM/YYYY hh:mm:ss aa")
        .format("YYYY-MM-DD");

      this.genderValue = GenderCode;
      this.raceValue = RaceCode;
      this.religionValue = ReligionCode;
      this.countryValue = CountryCode;

      if(this.countryValue != this.defaultCountry)
      this.formRegistration.patchValue({
        countryPrimary: CountryCode
      });

      this.getStatePrimaryList(this.countryValue, true);

      this.formRegistration.patchValue({
        id: "",
        mrn: "",
        firstName: myKadData.Name,
        primaryId: IdType,
        expiryDate: "",
        primaryNumber: myKadData.ICNo,
        dateOfBirth: new Date(dob),
        gender: GenderCode,
        race: RaceCode,
        religion: ReligionCode,
        addressPrimary: myKadData.Address1 + " " + myKadData.Address2 + " " + myKadData.Address3,
        cityPrimary: "",
        statePrimary: "",
        pincodePrimary: myKadData.Postcode,
        issuedCountryPrimary: CountryCode
      });

      setTimeout(() => {
        const StateCode = this.patientRegService.getCodeBySystemCode(
          this.statePrimaryList,
          myKadData.State ? myKadData.State : ""
        );

        this.stateValue = StateCode;
        this.formRegistration.patchValue({
          statePrimary: StateCode,
        });

        const State = this.patientRegService.getObjByCode(
          this.statePrimaryList,
          StateCode
        );
        this.getCityPrimaryList(State);

        setTimeout(() => {
          const CityCode = this.patientRegService.getCodeBySystemCode(
            this.cityPrimaryList,
            myKadData.City ? myKadData.City : ""
          );

          this.cityValue = CityCode;
          this.formRegistration.patchValue({
            cityPrimary: CityCode,
          });
        }, 1500);
      }, 1500);

      if (myKadData.PhotoBase64) this.patientImage = "data:image/jpeg;base64," + myKadData.PhotoBase64;
      else this.patientImage = "";

      this.primaryNumberOnKeyUp(myKadData.ICNo);
      this.formRegistration.updateValueAndValidity();
    }
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getMyKadMasters() {
    this.getIdentificationTypeMaster();
    this.getGenderMaster();
    this.getRaceMaster();
    this.getReligionMaster();
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getGenderMaster() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_GENDER)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.genderList = data;
      });
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getReligionMaster() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_RELIGION)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.religionList = data;
      });
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getRaceMaster() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_RACE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.raceList = data;
      });
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getIdentificationTypeMaster() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_IDENTIFICATION)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.identificationTypeList = data;
      });
  }

  /**
   *Check reques appoinment
   *
   * @memberof PatientRegistrationComponent
   */
  checkRequestHasAppointment() {
    if (localStorage.getItem(OPD_CONST.appoinmentRef.aptData)) {
      let appointment: any = JSON.parse(
        localStorage.getItem(OPD_CONST.appoinmentRef.aptData)
      );
      if (appointment && appointment.type == OPD_CONST.appoinmentRef.temp) {
        this.patientForm = true;
        this.appointmentReference.appointmentId = appointment.id;
        this.appointmentReference.appointmentNo = appointment.appointmentNo;
        this.appointmentReference.tempPatientId = appointment.patientid;
        this.appointmentReference.healthPackage = appointment.healthPackage;
        this.getTemporaryPatient(appointment.mrn);
        this.appointmentReference.departmentCode = appointment.departmentCode;
        this.appointmentReference.doctorCode = appointment.doctorCode;
        this.appointmentReference.bookingType = appointment.bookingtype;

        this.formEncounter.patchValue({
          doctor: this.appointmentReference.doctorCode
            ? this.appointmentReference.doctorCode
            : "",
        });
      } else if (
        appointment &&
        appointment.type == OPD_CONST.appoinmentRef.reg
      ) {
        this.appointmentReference.doctorCode = appointment.doctorCode;
        this.appointmentReference.departmentCode = appointment.departmentCode;
        this.appointmentReference.appointmentNo = appointment.appointmentNo;
        this.appointmentReference.appointmentId = appointment.id;
        this.appointmentReference.healthPackage = appointment.healthPackage;
        this.appointmentReference.bookingType = appointment.bookingtype;
        this.getpatientbyId(appointment.mrn);
      }
      setTimeout(() => {
        this.departmentValue=this.departmentList.find(e=>e.code===appointment.departmentCode)
      }, 1000);
      this.doctorValueappointment=this.appointmentReference.doctorCode;
      this.tokenNoValue=appointment.token;
      if (this.appointmentReference.doctorCode) {
        this.getDoctorList(this.appointmentReference.departmentCode, true);
      }

      if (
        appointment &&
        appointment.bookingtype == OPD_CONST.aptref.apttypeDeptCode
      )
        this.formEncounter.patchValue({
          visitToDepartment: true,
        });
      if (
        appointment &&
        appointment.departmentCode == OPD_CONST.aptref.apttTypeHscDesc
      ) {
        this.formEncounter.patchValue({
          visitToDepartment: true,
        });
        this.isAppointmentHsc = true;
        let healhPackageConrol = this.formEncounter.controls.healthPackage;
        healhPackageConrol.setValidators([Validators.required]);
        var packagelist = [];

        this.appointmentReference.healthPackage.forEach((element) => {
          packagelist.push(element.code);
        });
        this.selectedOption = packagelist;
        this.formEncounter.patchValue({
          healthPackage: this.selectedOption,
        });
      }
      localStorage.removeItem(OPD_CONST.appoinmentRef.aptData);
    }
  }

  /**
   *admission check
   *
   * @memberof PatientRegistrationComponent
   */
  checkRequestHasAdmission() {
    if (localStorage.getItem(OPD_CONST.admissionFormRequest)) {
      this.isAdmissionRequest = true;
      this.admissionReqload = JSON.parse(
        localStorage.getItem(OPD_CONST.admissionFormRequest)
      );
      localStorage.removeItem(OPD_CONST.admissionFormRequest);
      this.getTemporaryPatient(this.admissionReqload.mrn);
    }
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  checkRequestHasAdmissionForm() {
    if (localStorage.getItem(OPD_CONST.admissionForm)) {
      this.isAdmissionRequest = true;
      this.admissionReqload = JSON.parse(
        localStorage.getItem(OPD_CONST.admissionForm)
      );
      localStorage.removeItem(OPD_CONST.admissionForm);
      if(this.admissionReqload.length>1)
      {
        this.patientForm = !this.patientForm;
        this.getpatientbyId(this.admissionReqload[0].mrn)
      }
      else if(this.admissionReqload.isEditPatient)
      {
        this.patientForm = !this.patientForm;
        this.getpatientbyId(this.admissionReqload.mrn)
      }
      else{
        this.resetRegisterationForm();
      }
      for (var i in this.formRegistration.controls) {
        this.formRegistration.controls[i].markAsTouched();
      }
      setTimeout(() => {
        this.checkValidEmployee();
      }, 1000);
    }
  }

  /**
   *temporary patient details
   *
   * @param {string} trnNumber
   * @memberof PatientRegistrationComponent
   */
  getTemporaryPatient(trnNumber: string) {
    this.patientTRN = trnNumber;
    this.patientForm = true;
    this.generalApiService
      .getTypeApi(
        OPD_API_URL.OPD_SERVICE,
        OPD_API_URL.OPD_TEMP_PATIENT_SEARCH + trnNumber
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res) {
          this.patientInfoDisplay = "";
          var dobFormatted = "";
          var patientAge = undefined;
          this.tempPatientRef.code = res.trn;
          this.tempPatientRef.system = res.id;
          this.tempPatientRef.display = res.patientName;
          if (res.dob) {
            dobFormatted = this.datepipe.transform(res.dob, "dd/MM/yyyy");
            let dobDate = new Date(res.dob);
            patientAge = this.utilsService.getAge(dobDate);
            patientAge =
              patientAge > 1
                ? "(" + patientAge + " Years)"
                : "(" + patientAge + " Year)";
          }
          this.patientInfoDisplay =
            res.trn +
            " / " +
            res.patientName +
            " / " +
            (res.genderList ? res.genderList[0].display : "Unknown") +
            " / " +
            (res.dob ? dobFormatted + " " + patientAge : "");
          this.selectedPatient = null;
          (this.identifierTypeValue = res.primaryIdentificationType
            ? res.primaryIdentificationType[0].code
            : ""),
            (this.titleValue = res.prefixList ? res.prefixList[0].code : "");
            if (this.genderList && this.genderList.length > 0) {
              this.defaultGender = this.genderList.find((i) => i.code ==  res.genderList[0].code);
            }
            (this.countryCodeValue = res.countryCode
              ? res.countryCode.code
              : ""),
            (this.countryValue =
              res.extendedAddress && res.extendedAddress.countryList
                ? res.extendedAddress.countryList[0].code
                : ""),
            (this.stateValue =
              res.extendedAddress && res.extendedAddress.stateList
                ? res.extendedAddress.stateList[0].code
                : null),
            (this.cityValue =
              res.extendedAddress && res.extendedAddress.cityList
                ? res.extendedAddress.cityList[0].code
                : null),
            (this.districtValue =
              res.extendedAddress && res.extendedAddress.areaList
                ? res.extendedAddress.areaList[0].code
                : null),
            this.getStatePrimaryList(this.countryValue, true);

          this.formRegistration.patchValue({
            prefix: res.prefixList ? res.prefixList[0].code : "",
            emailId: res.email,
            firstName: res.patientName,
            dateOfBirth: res.dob,
            mobileNumber: res.mobileNo,
            homeContact: res.homeNo,
            workContact: res.officeNo,
            primaryNumber: res.idNumber,
            primaryId: res.primaryIdentificationType
              ? res.primaryIdentificationType[0].code
              : "",
            expiryDate: res.idExpiry ? new Date(res.idExpiry) : "",
            addressPrimary: res.extendedAddress
              ? res.extendedAddress.addressLine
              : "",
            countryPrimary:
              res.extendedAddress && res.extendedAddress.countryList
                ? res.extendedAddress.countryList[0].code
                : "",
            statePrimary:
              res.extendedAddress && res.extendedAddress.stateList
                ? res.extendedAddress.stateList[0].code
                : "",
            cityPrimary:
              res.extendedAddress && res.extendedAddress.cityList
                ? res.extendedAddress.cityList[0].code
                : "",
            subDistrictPrimary:
              res.extendedAddress && res.extendedAddress.areaList
                ? res.extendedAddress.areaList[0].code
                : "",
            pincodePrimary: res.extendedAddress
              ? res.extendedAddress.postCode
              : "",
          });
        }
      });
  }

  /**
   *get documents using MRN
   *
   * @param {*} mrn
   * @memberof PatientRegistrationComponent
   */
  getDocuments(mrn: string) {
    this.fileUploadRegList = [];
    let payload={
      mrn:mrn,
      offset: 0,
      limit: 10,
      screenCode:DocumentUploadEnum.REG_SCREEN
    }
    this.appBaseService.setResourceURL(MICROSERVICES.DOC_SERVICE);
    this.appBaseService
      .postResource(APPLICATIONSERVICE.GET_DOCUMENT,payload)
      .subscribe((res) => {
        if (res.payload) {
          res.payload.fileResponse.forEach((element) => {
            let docType = this.documentTypelist.find((data) => {
              return data.desc == element.docVersion?element.docVersion:'';
            });
          
            let docListValue = this.docListRegistration.find((data) => {
              return data.desc == element.documentType;
            });
            this.fileUploadRegList.push({
              doctype:docListValue?docListValue.code:'',
              mrn: element.mrn,
              fileType: element.fileFormat,
              fileName: element.fileName,
              id: element.id,
              fileDescription: element.documentName,
              docFileType: docType?docType.code:'',
              isUploaded: true,
            });
          });
        }
        this.fileSourceReg = new MatTableDataSource(this.fileUploadRegList);
      });
  }

  /**
   *File upload from reg
   *
   * @param {*} isFromReg
   * @memberof PatientRegistrationComponent
   */
  fileUploadDialog(isFromReg) {
    const dialogRef = this.dialog.open(UploadfileComponent, {
      height: "auto",
      width: "300px",
      data: { mrn: "", encounter: "" },
    });
    let list = [];
    const screenCode = isFromReg ? DocumentUploadEnum.REG_SCREEN : DocumentUploadEnum.ENCOUNTER_SCREEN;
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((res) => {
        res.forEach((element) => {
          list.push({
            fileName: element.name,
            files: element.files,
            fileType: "",
            fileDescription:  element.name,
            docFileType:DocumentUploadEnum.FINAL,
            isUploaded: false,
            screenCode:screenCode,
            doctype:isFromReg?DocumentUploadEnum.PATIENT:""
          });
        });

        if (isFromReg) {
          if (this.fileUploadRegList.length > 0) {
            let listres = this.fileUploadRegList.concat(list);
            this.fileUploadRegList = listres;
            this.fileSourceReg = new MatTableDataSource(this.fileUploadRegList);
          } else {
            this.fileUploadRegList = list;
            this.fileSourceReg = new MatTableDataSource(this.fileUploadRegList);
          }
        } else {
          let listres = this.fileUploadList.concat(list);
          this.fileUploadList = listres;
          this.fileSource = new MatTableDataSource(this.fileUploadList);
        }
      })
    );
  }

  /**
   *update File List
   *
   * @param {*} fileType
   * @param {*} res
   * @param {*} isfromReg
   * @memberof PatientRegistrationComponent
   */
  upadteFileList(docFileType, res, isfromReg) {
    if (isfromReg)
      this.fileUploadRegList.find(
        (item) => item.fileName == res.fileName
      ).filetypevalue = docFileType;
    else
      this.fileUploadList.find(
        (item) => item.fileName == res.fileName
      ).filetypevalue = docFileType;
  }

  /**
   *
   *
   * @param {*} filedesc
   * @param {*} res
   * @param {*} isfromReg
   * @memberof PatientRegistrationComponent
   */
  updateFiledesc(filedesc, res, isfromReg) {
    if (isfromReg)
      this.fileUploadRegList.find(
        (item) => item.fileName == res.fileName
      ).description = filedesc;
    else
      this.fileUploadList.find(
        (item) => item.fileName == res.fileName
      ).description = filedesc;
  }

  /**
   *File save method
   *
   * @param {*} mrn
   * @param {*} visitNo
   * @param {*} isFromReg
   * @memberof PatientRegistrationComponent
   */
  fileSave(mrn, visitNo, isFromReg) {
    let list = [];
    list = isFromReg ? this.fileUploadRegList : this.fileUploadList;
    if (!isFromReg) {
      this.sfileUploadService.getFileUpload(
        list,
        mrn,
        visitNo,
        "ADMINISTRATIVE",
        "VISIT"
      );
    } else {
      this.sfileUploadService.getFileUpload(
        list,
        mrn,
        visitNo,
        "PATIENT",
        "MRN"
      );
    }
  }

  /**
   *
   *
   * @param {*} patient
   * @memberof PatientRegistrationComponent
   */
  getPendingOrders(patient) {
    let payload = {
      limit: 10,
      offset: 0,
      mrn: patient ? patient.mrn : "",
    };
    
    this.appBaseService.setResourceURL(OPD_API_URL.LAB_SERVICE);
    this.appBaseService
      .postResource( OPD_API_URL.LAB_PENDING_ORDERS,payload)
      .subscribe((res:any)=>{
        this.groupedItems = res.pendingFutureOrderList.reduce((prev, current) => {
          if (!prev[current.groupMaster.desc]) {
            prev[current.groupMaster.desc] = [];
          }
          prev[current.groupMaster.desc].push(current);
          return prev;
        }, {})
        this.showBadge=true;
        this.pendingOrderCount= res.pendingFutureOrderList.length;
      });
  }

  /**
   *
   *
   * @param {object} obj
   * @return {*}  {string[]}
   * @memberof PatientRegistrationComponent
   */
  objectKeys(obj: object): string[] {
    return Object.keys(obj);
  }

  /**
   *
   *
   * @param {*} category
   * @param {*} element
   * @memberof PatientRegistrationComponent
   */
  selectPending(category,element) {
    let list = this.groupedItems[category];
    if(element.checked) {
    list.forEach(element => {
        element.isSelected=true;
      });
    } else {
      list.forEach(element => {
        element.isSelected=false;
      });
    }
  }
  
  /**
   * Confirmation popup for delete file
   *
   * @param {*} element
   * @param {*} isfromReg
   * @memberof PatientRegistrationComponent
   */
  confirmDelete(element, isfromReg) {
    const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
      width: "30%",
      data: { displayText: "deleteConfirm" },
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result != undefined && result.confirm && result.confirm == "yes") {
          this.removeFile(element, isfromReg);
        }
      })
    );
  }

  /**
   *remove File
   *
   * @param {*} element
   * @param {*} isfromReg
   * @memberof PatientRegistrationComponent
   */
  removeFile(element, isfromReg) {
    if (isfromReg) {
      const index: number = this.fileSourceReg.data.indexOf(element);

      if (index != -1) {
        if (!element.isUploaded) {
          this.fileUploadRegList.splice(index, 1);
          this.fileSourceReg = new MatTableDataSource(this.fileUploadRegList);
        } else {
          this.appBaseService.setResourceURL(OPD_API_URL.DOC_SERVICE);
          this.appBaseService
            .deleteResource(OPD_API_URL.REMOVE_UPLODED_FILE +element.id)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((response: any) => {
              if (response.statusCode=="200") {
                this.snackBar.open(
                  this.langService.get("EncounterPage.fileDeletedSucces"),
                  this.langService.get("CommonAppoinment.success"),
                  {
                    duration: 1000,
                  }
                );
                this.fileUploadRegList.splice(index, 1);
                this.fileSourceReg = new MatTableDataSource(
                  this.fileUploadRegList
                );
              }
            });
        }
      }
    } else {
      const index: number = this.fileSource.data.indexOf(element);

      if (index != -1) {
        this.fileUploadList.splice(index, 1);
        this.fileSource = new MatTableDataSource(this.fileUploadList);
      }
    }
  }

  /**
   * To download a file
   *
   * @param element
   */
  downloadFile(element) {
    this.appBaseService.setResourceURL(OPD_API_URL.DOC_SERVICE);
    this.appBaseService
      .getMIMETypeResource(OPD_API_URL.OPD_DOWNLOAD_FILE + element.id)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        var b = new Blob([res], { type: res.type });
        saveAs(b, element.fileName);
      });
  }

  /**
   *patient search
   *
   * @memberof PatientRegistrationComponent
   */
  public initSearchFilter() {
    let url = !this.patientForm
      ? OPD_API_URL.M_PAT_REG_SEARCH
      : OPD_API_URL.M_PAT_REG_TYPE_SEARCH;
    this.subscriptionList.add(
      this.searchHead.getTypeSearch(url).subscribe((data: any) => {
        this.searchHead.updateSearchHeader(data);
      })
    );

    this.subscriptionList.add(
      this.searchService.getResult.subscribe((result) => {
        let keysArray = Object.keys(result);
        let hasValue = false;
        
        for (let i = 0; i < keysArray.length; i++) {
          let value = result[keysArray[i]];
          if (
            value !== "" && value !== null && value !== undefined && value !== false && 
            value.endDate !== null && value.startDate !== null &&
            value !== "Staff" && value !== "Student" && value !== "Patient" 
          ) {
            hasValue = true;
            break; 
          }
        }
        
        if (hasValue) {
          this.searchCriteria = this.searchHead.SetPatientSearch(result);
          this.isPatientType = result.userType;
          this.patientList = null;
          this.refreshTime = new Date().toString();
          this.hidePatientSearch = false;
        } else {
           this.snackBar.open("Warning", "Please select at least one search parameter to proceed with the data search.", {
            duration: 3000,
          });
        }
      })
    );
    let quickSearch = this.searchService.getElasticSearch.subscribe(
      (quickSearch) => {
        let result = { mrn: quickSearch };
        this.searchCriteria = this.searchHead.SetPatientSearch(result);
        this.patientList = null;
        this.refreshTime = new Date().toString();
        this.hidePatientSearch = false;
      }
    );
    this.subscriptionList.add(quickSearch);
    
  }

  /**
   *select patient Data
   *
   * @param {*} patient
   * @memberof PatientRegistrationComponent
   */
  patientOnSelect(patient) {
    this.getPatientStatus(patient);
    this.checkPatientLastVisit(patient);
    this.getPendingOrders(patient);
  }

  /**
   *
   *
   * @param {*} patient
   * @memberof PatientRegistrationComponent
   */
  getPatientStatus(patient) {
    this.appBaseService.setResourceURL(OPD_API_URL.OPD_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.PATIENT_STATUS_FILTER + patient.id)
      .subscribe((res) => {
        this.admissionStatus = res.isAdmitted;
        if (res.isAdmitted == true) {
          // this.patientForm=true;
          // this.isBackAllow=true;
          this.isEncounterAllow=true;
          if (res.validationMsg) {
            this.snackBar.open( this.langService.get("adt.Selectedpatient"),
            this.langService.get("dialogs.info"), {
              duration: 3000,
            });
          }
            this.getPatientData(patient);
            if (this.isPatientEditRights) this.isRegistered = false;
            else this.isRegistered = true;
        }  else {
          this.isEncounterAllow=false;
          this.isBackAllow=false;
          this.getPatientData(patient);
            if (this.isPatientEditRights) this.isRegistered = false;
            else this.isRegistered = true;
        }
      });
  }

  /**
   *
   *
   * @param {*} patientMrn
   * @memberof PatientRegistrationComponent
   */
  getOustandAmount(patientMrn) {
    this.generalApiService
      .getTypeApi(
        OPD_API_URL.OPD_SERVICE,
        OPD_API_URL.OPD_Outstanding_Bill + patientMrn
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res) {
          this.resdata = res;
          this.getPreviousEncounters(patientMrn);
        } else this.getPreviousEncounters(patientMrn);
      });
  }

  /**
   *check patient last visit occurred over 3 months ago
   *
   * @param {*} patient
   * @memberof PatientRegistrationComponent
   */ 
   checkPatientLastVisit(patient){
    this.appBaseService.setResourceURL(OPD_API_URL.OPD_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.CHECK_PATIENT_LAST_VISIT + patient.mrn)
      .subscribe((res) => {
        if(res.message!=null){
          this.snackBar.open(res.message,"Validation",{duration:3000})
        }
      });

      if(patient.expiryDate ){
        const existingDate = new Date(patient.expiryDate);
        const currentDate = new Date();
        if (existingDate < currentDate) {
        this.snackBar.open(
          this.langService.get("RegistrationPage.expiryDateValidationMessage"),
          this.langService.get("CommonAppoinment.validation"),
          {
            duration: 4000,
          });
          this.patientForm=true;
          this.isBackAllow=true;
          setTimeout(() => {
            for (var i in this.formRegistration.controls) {
              this.formRegistration.controls[i].markAsTouched();
            }
          }, 3000);
        }
      }
      if(this.isStaffActive){
        if(!patient.isStaffActive && patient.secondaryIdType && patient.isStaffActive!=undefined){
        this.snackBar.open(
          this.langService.get("RegistrationPage.activeStaffValidationMessage"),
          this.langService.get("CommonAppoinment.validation"),
          {
            duration: 4000,
          });
          this.patientForm=true;
          this.isBackAllow=true;
          this.isEncounterAllow=true;
          setTimeout(() => {
            this.checkValidEmployee();
          }, 1000); 
      }
      else{
        setTimeout(() => {
          this.checkValidEmployee();
        }, 1000); 
      }
      
    }
  }

  /**
   *get previous encounters data
   *
   * @param {*} patientMrn
   * @memberof PatientRegistrationComponent
   */
  getPreviousEncounters(patientMrn) {
    let list = [];
    //this.formEncounter.reset();
    let reqPayload = patientMrn + OPD_API_URL.OPD_PREVIOUS_ENCOUNTERS;
    if (this.isPatientCategory) {
      this.formEncounter.get("patientCategory").disable();
    } else {
      this.formEncounter.get("patientCategory").enable();
    }
    this.generalApiService
      .getTypeApi(
        OPD_API_URL.OPD_SERVICE,
        OPD_API_URL.OPD_ENCOUNTER_LIST + reqPayload
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        res.totalRecords>0 ? this.pdpaForm = true : this.pdpaForm = false
        if (res && res.totalRecords > 0) {
          res.response.forEach((element, index) => {
            var utcSeconds = element.createdDate;
            var encounterDate = new Date(0);
            this.previousInternalNotes = element.internalNoteList;
            this.internotesEncounterid = element.id;
            // this.patientImage = element.patient
            //   ? element.patient[0].photo
            //     ? element.patient[0].photo[0].url
            //     : ""
            //   : "";
            const OutAmt = this.resdata.filter(
              (data) => data.encounterNumber == element.encounterNumber
            );

            list.push({
              EncounterId: element.id,
              VisitDate: encounterDate.setUTCSeconds(utcSeconds),
              VisitNumber: element.encounterNumber,
              visitType:
              element.visitType && element.visitType.display
              ? element.visitType.display
              : "",
              payer: element.payer ? element.payer : "",
              Department: element.department ? element.department.display : "",
              DoctorName: element.doctor ? element.doctor.display : "",
              OutstandingAmount:
                OutAmt.length == 0 ? 0 : OutAmt[0].outstandingAmt,
              BillNumber: "",
              BillAmount: element.billAmount ? element.billAmount : "0.00",
            });
          });
        }
        this.previousEncounterList = list;
        if (list.length > 0) {
          var id = this.previousEncounterList.reduce(
            (a, { OutstandingAmount }) =>
              Number(OutstandingAmount) > a ? Number(OutstandingAmount) : a,
            -1
          );
          if (Number(id) > 0 && id != null) {
            const dialogRef = this.dialog.open(InfoDialogComponent, {
              width: "30%",
              data: {
                display: "Outstanding Info",

                info:
                  this.langService.get("RegistrationPage.patienthas") +
                  " " +
                  Number(id).toFixed(2) +
                  " " +
                  this.langService.get("RegistrationPage.outstandingAmtMsg"),
              },
            });
          }
          this.dataSourceEncounters = new MatTableDataSource<any>(list);
        }
        this.getTariffList();
      });
  }

  /**
   *setlanguage list
   *
   * @param {*} value
   * @return {*}
   * @memberof PatientRegistrationComponent
   */
  setObjectListDesc(value) {
    var res;
    this.selected = localStorage.getItem("lan").toUpperCase();
    value.forEach((element) => {
      if (element.system == this.selected) {
        res = element.display;
      }
    });
    return res;
  }

  /**
   *
   *
   * @param {*} value
   * @return {*} 
   * @memberof PatientRegistrationComponent
   */
  setObjectListCode(value) {
    var res;
    this.selected = localStorage.getItem("lan").toUpperCase();
    value.forEach((element) => {
      if (element.system == this.selected) {
        res = element.code;
      }
    });
    return res;
  }

  /**
   *Validate Registration
   *
   * @return {*}  {boolean}
   * @memberof PatientRegistrationComponent
   */
   validateRegistration(): boolean {
    if (this.isDeceased) {
      return this.formRegistration.valid;
    } else {
      if(!this.formRegistration.controls.mobileNumber.valid) {
        this.formRegistration.controls.mobileNumber.markAsTouched();
        if(!this.formRegistration.controls.mobileNumber.disabled) {
          this.snackBar.open(
            this.langService.get("CommonAppoinment.addDependend"),
            this.langService.get("CommonAppoinment.validation"),
            { duration:1000 }
          );
        }
        return this.formRegistration.valid;
      } else {
        for (var i in this.formRegistration.controls) {
          this.formRegistration.controls[i].markAsTouched();
        }
        if (!this.formRegistration.valid) {
          this.snackBar.open(
            this.langService.get("CommonAppoinment.pleasefillallmandatory"),
            this.langService.get("CommonAppoinment.validation"),
            {
              duration: 1000,
            }
          );
        }
        return this.formRegistration.valid;
      }
    }
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  openRegisterConfirmDialog(): void {
    if (this.validateRegistration()) {
      if(this.formRegistration.controls.isVip.value) {
        const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
          width: "15%",
          data: { displayText: "conformVipMessage"},
        });
        this.subscriptionList.add(
          dialogRef.afterClosed().subscribe((result) => {
            if (result && result.confirm == "yes") {
              this.openRegisterConfirmationDialog();
            }
          })
        );
      } else {
        this.openRegisterConfirmationDialog();
      } 
    }
 }

 /**
  *
  *
  * @memberof PatientRegistrationComponent
  */
 openRegisterConfirmationDialog(): void {
    const dialogRef = this.dialog.open(RegsuccessDialogComponent, {
      width: "20%",
      data: {},
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.workflow == "yes") {
          this.savePatient();
        }
      })
    );
  }

  /**
   *save encounter confirm window
   *
   * @memberof PatientRegistrationComponent
   */
  openEncounterConfirmDialog(): void {
    // if (this.selectedOption.length > 0 && this.validateEncounter()) {
    //   this.validatePackage();
    // } else
     if (this.validateEncounter()) {
      // if (
      //   (this.formEncounter.controls.tokenNo.value && this.isToken == true) ||
      //   !this.formEncounter.controls.tokenNo.value
      // ) {
        const dialogRef = this.dialog.open(RegsuccessDialogComponent, {
          width: "20%",
          data: {},
        });
        this.subscriptionList.add(
          dialogRef.afterClosed().subscribe((result) => {
            if (result && result.workflow == "yes") {
              this.saveEncounter();
            }
          })
        );
      // }
    }
  }

  /**
   *Save Patient Method
   *
   * @memberof PatientRegistrationComponent
   */
  savePatient() {
    if (this.validateRegistration()) {
      this.patientRegService.addHealthTouristDetails(this.healthTouristList);
      let patient: PatientRegistration =
        this.patientRegService.getRegistrationFormValues(
          this,
          this.formRegistration
        );
      
      let requestObject = this.patientRegService.setSavePayload(this, patient);
      if (this.isPatientType == "Staff" || this.isPatientType == "Student")
        patient.id = "";
      if (!patient.id) {
        this.generalApiService
          .postTypeApi(
            OPD_API_URL.OPD_SERVICE,
            OPD_API_URL.S_PATIENT_REG,
            requestObject
          )
          .pipe(takeUntil(this._onDestroy))
          .subscribe((response: any) => {
            let resMrn = response.identifier
              ? response.identifier[0].value
              : "";
            this.formRegistration.patchValue({
              id: response.id,
              mrn: resMrn,
            });
            if (this.fileSourceReg.data.length > 0 && resMrn) {
              this.fileSave(resMrn, "", true);
            }
            if (this.isAdmissionRequest) {
              this.admissionReqload.mrn = resMrn;
              localStorage.setItem(
                OPD_CONST.admissionFormRequest,
                JSON.stringify(this.admissionReqload)
              );
              if (this.admissionReqload.isDayadmission == true) {
                this.router.navigate([opdNavigation.DayCare_NAVIGATE]);
              } else {
                this.router.navigate([opdNavigation.ADT_NAVIGATE]);
              }
            }
            this.patientSaveOnSuccess(resMrn, response.id);
          });
      } else {
        if (this.isUpdateRequired) {
          const dialogRef = this.dialog.open(SigninModalComponent, {
            height: "auto",
            width: "auto",
            data: { message: "Do you want to Save?" },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result == true) {
              this.updatePatient();
            }
          });
        } else {
          this.updatePatient();
        }
      }
    }
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  updatePatient() {
    let patient: PatientRegistration =
      this.patientRegService.getRegistrationFormValues(
        this,
        this.formRegistration
      );
    let requestObject = this.patientRegService.setSavePayload(this, patient);

    this.generalApiService
      .putTypeApi(
        OPD_API_URL.OPD_SERVICE,
        OPD_API_URL.S_PATIENT_UPDATE + patient.id,
        requestObject
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((response: any) => {
        let resMrn = response.identifier ? response.identifier[0].value : "";
        this.formRegistration.patchValue({
          id: response.id,
          mrn: response.mrnno,
        });
        if (this.fileSourceReg.data.length > 0 && resMrn) {
          this.fileSave(resMrn, "", true);
        }
        if (this.isAdmissionRequest) {
          this.admissionReqload.mrn = resMrn;
          localStorage.setItem(
            OPD_CONST.admissionFormRequest,
            JSON.stringify(this.admissionReqload)
          );
          if (this.admissionReqload.isDayadmission == true) {
            this.router.navigate([opdNavigation.DayCare_NAVIGATE]);
          } else {
            this.router.navigate([opdNavigation.ADT_NAVIGATE]);
          }
        }
        
        this.patientSaveOnSuccess(resMrn, response.id);
      });
  }

  /**
   *get list data details by code
   *
   * @param {any[]} list
   * @param {string} code
   * @return {*}  {string}
   * @memberof PatientRegistrationComponent
   */
  getObjByCode(list: any[], code: string): string {
    let value;
    if (list && list.length > 0) {
      value = list.find((i) => i.code == code);
    }
    return value;
  }

  /**
   *redirect encounter page
   *
   * @memberof PatientRegistrationComponent
   */
  transactionIncompleteDilog() {
    if(this.isAdmissionRequest) {
      if(this.selectedPatient)
      this.admissionReqload.mrn = this.selectedPatient.mrn;
      localStorage.setItem(
        OPD_CONST.admissionFormRequest,
        JSON.stringify(this.admissionReqload)
      );
      this.router.navigate([opdNavigation.ADT_NAVIGATE]);
    } else {
      this.patientForm = false;
      this.initSearchFilter();
    }
  }

  /**
   *after patient save call method
   *
   * @param {*} patientId
   * @memberof PatientRegistrationComponent
   */
  patientSaveOnSuccess(patientId, id) {
    this.patientTRN = "";
    this.isSameAddress = false;
    this.getpatientbyId(patientId);
    this.patientForm = false;
    this.patientId = patientId;
    this.tempPatientRef.code = "";
    this.tempPatientRef.display = "";
    this.tempPatientRef.system = "";
    if(this.isPatientRequired){
    let reqPayload = {
      transactionId: id,
      type: OPD_REPORT_TYPE.pdf,
      reportName: OPD_REPORT_NAME.patient_Card,
      isReports: false,
    };
    localStorage.setItem(OPD_CONST.reportPayload, JSON.stringify(reqPayload));
    window.open(opdNavigation.REPORT_NAVIGATE);
  }
    this.isAdmissionRequest = false;
    localStorage.removeItem(OPD_CONST.appoinmentRef.aptData);
    this.appointmentReference.doctorCode = "";
    this.appointmentReference.departmentCode = "";
    this.snackBar.open(
      this.langService.get("EncounterPage.patientSaved"),
      this.langService.get("CommonAppoinment.success"),
      {
        duration: 1000,
      }
    );
  }

  /**
   *getpatient search by id
   *
   * @param {*} patientId
   * @memberof PatientRegistrationComponent
   */
  getpatientbyId(patientId) {
    let reqPayload =
      "identificationId=&name=&mrn=" +
      patientId +
      "&gender=&unitCode=&city=&identificationType=&frDate=&toDate=&id=&mobile=&birthDate=&offset=&size=&pincode=&country=&state=";
    this.generalApiService
      .getTypeApi(
        OPD_API_URL.OPD_SERVICE,
        OPD_API_URL.OPD_PATIENT_SEARCH + reqPayload
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any[]) => {
        let pateintData = this.patientDataService.displayList(res);
        this.getPatientData(pateintData[0]);
      });
  }

  /**
   *validate doctor
   *
   * @memberof PatientRegistrationComponent
   */
  validationOnDoctor() {
    let departmentCode = this.formEncounter.controls.department.value;
    let doctorCode = this.formEncounter.controls.doctor.value;
    if (this.patientId && departmentCode) {
      this.generalApiService
        .getTypeApi(
          OPD_API_URL.OPD_SERVICE,
          OPD_API_URL.OPD_DUPLICATE_ENCOUNTER +
            departmentCode.code +
            "&doctorCode=" +
            doctorCode +
            "&patientId=" +
            this.patientId
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((response: any) => {
          if (response) {
            this.snackBar.open(
              this.langService.get("CommonAppoinment.warning"),
              this.langService.get("CommonAppoinment.duplicateEncouter") + "",
              {
                duration: 1000,
              }
            );
          }
        });
    }
  }

  /**
   *validate encounter
   *
   * @return {*}  {boolean}
   * @memberof PatientRegistrationComponent
   */
  validateEncounter(): boolean {
    let valid = true;
    let payersList = this.dataSourcePayers.data;
    if (this.selectedPatient == null) {
      valid = false;
      this.snackBar.open(
        this.langService.get("EncounterPage.pleaseSelectPatient"),
        this.langService.get("CommonAppoinment.validation"),
        {
          duration: 1000,
        }
      );
    }

    if (payersList.length > 0) {
      payersList.forEach((element) => {
        if (element.payerCode == "" || element.payerCode == undefined) {
          valid = false;
          this.snackBar.open(
            this.langService.get("EncounterPage.pleaseselectreferrconpamy"),
            this.langService.get("CommonAppoinment.validation"),
            {
              duration: 1000,
            }
          );
        }
      });
    }
    if (payersList.length > 0) {
      payersList.forEach((element) => {
        if (element.tariffCode == "" || element.tariffCode == undefined) {
          valid = false;
          this.snackBar.open(
            this.langService.get("CommonAppoinment.PleaseSelectTariff"),
            this.langService.get("CommonAppoinment.validation"),
            {
              duration: 1000,
            }
          );
        }
      });
    }

    if (valid) {
      for (var i in this.formEncounter.controls) {
        this.formEncounter.controls[i].markAsTouched();
      }
      valid = this.formEncounter.valid;

      if (!valid) {
        this.snackBar.open(
          this.langService.get("CommonAppoinment.pleasefillallmandatory"),
          this.langService.get("CommonAppoinment.validation"),
          {
            duration: 1000,
          }
        );
      }
    } 
    if (this.kinData.length == 0 && this.isNOKRequired) {
      valid = false;
      this.snackBar.open(
        this.langService.get("Please Add  Next of Kin (Dependent) Details"),
        this.langService.get("CommonAppoinment.validation"),
        {
          duration: 1000,
        }
      );
    }
    if(this.fileUploadList && this.fileUploadList.length > 0){
      let doctType=this.fileUploadList.find((i) => i.doctype =='' ||i.doctype ==undefined);
      if(doctType){
        valid=false;
        this.snackBar.open("Warning", "Please select the Document Type", {
          duration: 2000,
        });
      }
    }
    return valid;
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  validatePackage() {
    if (this.selectedOption.length > 0) {
      let tariiffDetails = this.dataSourcePayers.data.find(
        (s) => s.priority == 1
      );
      let tariffId = this.tariffList.find(
        (x) => x.code == tariiffDetails.tariffCode
      );
      let packageList = [];
      if (this.selectedOption.length > 0) {
        for (let i = 0; i < this.selectedOption.length; i++) {
          let value = this.healthPackageList.find(
            (s) => s.code == this.selectedOption[i]
          );
          packageList.push(value.id);
        }
      }
      let requestObject = {
        tariffId: tariffId.id,
        packageList: packageList.length > 0 ? packageList : [],
      };
      this.generalApiService
        .postTypeApi(
          OPD_API_URL.MASTER_SERVICE,
          OPD_API_URL.M_PACKAGEVALIDATION,
          requestObject
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((response: any) => {
          if (response.desc) {
            this.snackBar.open(
              response.desc,
              this.langService.get("CommonAppoinment.validation"),
              {
                duration: 1000,
              }
            );
          } else {
            const dialogRef = this.dialog.open(RegsuccessDialogComponent, {
              width: "20%",
              data: {},
            });
            this.subscriptionList.add(
              dialogRef.afterClosed().subscribe((result) => {
                if (result && result.workflow == "yes") {
                  this.saveEncounter();
                }
              })
            );
          }
        });
    }
  }

  /**
   *Save encounter
   *
   * @memberof PatientRegistrationComponent
   */
  saveEncounter() {
    let encounter = this.patientRegService.getEncounterFormValues(
      this,
      this.formEncounter
    );
    encounter.defaultDeposit = this.defaultDeposit;
    let dataSourcePayers = this.patientRegService.getEncounterPayerFormValues(
      this,
      this.dataSourcePayers
    );
    let requestObject = this.patientRegService.setEncounterSave(
      this,
      encounter,
      dataSourcePayers,
      //this.kinData
      this.dataSourceNextOfKin.data
    );
    this.generalApiService
      .postTypeApi(
        OPD_API_URL.OPD_SERVICE,
        OPD_API_URL.S_PATIENT_ENCOUNTER,
        requestObject
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe(async (response: any) => {
        if (response && response.id) {
          this.snackBar.open(
            this.langService.get("EncounterPage.encounterHeader") +
              " " +
              response.identifier[0].value +
              " " +
              this.langService.get("EncounterPage.savedSuccess") +
              " ",
            this.langService.get("CommonAppoinment.success"),
            {
              duration: 1000,
            }
          );
          this.encounterid = response.id;
          this.visitno = response.identifier[0].value;
          this.previousInternalNotes = [];
          this.internalNotesList = [];
          this.appointmentData = null;
          if (this.fileSource.data.length > 0) {
            this.fileSave(this.selectedPatient.mrn, this.visitno, false);
          }
          if(this.defaultDeposit){
          const payload = {
            transactionId: response.id,
            type: OPD_REPORT_TYPE.pdf,
            reportName: OPD_REPORT_NAME.default_deposit_slip, 
            isReports: false,
          };
          localStorage.setItem(
            OPD_CONST.reportPayload2,
            JSON.stringify(payload)
          );
          window.open(opdNavigation.REPORT_NAVIGATE );
        }
          //   let reqPayload = {
          //     transactionId: this.encounterid,
          //     type: OPD_REPORT_TYPE.pdf,
          //     reportName: OPD_REPORT_NAME.encounter_Slip,
          //     isReports: false,
          //   };
          //   localStorage.setItem(
          //     OPD_CONST.reportPayload,
          //     JSON.stringify(reqPayload)
          //   );
          // await setLocalStorageItem( OPD_CONST.reportPayload,  reqPayload);
          //   window.open(opdNavigation.REPORT_NAVIGATE);
          //   let reqPayload1 = {
          //     transactionId: this.encounterid,
          //     type: OPD_REPORT_TYPE.pdf,
          //     reportName: OPD_REPORT_NAME.pdpaForm_new,
          //     isReports: false,
          //   };
          //  // localStorage.setItem("reportPayload2", JSON.stringify(reqPayload1));
          //   await setLocalStorageItem("reportPayload2",  reqPayload1);
          //   window.open(opdNavigation.REPORT_NAVIGATE);
          //   setTimeout(() => {
          //     let reqPayload2 = {
          //       transactionId: this.encounterid,
          //       type: OPD_REPORT_TYPE.pdf,
          //       reportName: OPD_REPORT_NAME.patient_Label,
          //       isReports: false,
          //     };
          //     localStorage.setItem("reportPayload3", JSON.stringify(reqPayload2));
          //    // await setLocalStorageItem("reportPayload3", reqPayload2);
          //     window.open(opdNavigation.REPORT_NAVIGATE);
          //   }, 1500);
          // let reportList = [
          //   {
          //     id: "1",
          //     code: OPD_REPORT_NAME.patient_Label,
          //     desc: "reportPayload",
          //   },
          //   {
          //     id: "2",
          //     code: OPD_REPORT_NAME.pdpaForm_new,
          //     desc: "reportPayload2",
          //   },
          //   {
          //     id: "3",
          //     code: OPD_REPORT_NAME.encounter_Slip,
          //     desc: "reportPayload3",
          //   },
          // ];

          // let promises = reportList.map(async (element) => {
          //   let reqPayload = {
          //     transactionId: this.encounterid,
          //     type: OPD_REPORT_TYPE.pdf,
          //     reportName: element.code,
          //     isReports: false,
          //   };
          //   await Promise.all(promises);
          //   await setLocalStorageItem(element.desc, reqPayload);

          // window.open(opdNavigation.REPORT_NAVIGATE);
          // });
          // generateReports(reportList, this.encounterid);
          let visitNo=response?.identifier[0]?.value;
          if (!this.pdpaForm) {
            this.patientLableService.generateReports(this.encounterid,OPD_REPORT_NAME.patient_Label);
            this.pdpaFormService.generateReports(this.encounterid,OPD_REPORT_NAME.pdpaForm_new);
            this.encounterSlipService.generateReports(this.encounterid,OPD_REPORT_NAME.encounter_Slip);
          }else{
          this.patientLableService.generateReports(this.encounterid,OPD_REPORT_NAME.patient_Label);
          this.encounterSlipService.generateReports(this.encounterid,OPD_REPORT_NAME.encounter_Slip);
          }
          this.clearAllPatientEncounter();
        } else {
          this.getPayerTypeList();
          // this.getPayerList();
        }
      });
  }

  /**
   *
   *
   * @param {string} encounterId
   * @param {string} reportname
   * @memberof PatientRegistrationComponent
   */
  printReport(encounterId: string, reportname: string) {
    let reqPayload = {
      transactionId: encounterId,
      type: OPD_REPORT_TYPE.pdf,
      reportName: reportname,
      isReports: false,
    };
    localStorage.setItem(OPD_CONST.reportPayload, JSON.stringify(reqPayload));
    window.open(opdNavigation.REPORT_NAVIGATE);
  }

  /**
   *add appointment method
   *
   * @memberof PatientRegistrationComponent
   */
  addAppointment() {
    this.doctorValueappointment = null;
    var data = {
      department: this.formEncounter.controls.department.value
        ? this.formEncounter.controls.department.value
        : "",
      doctor: this.formEncounter.controls.doctor.value
        ? this.formEncounter.controls.doctor.value
        : "",
      isDepartment: this.formEncounter.controls.visitToDepartment.value
        ? this.formEncounter.controls.visitToDepartment.value
        : false,
      isFromEcnounter: true,
    };
    localStorage.setItem(
      OPD_CONST.appoinmentRef.appointmentEncounter,
      JSON.stringify(data)
    );
    const dialogRef = this.dialog.open(PatientAppointmentDialogComponent, {
      height: "900px",
      width: "1250px",
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        let res = result;
        if (result) {
          if (res.appointmentType == OPD_CONST.aptref.apttypeDeptCode) {
            this.formEncounter.patchValue({
              visitToDepartment: true,
            });
            this.formEncounter.controls.department.markAsTouched();
            this.departmentFilterList=this.departmentList;
            this.departmentValue=this.departmentList.find(e=>e.code===result.department);
          } else {
            this.formEncounter.patchValue({
              visitToDepartment: false,
            });
            this.departmentFilterList=this.departmentList;
            this.departmentValue=this.departmentList.find(e=>e.code===result.department);
            this.formEncounter.controls.department.markAsTouched();
            this.getDoctorList(result.department, true);
            (this.doctorValueappointment = result.doctor
              ? result.doctor.code
              : ""),
              this.formEncounter.controls.doctor.markAsTouched();
          }
          var slotTime = this.datepipe.transform(
            res.fromTime,
            "dd-MM-yyyy hh:mm aa"
          );
          this.formEncounter.patchValue({
            appointmentSlots: res.fromTime ? slotTime : "",
          });
          var dateValue = this.datepipe.transform(res.fromTime, "yyyy-MM-dd");
          this.appointmentData = {
            slotId: res.slotId,
            fromTime: res.fromTime,
            toTime: res.toTime,
            date: dateValue,
          };
        }
      })
    );
  }

  /**
   *add internal notes
   *
   * @memberof PatientRegistrationComponent
   */
  addInternalNotes() {
    if (!this.previousInternalNotes) {
      this.previousInternalNotes = [];
    }
    const dialogRef = this.dialog.open(PatientNotesDialogComponent, {
      height: "350px",
      width: "1000px",
      data: {
        listNotes: this.previousInternalNotes,
        name: "Encounter",
        encounterId: this.internotesEncounterid,
        isPrint:false
      },
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        let res = result;
        this.internalNotesList = res;
      })
    );
  }
 
  /**
   *set mandatory fields
   *
   * @memberof PatientRegistrationComponent
   */
  getScreenConfigurations() {
    this.subscriptionList.add(
      this.applicationConfig
        .getScreenConfiguration("OPD", MasterSubMenus.Registration)
        .subscribe((config: any) => {
          if (config) {
            this.mandatorykeys.setMandatoryFormValues(config, mandatoryFields);
          }
          const clinicElement = config.find(element => element.key == ManditoryKayEnum.clinic);
          if (clinicElement) {
            this.isGetClinicElement = clinicElement.systemMandatory;
            this.isClinicReqired = clinicElement.systemMandatory;
          }
        })
    );
  }
  
  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getAppConfigList() {
    let mrdConfig: any;
    // this.applicationConfigService
    //   .getModuleConfiguration(MasterMenus.OPD, "",false)
    //   .subscribe((res) => {
      this.appBaseService.setResourceURL(MICROSERVICES.APP_CONFIG_SERVICE);
      this.appBaseService
        .getResource( "appConfiguration/findConfigByModule?unitCode="+this.unitCode+
          "&orgCode="+this.orgCode+"&moduleName="+OPD_CONST.patientManagement+"&keyName=")
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
        if (res) {
          mrdConfig = res.keys;
          mrdConfig.forEach((element) => {
            if (element.key == MasterKeys.patientCategoryTariffRequired) {
              this.isPatientCategory =
                element.valueList[0].code == "true" ? true : false;
            }
            if (element.key == MasterKeys.updateAuthRequired) {
              this.isUpdateRequired =
                element.valueList[0].code == "true" ? true : false;
            }
            if (element.key == MasterKeys.NextOfKinRequired){
              if (element.valueList.length > 0) {
                this.isNOKRequired = element.valueList[0].code=="true"?true:false;
              }
            }
            if (element.key == MasterKeys.staffActiveFlag){
              if (element.valueList.length > 0) {
                this.isStaffActive = element.valueList[0].code=="true"?true:false;
              }
            }
            if (element.key == MasterKeys.patientCardRequired){
              if (element.valueList.length > 0) {
                this.isPatientRequired = element.valueList[0].code=="true"?true:false;
              }
            }
            if (element.key == MasterKeys.defaultNationality){
              if (element.valueList.length > 0) {
                this.defaultNationality = element.valueList[0]?.code;
              }
            }
            if (element.key == MasterKeys.defaultCountry){
              if (element.valueList.length > 0) {
                this.defaultCountry = element.valueList[0]?.code;
                this.getStatePrimaryList(this.defaultCountry ,true);
                this.getCountryCode(element.valueList[0]?.code,false);
              }
            }
          });
        }
      });
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getOrgAppConfigList() {
    let appConfig: any;
    this.applicationConfigService
      .getModuleConfiguration(MasterMenus.OPD, "",true)
      .subscribe((res) => {
        if (res) {
          appConfig = res;
          appConfig.forEach((element) => {
           
            if (element.key == MasterKeys.StaffPatientCategory){
              if (element.valueList.length > 0) {
               this.MasterStaffCode = element.valueList[0].code;
              }
            }
            if (element.key == MasterKeys.StudentPatientCategory){
              if (element.valueList.length > 0) {
                this.MasterStudentCode = element.valueList[0].code;
              }
            }
            if (element.key == MasterKeys.isRaceDependentRequired){
              if (element.valueList.length > 0) {
                this.isNationDependant = element.valueList[0].code=="true"?true:false;
                this.isNationDependant === false ? this.getRace() : null;
              }
            }
            if (element.key == MasterKeys.firstPayerPriorityRequired){
              if (element.valueList.length > 0) {
                this.isFirstPayerPriority = element.valueList[0].code=="true"?true:false;
              }
            }
            if (element.key == MasterKeys.isDefaultDepositRequired){
              if (element.valueList.length > 0) {
                this.isDefaultDeposit = element.valueList[0].code=="true"?true:false;
              }
            }
          });
        }
      });
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getOrgMrdAppConfigList(){
    let mrdConfig: any;
    this.applicationConfigService
      .getModuleConfiguration(MasterMenus.OPD, "",true)
      .subscribe((res) => {
        if (res) {
          mrdConfig = res;
          mrdConfig.forEach((element) => {
            if (element.key == MasterMenus.MRD) {
              this.mrdDepartment = element.valueList[0].code;
            }
          });
        }
      });
  }

  /**
   *confirm clear window
   *
   * @memberof PatientRegistrationComponent
   */
  confirmClearPatientEncounter() {
    const dialogRefConfirm = this.dialog.open(ConfirmDialogComponent, {
      width: "350px",
      data: { confirm: "no" },
    });
    this.subscriptionList.add(
      dialogRefConfirm.afterClosed().subscribe((result) => {
        if (result.confirm == "yes") {
          this.clearAllPatientEncounter();
        }
      })
    );
  }

  /**
   *race master
   *
   * @memberof PatientRegistrationComponent
   */
  getRace() {
    this.appBaseService.setResourceURL(OPD_API_URL.MASTER_SERVICE);
    this.appBaseService
      .getResource(MASTERSSERVICES.RACE_MASTER)
      .subscribe((res) => {
        this.nationalityWiseRace = res;
        this.raceFilterList = res;
      });
  }

  /**
   *clear all the patient data
   *
   * @memberof PatientRegistrationComponent
   */
  clearAllPatientEncounter() {
    this.isAppointmentHsc = false;
    this.raceMasterSearch=null;
    this.showBadge=false;
    localStorage.removeItem(OPD_CONST.appoinmentRef.aptData);
    localStorage.removeItem(OPD_CONST.admissionFormRequest);
    this.genderValue = null;
    this.identifierTypeValue = null;
    this.primaryIdSearch = null;
    this.countryCodeValue = null;
    this.titleValue = null;
    this.countryValue = null;
    this.appointmentReference.doctorCode = "";
    this.appointmentReference.departmentCode = "";
    this.previousInternalNotes = [];
    this.internalNotesList = [];
    this.appointmentData = null;
    this.isAdmissionRequest = false;
    this.patientId = "";
    this.selectedPatient = null;
    this.patientInfoDisplay = "";
    this.defaultDeposit = "";
    this.formRegistration.reset();
    this.formEncounter.reset();
    this.formNextOfKin.reset();
    this.countrySecondaryValue = null;
    this.stateSecondaryValue = null;
    this.citySecondaryValue = null;
    this.districtSeoncdaryValue = null;
    this.CountryCode = null;
    this.StateCode = null;
    this.CityCode = null;
    this.stateValue = null;
    this.cityValue = null;
    this.districtValue = null;
    this.secondaryCountryCode = null;
    this.secondaryStateCode = null;
    this.secondaryDistrictCode = null;
    this.encounterTypeValue = null;
    this.departmentValue = null;
    this.doctorValue = null;
    this.doctorValueappointment = null;
    this.countryEmploymentValue = null;
    this.stateEmploymentValue = null;
    this.cityEmploymentValue = null;
    this.raceValue = null;
    this.religionValue = null;
    this.patientImage = "";
    this.selectedPatient=null;
    this.fileSource = new MatTableDataSource();
    this.fileSourceReg = new MatTableDataSource();
    this.dataSourceNextOfKin = new MatTableDataSource<any>([]);
    this.dataSourceEncounters = new MatTableDataSource<any>([]);
    // let patientEncounterPayer = new PatientEncounterPayer();
    // patientEncounterPayer.isSelf = true;
    this.dataSourcePayers = new MatTableDataSource<any>([]);
    this.getPayerTypeList();
    // this.getPayerList();
    this.groupedItems=[];
    this.getUnitData();
    this.fillDropDownLists();
    this.pdpaForm = false;
  }

  /**
   *remove payer
   *
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  removePayer(element) {
    let list = this.dataSourcePayers.data;
    const index: number = list.indexOf(element);
    list.splice(index, 1);
    this.dataSourcePayers = new MatTableDataSource<any>(list);
  }

  /**
   *change payer priority
   *
   * @param {boolean} moveUp
   * @param {number} index
   * @memberof PatientRegistrationComponent
   */
  changePayerPriority(moveUp: boolean, index: number) {
    let list = this.dataSourcePayers.data;
    var element = list[index];
    if (moveUp && index > 0) {
      list.splice(index, 1);
      list.splice(index - 1, 0, element);
    } else if (!moveUp && index != list.length - 1) {
      list.splice(index, 1);
      list.splice(index + 1, 0, element);
    }
    this.dataSourcePayers = new MatTableDataSource<PatientEncounterPayer>(list);
  }

  /**
   *address same check box
   *
   * @param {*} event
   * @memberof PatientRegistrationComponent
   */
  bothAddressSame(event) {
    if (event.checked) {
      this.isSameAddress = event.checked;
      this.formRegistration.patchValue({
        addressSecondary: this.formRegistration.controls.addressPrimary.value,
        pincodeSecondary: this.formRegistration.controls.pincodePrimary.value,
      });
      this.countrySecondaryValue =
        this.formRegistration.controls.countryPrimary.value.code;
      this.getStateSecondaryList(this.countrySecondaryValue, true);
    } else {
      this.formRegistration.patchValue({
        addressSecondary: null,
        pincodeSecondary: null,
      });
      this.countrySecondaryValue = null;
      this.stateSecondaryValue = null;
      this.citySecondaryValue = null;
      this.districtSeoncdaryValue = null;
    }
  }

  /**
   *get patient data
   *
   * @param {*} patient
   * @memberof PatientRegistrationComponent
   */
  getPatientData(patient) {
    this.PatientData = patient;
    if (patient.id == "ExtendedPatient/Patient Not Registered") {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.pleaseresetencounter"),
        this.langService.get("CommonAppoinment.validation"),
        {
          duration: 1000,
        }
      );
    } else {
      if (
        patient.ctos ||
        patient.blocked ||
        patient.deceased ||
        patient.merged
      ) {
        const dialogRef = this.dialog.open(InfoDialogComponent, {
          width: "30%",
          data: {
            display: "Info",
            info: patient.deceased
              ? this.langService.get("EncounterPage.unabletoProceedDecease")
              : patient.merged
              ? this.langService.get("EncounterPage.unabletoProceedMerge")
              : this.langService.get("EncounterPage.selectPatientBlock"),
          },
        });
        this.subscriptionList.add(
          dialogRef.afterClosed().subscribe((result) => {
            if (
              result != undefined &&
              result.confirm &&
              result.confirm == "yes"
            ) {
              if(!patient.blocked)
              {
              this.selectedPatient = patient;

              this.religionValue = this.selectedPatient
                ? this.selectedPatient.religion
                : "";

              this.raceValue = this.selectedPatient
                ? this.selectedPatient.race
                : "";
              this.genderValue = this.selectedPatient
                ? this.selectedPatient.gender
                : "";
              // this.primaryIdSearch = this.selectedPatient;
              // this.identifierTypeValue = this.selectedPatient
              //   ? this.selectedPatient.identifierType
              //   : "";
              this.countryCodeValue =
                this.selectedPatient && this.selectedPatient.countryCode
                  ? this.selectedPatient.countryCode
                  : "";
              this.titleValue = this.selectedPatient
                ? this.selectedPatient.title
                : "";
                this.selectedPrimaryId = this.selectedPatient
                ? this.selectedPatient.idNo
                : "";
              this.patientCategory = this.selectedPatient
                ? this.selectedPatient.patientCategory
                : "";
              this.countryValue = this.selectedPatient
                ? this.selectedPatient.extendedAddress
                  ? this.selectedPatient.extendedAddress.country
                  : null
                : null;

              var dobFormatted = "";
              var patientAge = undefined;
              if (patient.dateOfBirth) {
                dobFormatted = this.datepipe.transform(
                  patient.dateOfBirth,
                  "dd/MM/yyyy"
                );
                let dobDate = new Date(patient.dateOfBirth);
                patientAge = this.utilsService.getAge(dobDate);
                patientAge =
                  patientAge > 1
                    ? "(" + patientAge + " Years)"
                    : "(" + patientAge + " Year)";
              }
              this.patientInfoDisplay =
                patient.mrn +
                " / " +
                patient.fullName +
                " / " +
                (patient.gender ? patient.gender : "Unknown") +
                " / " +
                (patient.dateOfBirth ? dobFormatted + " " + patientAge : "");
              
              let photo=  patient.photo ? patient.photo : "";
              if( photo && photo.includes("doc-service")) photo=this.imageUrl+ photo;

              this.patientImage = photo;
              this.formRegistration.enable();
              this.patientId = patient.id;

              if (patient.patientNotes)
                this.formEncounter.controls.reportRemarks.setValue(
                  patient.patientNotes
                );
              
              if (patient.identifierType === "Passport") {
                this.formRegistration.controls.expiryDate.enable();
                this.isexpiryDate = true;
              } else {
                this.formRegistration.controls.expiryDate.setValue("");
                this.formRegistration.controls.expiryDate.disable();
                this.isexpiryDate = false;
              }
              
              let primaryIdValue = this.primaryIdFilteredList.find(e=>e.desc==patient.identifierType);
              this.isEncounterAllow=primaryIdValue?true:false;
              this.mandatoryField.issuingCountry = primaryIdValue ? primaryIdValue.isCountryRequired : false;
              this.formRegistration.patchValue({
                id: patient.id,
                active:patient.active,
                mrn: patient.mrn,
                primaryNumber: patient.idNo,
                primaryId: (primaryIdValue && primaryIdValue.code) ? primaryIdValue.code : "",
                expiryDate: patient.expiryDate
                  ? new Date(patient.expiryDate)
                  : "",
                issuedCountryPrimary: patient.idIssueCountry
                  ? patient.idIssueCountry
                  : "",
                secondaryId: patient.secondaryIdType
                  ? patient.secondaryIdType
                  : "",
                secondaryNumber: patient.secondaryNo,
                issuedCountrySecondary: "",
                firstName: patient.fullName,
                dateOfBirth: patient.dateOfBirth
                  ? new Date(patient.dateOfBirth)
                  : "",
                birthTime: patient.birthTime ? patient.birthTime : "",
                realdob: patient.real ? patient.real : false,
                birthplace: patient.birthlocation ? patient.birthlocation : "",
                tinNo:patient.tinNo ? patient.tinNo : "",
                motherName: patient.motherName ? patient.motherName : "",
                patientNotes: patient.patientNotes ? patient.patientNotes : "",
                addressPrimary: patient.extendedAddress
                  ? patient.extendedAddress.addressLine
                  : "",

                cityPrimary:
                  patient.extendedAddress && patient.extendedAddress.city
                    ? patient.extendedAddress.city
                    : "",
                subDistrictPrimary:
                  patient.extendedAddress && patient.extendedAddress.area
                    ? patient.extendedAddress.area
                    : "",
                pincodePrimary: patient.extendedAddress
                  ? patient.extendedAddress.postCode
                  : "",
                emailId: patient.emailId,
                homeContact: patient.homeContactNo,
                mobileNumber: patient.mobileNumber,
                addressSecondary: patient.correspondingAddress
                  ? patient.correspondingAddress.addressLine
                  : "",

                pincodeSecondary: patient.correspondingAddress
                  ? patient.correspondingAddress.postCode
                  : "",
                workContact: patient.workContactNo,
                contactRelation: patient.relation ? patient.relation : "",
                isDeceased: patient.deceased ? patient.deceased : "",
                isBlocked: patient.blocked ? patient.blocked : "",
                isVip: patient.vip ? patient.vip : false,
                isNewborn: patient.newBorn ? patient.newBorn : false,
                workFax: patient.workFax ? patient.workFax : "",
                personalFax: patient.personalFax ? patient.personalFax : "",
                secondaryExpirydate: patient.seondaryExpiryDate
                  ? new Date(patient.seondaryExpiryDate)
                  : "",
                employmentStatus: patient.employmentStatus
                  ? patient.employmentStatus
                  : "",
                employerName: patient.employerName ? patient.employerName : "",
                employerNameOthers: patient.employerNameOthers
                  ? patient.employerNameOthers
                  : "",
                patientCategory: patient.patientCategory ? patient.patientCategory:"",
                employmentNo: patient.employmentNo ? patient.employmentNo : "",
                workingFrom: patient.workingFrom ? patient.workingFrom : "",
                employmentContactNo: patient.employmentContactNo
                  ? patient.employmentContactNo
                  : "",
                employmentAddress: patient.employmentAddress
                  ? patient.employmentAddress
                  : "",
                employmentCountry: patient.employmentCountry
                  ? patient.employmentCountry
                  : "",
                employmentState: patient.employmentState
                  ? patient.employmentState
                  : "",
                employmentCity: patient.employmentCity
                  ? patient.employmentCity
                  : "",
                employmentPostCode: patient.employmentPostCode
                  ? patient.employmentPostCode
                  : "",
              });

              if(primaryIdValue.isMyKad) this.primaryNumberOnKeyUp(patient.idNo);

              if (patient.healthTouristDetailsDTO) {
                this.healthTouristList = [];
                patient.healthTouristDetailsDTO.forEach((ele) => {
                  let startDateValue = this.epochToDateConverter(ele.startDate);
                  let endDateValue = this.epochToDateConverter(ele.endDate);
                  let currentDateValue = this.epochToDateConverter(
                    ele.currentDate
                  );

                  let obj = {
                    healthTouristId: ele.healthTouristId
                      ? ele.healthTouristId
                      : "",
                    code: ele.healthTouristCode ? ele.healthTouristCode : "",
                    identificationType: {
                      desc: ele.agentIdentificationType
                        ? ele.agentIdentificationType
                        : "",
                    },
                    identificationNumber: ele.agentIdentificationNo
                      ? ele.agentIdentificationNo
                      : "",
                    agentName: ele.agentName ? ele.agentName : "",
                    startDate: startDateValue,
                    endDate: endDateValue,
                    contactNo: ele.contactNo ? Number(ele.contactNo) : "",
                    emailId: ele.emailId ? ele.emailId : "",
                    currentDate: currentDateValue,
                  };
                  this.healthTouristList.push(obj);
                });
                this.healthTouristSource = new MatTableDataSource<any>(
                  this.healthTouristList
                );
              } else {
                this.healthTouristSource = new MatTableDataSource<any>();
              }
              if(this.raceValue)
              this.selectNationality(this.raceValue);
              this.countrySecondaryValue = patient.correspondingAddress.country;
              this.getStatePrimaryList(
                patient.correspondingAddress && patient.extendedAddress.country
                  ? patient.extendedAddress.country
                  : "",
                true
              );
              this.getStateSecondaryList(
                patient.correspondingAddress &&
                  patient.correspondingAddress.country
                  ? patient.correspondingAddress.country
                  : "",
                true
              );

              (this.countryEmploymentValue = patient.employmentCountry
                ? patient.employmentCountry
                : null),
                this.getStateEmploymentList(
                  patient.employmentCountry ? patient.employmentCountry : "",
                  true
                );

              if (
                patient.extendedAddress.addressLine ==
                  patient.correspondingAddress.addressLine &&
                patient.extendedAddress.postCode ==
                  patient.correspondingAddress.postCode
              ) {
                this.isBothAddressSame = true;
              }
              this.formRegistration.disable();
              this.formRegistration.get('isDeceased').enable();
              this.isEncounterAllow=true;
            }
            }
          })
        );
        this.getNOKList();
        setTimeout(() => {
          this.getDocuments(patient.mrn);
        }, 2000);
      } else {
        this.getOustandAmount(patient.mrn);
        this.getPreviousPayerList(patient.mrn);
        this.selectedPatient = patient;
        this.genderValue = this.selectedPatient
          ? this.selectedPatient.gender
          : "";
        this.raceValue = this.selectedPatient
          ? this.selectedPatient.race
          : "";
        // this.primaryIdSearch = this.selectedPatient;
        // this.identifierTypeValue = this.selectedPatient
        //   ? this.selectedPatient.identifierType
        //   : "";
        this.countryCodeValue =
          this.selectedPatient && this.selectedPatient.countryCode
            ? this.selectedPatient.countryCode
            : "";
        this.titleValue = this.selectedPatient
          ? this.selectedPatient.title
          : "";
          this.selectedPrimaryId = this.selectedPatient
          ? this.selectedPatient.idNo
          : "";
        this.countryValue = this.selectedPatient
          ? this.selectedPatient.extendedAddress
            ? this.selectedPatient.extendedAddress.country
            : null
          : null;
            if(this.isPatientType == "Staff"){
              this.selectedPatient.patientCategory = this.MasterStaffCode;
             }else if(this.isPatientType == "Student"){
          this.selectedPatient.patientCategory = this.MasterStudentCode;
         }else{
          this.patientCategory =patient.patientCategory ? patient.patientCategory : "";
         }
        var dobFormatted = "";
        var patientAge = undefined;
        if (patient.dateOfBirth) {
          dobFormatted = this.datepipe.transform(
            patient.dateOfBirth,
            "dd/MM/yyyy"
          );
          let dobDate = new Date(patient.dateOfBirth);
          patientAge = this.utilsService.getAge(dobDate);
          patientAge =
            patientAge > 1
              ? "(" + patientAge + " Years)"
              : "(" + patientAge + " Year)";
        }
        this.patientInfoDisplay =
          patient.mrn +
          " / " +
          patient.fullName +
          " / " +
          (patient.gender ? patient.gender : "Unknown") +
          " / " +
          (patient.dateOfBirth ? dobFormatted + " " + patientAge : "");
         
        let photo=  patient.photo ? patient.photo : "";
        if( photo && photo.includes("doc-service"))
          photo=this.imageUrl+ photo;
        this.patientImage = photo;
        this.formRegistration.enable();
        this.patientId = patient.id;
        if (patient.patientNotes)
          this.formEncounter.controls.reportRemarks.setValue(
            patient.patientNotes
          );
        //  if (patient.referralRemark)
        //   this.formEncounter.controls.referralRemarks.setValue(
        //     patient.referralRemarks
        //  );

        //  if (patient.remark)
        //   this.formEncounter.controls.remarks.setValue(
        //     patient.remarks
        //  );
        if (patient.identifierType === "Passport") {
          this.formRegistration.controls.expiryDate.enable();
          this.isexpiryDate = true;
        } else {
          this.formRegistration.controls.expiryDate.setValue("");
          this.formRegistration.controls.expiryDate.disable();
          this.isexpiryDate = false;
        }
        this.getNOKList();

        let primaryIdValue = this.primaryIdFilteredList.find(e=>e.desc==patient.identifierType);
        this.isEncounterAllow=primaryIdValue?true:false;
        this.mandatoryField.issuingCountry = primaryIdValue ? primaryIdValue.isCountryRequired : false; 
        this.formRegistration.patchValue({
          id: patient.id,
          active:patient.active,
          mrn: patient.mrn,
          primaryNumber: patient.idNo,
          primaryId: (primaryIdValue && primaryIdValue.code) ? primaryIdValue.code : "",
          expiryDate: patient.expiryDate ? new Date(patient.expiryDate) : "",
          issuedCountryPrimary: patient.idIssueCountry
            ? patient.idIssueCountry
            : "",
          secondaryId: patient.secondaryIdType ? patient.secondaryIdType : "",
          secondaryNumber: patient.secondaryNo,
          issuedCountrySecondary: "",
          firstName: patient.fullName,
          dateOfBirth: patient.dateOfBirth ? new Date(patient.dateOfBirth) : "",
          birthTime: patient.birthTime ? patient.birthTime : "",
          realdob: patient.real ? patient.real : false,
          birthplace: patient.birthlocation ? patient.birthlocation : "",
          tinNo:patient.tinNo ? patient.tinNo : "",
          motherName: patient.motherName ? patient.motherName : "",
          patientNotes: patient.patientNotes ? patient.patientNotes : "",
          addressPrimary: patient.extendedAddress
            ? patient.extendedAddress.addressLine
            : "",

          cityPrimary:
            patient.extendedAddress && patient.extendedAddress.city
              ? patient.extendedAddress.city
              : "",
          subDistrictPrimary:
            patient.extendedAddress && patient.extendedAddress.area
              ? patient.extendedAddress.area
              : "",
          pincodePrimary: patient.extendedAddress
            ? patient.extendedAddress.postCode
            : "",
          emailId: patient.emailId,
          homeContact: patient.homeContactNo,
          mobileNumber: patient.mobileNumber,
          addressSecondary: patient.correspondingAddress
            ? patient.correspondingAddress.addressLine
            : "",

          pincodeSecondary: patient.correspondingAddress
            ? patient.correspondingAddress.postCode
            : "",
          workContact: patient.workContactNo,
          contactRelation: patient.relation ? patient.relation : "",
          isDeceased: patient.deceased ? patient.deceased : "",
          isBlocked: patient.blocked ? patient.blocked : "",
          isVip: patient.vip ? patient.vip : false,
          isNewborn: patient.newBorn ? patient.newBorn : false,
          workFax: patient.workFax ? patient.workFax : "",
          personalFax: patient.personalFax ? patient.personalFax : "",
          secondaryExpirydate: patient.seondaryExpiryDate
            ? new Date(patient.seondaryExpiryDate)
            : "",
          employmentStatus: patient.employmentStatus
            ? patient.employmentStatus
            : "",
          employerName: patient.employerName ? patient.employerName : "",
          employerNameOthers: patient.employerNameOthers
            ? patient.employerNameOthers
            : "",
          employmentNo: patient.employmentNo ? patient.employmentNo : "",
          workingFrom: patient.workingFrom ? patient.workingFrom : "",
          employmentContactNo: patient.employmentContactNo
            ? patient.employmentContactNo
            : "",
          employmentAddress: patient.employmentAddress
            ? patient.employmentAddress
            : "",
          employmentCountry: patient.employmentCountry
            ? patient.employmentCountry
            : "",
          employmentState: patient.employmentState
            ? patient.employmentState
            : "",
          employmentCity: patient.employmentCity ? patient.employmentCity : "",
          employmentPostCode: patient.employmentPostCode
            ? patient.employmentPostCode
            : "",
        });

        if(primaryIdValue.isMyKad) this.primaryNumberOnKeyUp(patient.idNo);

        if (patient.healthTouristDetailsDTO) {
          this.healthTouristList = [];
          patient.healthTouristDetailsDTO.forEach((ele) => {
            let startDateValue = this.epochToDateConverter(ele.startDate);
            let endDateValue = this.epochToDateConverter(ele.endDate);
            let currentDateValue = this.epochToDateConverter(ele.currentDate);

            let obj = {
              healthTouristId: ele.healthTouristId ? ele.healthTouristId : "",
              code: ele.healthTouristCode ? ele.healthTouristCode : "",
              identificationType: {
                desc: ele.agentIdentificationType
                  ? ele.agentIdentificationType
                  : "",
              },
              identificationNumber: ele.agentIdentificationNo
                ? ele.agentIdentificationNo
                : "",
              agentName: ele.agentName ? ele.agentName : "",
              startDate: startDateValue,
              endDate: endDateValue,
              contactNo: ele.contactNo ? Number(ele.contactNo) : "",
              emailId: ele.emailId ? ele.emailId : "",
              currentDate: currentDateValue,
            };
            this.healthTouristList.push(obj);
          });
          this.healthTouristSource = new MatTableDataSource<any>(
            this.healthTouristList
          );
        } else {
          this.healthTouristSource = new MatTableDataSource<any>();
        }
        if(patient.employeeDetailsDTO){
          var details=patient.employeeDetailsDTO;
          this.formRegistration.patchValue({
            employerName:details.employer[0]?.code,
            employerNameOthers:details.other,
            employmentNo:details.employmentNo,
            workingFrom: details.workingFrom?new Date(details.workingFrom * 1000):0,
            employmentContactNo: details.contactNo,
            employmentAddress:details.employeeAdress?details.employeeAdress.addressLine: "",
            employmentState:details.employeeAdress?details.employeeAdress.state.code: "",
            employmentCity: details.employeeAdress?details.employeeAdress.city.code: "",
            employmentPostCode:details.employeeAdress?details.employeeAdress.postCode: "",
          });         
        }
        if(this.raceValue)
        this.selectNationality(this.raceValue);
        this.countrySecondaryValue = patient.correspondingAddress.country;
        this.getStatePrimaryList(
          patient.correspondingAddress && patient.extendedAddress.country
            ? patient.extendedAddress.country
            : "",
          true
        );
        this.getStateSecondaryList(
          patient.correspondingAddress && patient.correspondingAddress.country
            ? patient.correspondingAddress.country
            : "",
          true
        );

        (this.countryEmploymentValue = patient.employmentCountry!==undefined
          ? patient.employmentCountry
          : (details?details.employeeAdress.country.code:null)),
          this.getStateEmploymentList(
            patient.employmentCountry ? patient.employmentCountry : (details?details.employeeAdress.country.code:''),
            true
          );

        if (
          patient.extendedAddress.addressLine ==
            patient.correspondingAddress.addressLine &&
          patient.extendedAddress.postCode ==
            patient.correspondingAddress.postCode
        ) {
          this.isBothAddressSame = true;
        }
        setTimeout(() => {
          this.getDocuments(patient.mrn);
        }, 2000);
      }
    }
    this.isVip = patient.vip;
  }

  /**
   * To edit on patient click
   */
  editPatientClicked() {
    if (this.selectedPatient) {
      const formRegistration = this.formRegistration.controls;
      const IdTypeCode = formRegistration.IdTypeCode
        ? formRegistration.IdTypeCode.value
        : "";

      if (IdTypeCode) {
        this.isexpiryDate = IdTypeCode.isExpireReq;

        if (!this.isexpiryDate) {
          formRegistration.expiryDate.setValue("");
          formRegistration.expiryDate.disable();
          this.isexpiryDate = true;
        } else {
          formRegistration.expiryDate.enable();
          this.isexpiryDate = false;
        }
      }
      //this.getpatientbyId(this.selectedPatient.mrn);
      this.initSearchFilter();
    }
  }

  /**
   *validate kin form
   *
   * @return {*}  {boolean}
   * @memberof PatientRegistrationComponent
   */
  validateNextOfKinForm(): boolean {
    for (var i in this.formNextOfKin.controls) {
      this.formNextOfKin.controls[i].markAsTouched();
    }
    return this.formNextOfKin.valid;
  }

  /**
   *add next kin
   *
   * @memberof PatientRegistrationComponent
   */
  addNextOfKin() {
    if (this.validateNextOfKinForm()) {
      let nextOfKin: PatientNextOfKin =
        this.patientRegService.getNextOfKinFormValues(this, this.formNextOfKin);
        nextOfKin.checked = true;
      if (nextOfKin) {
        let list = this.dataSourceNextOfKin.data;
        list.push(nextOfKin);
        this.dataSourceNextOfKin = new MatTableDataSource<any>(list);
        this.formNextOfKin.reset();
        this.getNokforSave(nextOfKin,nextOfKin);
      }
    }
    return this.formNextOfKin.controls.name.valid;
  }

  /**
   *add payer
   *
   * @memberof PatientRegistrationComponent
   */
  addPayer() {
    if (this.dataSourcePayers.data.length > 0) {
      let list = this.dataSourcePayers.data;
      let patientEncounterPayer = new PatientEncounterPayer();
      patientEncounterPayer.priority = null;
      if(!this.isFirstPayerPriority){
        list.push(new PatientEncounterPayer());
      }else{
        list.unshift(new PatientEncounterPayer());
      }
      this.dataSourcePayers = new MatTableDataSource<PatientEncounterPayer>(
        list
      );
    } else {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.pleaseselectpatient"),
        this.langService.get("CommonAppoinment.validation"),
        {
          duration: 1000,
        }
      );
    }
  }

  /**
   *fill dropdownlist
   *
   * @memberof PatientRegistrationComponent
   */
  fillDropDownLists() {
    this.getHealthPackgeList();
    this.getPrimaryIdList();
    this.getRelationList();
    this.getCountryList();
     //this.getPayerList();
    this.getPayerTypeList();
    this.getEmployerList();
    this.getDocTypeyList();
    // this.getContractListData();
    this.getTariffmaster();
    this.getAssociatedCompanyList();
    this.getGLTypeList();
    this.getReportTypes();
    this.getPrefixList();
    this.getDocumentTypeList();
    this.getDoctorList(null,true);
    this.getdepartment();
    this.getDocTypeListForRegistration();
    this.getDocTypeListForEncounter();
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getDocumentTypeList() {
    this.appBaseService.setResourceURL(OPD_API_URL.DOC_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.DOCUMENTTYPEMASTER)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.documentTypelist = res;
      });
  }

  /**
   *
   *
   * @param {*} referralTypeCode
   * @memberof PatientRegistrationComponent
   */
  getReferralList(referralTypeCode) {
    if (referralTypeCode) {
      this.referralTypeCode = {
        field: OPD_API_URL.M_REFFERALTYPE,
        value: referralTypeCode.id,
      };
    }
  }

  /**
   *It is the source of doctor data
   *
   * @param {*} departmentCode
   * @memberof PatientRegistrationComponent
   */
  getDoctorList(departmentCode, isappointment) {
    if (!isappointment) {
      this.formEncounter.patchValue({ appointmentSlots: "" });
      this.appointmentData = null;
    }
    //if (departmentCode) {
      if (this.mrdDepartment == departmentCode?.value?.code)  {
        if(this.mrdDepartment)
        this.medicalReport = true;
      } else {
        this.medicalReport = false;
        this.formEncounter.controls.reportResonMrd.setErrors(null);
        this.formEncounter.controls.reportTypeMrd.setErrors(null);
      }
      let payload = {
        limit: 0,
        unitCode: this.unitCode,
        departmentCode: isappointment ? departmentCode : departmentCode?.value?.code,
        typeCodeList: ["DR"],
      };
      this.masterApiService
        .postTypeMaster(OPD_API_URL.EMPLOYEE_LIST, payload)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((data: any) => {
          let list = [];
          let letmultilangdoclist = [];
          if (
            data &&
            data.payload &&
            data.payload.employeeResponseList &&
            data.payload.employeeResponseList.length > 0
          ) {
            data.payload.employeeResponseList.forEach((element) => {
              list.push({
                id: element.id,
                code: element.code,
                desc: element.desc,
              });
              letmultilangdoclist.push(element);
              if (this.previousEncounterList.length > 0)
                this.formEncounter.controls.doctor.setValue(this.doctorValue);
            });
          }
          this.multiLangDoctorList = letmultilangdoclist;
          this.doctorFilteredList = list;
          this.docList = list;
          if (isappointment) {
            this.formEncounter.patchValue({
              doctor: this.doctorValueappointment,
            });
          }
        });
    //}
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getReportTypes() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_REASON_MASTER_QUERY_NEW+"MEDR")
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
       this.reportReasonList = res;
        this.reportReasonFilterdList = res;
      });
  }

  /**
   *
   *
   * @param {*} event
   * @memberof PatientRegistrationComponent
   */
  getSpeciality(event){
    if(event.value){
      this.masterApiService
        .postTypeMaster(OPD_API_URL.GET_SPECIALITY_BY_DOCTOR+event.value, '')
        .pipe(takeUntil(this._onDestroy))
        .subscribe((data: any) => {
          if(data.payload){
            this.departmentFilterList =data.payload;
            this.departmentValue= data.payload[0];
          }
        })
    }
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getdepartment() {
    this.masterApiService
      .getTypeMaster(this.masterAPI.DEPT_GET_DEPT_BY_UNIT)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.departmentList = data;
        this.departmentFilterList=this.departmentList
      });
  }

  /**
   *get health package
   *
   * @memberof DialogAddAppointmentPatient
   */
  getHealthPackgeList() {
    let list = [];
    this.subscriptionList.add(
      this.masterApiService
        .getTypeMaster(OPD_API_URL.M_PACKAGE)
        .subscribe((result: any) => {
          result.forEach((element) => {
            list.push({
              id: element.id,
              code: element.code,
              desc: element.desc,
              l1: element.l1,
              l2: element.l2,
            });
          });
          this.healthPackageList = list;
          this.checkRequestHasAppointment();
        })
    );
  }

  /**
   *
   *
   * @param {*} o1
   * @param {*} o2
   * @return {*} 
   * @memberof PatientRegistrationComponent
   */
  compareFn(o1: any, o2: any) {
    if (o1.code == o2.code) return true;
  }

  /**
   *
   *
   * @param {*} companyValue
   * @memberof DialogAddAppointmentPatient
   */
  pakageChecked(packageCode) {
    let packageValue = [];
    this.selectedOption = [];
    for (let i = 0; i < this.healthPackageList.length; i++) {
      let value = this.healthPackageList.find((s) => s.code == packageCode[i]);
      packageValue.push(value);
    }
    for (let i = 0; i < this.healthPackageList.length; i++) {
      if (packageValue.indexOf(this.healthPackageList[i]) != -1)
        this.selectedOption.push(this.healthPackageList[i].code);
    }
  }

  /**
   *It is the source of primaryId data
   *
   * @memberof PatientRegistrationComponent
   */
  getPrimaryIdList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_IDENTIFICATION)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.primaryIdList = data.filter((i) => i.isPrimaryId);
        this.primaryIdFilteredList = this.primaryIdList;
        this.secondaryIdList = data.filter((i) => !i.isPrimaryId);
        this.secondaryIdFilteredList = this.secondaryIdList;
      });
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getRelationList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_RELATION)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.relationList = data;
      });
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getPrefixList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_PREFIX)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.newPrefixList = data;
      });
  }

  /**
   *It is the source of country data.
   *
   * @memberof PatientRegistrationComponent
   */
  getCountryList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_COUNTRY)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.countryPrimaryList = data;
        this.countrySecondaryList = data;
        this.countryEmploymentList = data;
      });
  }

  /**
   * Loading Employer Data
   *
   * @memberof PatientRegistrationComponent
   */
  getEmployerList() {
   
    this.employerList = [];
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_EMPLOYER)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        if (data) {
          this.employerList = data;
        }
      });
  }

  /**
   *Passing countryCode to get the stateList
   *
   * @param {*} countryCode
   * @memberof PatientRegistrationComponent
   */
  getStatePrimaryList(countryCode, isCode) {
    if (countryCode) {
      let result: any = "";
      if (isCode)
        result = this.countryPrimaryList.find((i) => i.code == countryCode);
      else
        result = this.countryPrimaryList.find(
          (i) => i.code == countryCode.code
        );
      this.CountryCode = {
        field: OPD_API_URL.M_COUNTRYM,
        value: result.id,
      };
      this.masterApiService
        .getTypeMaster(
          OPD_API_URL.M_STATE + OPD_API_URL.M_COUNTRY_VALUE + result.id
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.statePrimaryList = res;
          if (this.selectedPatient) {
            const stateres = this.statePrimaryList.find(
              (i) =>
                i.code ==
                (this.selectedPatient.extendedAddress
                  ? this.selectedPatient.extendedAddress.state
                  : "")
            );
            this.stateValue = stateres ? stateres.code : null;
            if (this.isAddeassByPostCode == true) {
              this.getCityPrimaryList(this.getStateValue);
            } else {
              this.getCityPrimaryList(stateres);
            }
          } else {
            const stateres = this.statePrimaryList.find(
              (i) => i.code == this.stateValue
            );
            this.stateValue = stateres ? stateres.code : null;
            const result = this.statePrimaryList.find(
              (i) => i.code == this.stateValue
            );

            if (result && result.id) {
              this.StateCode = {
                field: OPD_API_URL.M_STATEM,
                value: result.id,
              };
              if (this.isAddeassByPostCode == true) {
                this.getCityPrimaryList(this.getStateValue);
              } else {
                this.getCityPrimaryList(stateres);
              }
            }
          }
        });
    }
  }

  /**
   *Passing stateCode to get the cityList
   *
   * @param {*} stateCode
   * @memberof PatientRegistrationComponent
   */
  getCityPrimaryList(stateCode) {
    if (stateCode) {
      const result = this.statePrimaryList.find(
        (i) => i.code == stateCode.code
      );
      this.StateCode = {
        field: OPD_API_URL.M_STATEM,
        value: result.id,
      };

      this.masterApiService
        .getTypeMaster(
          OPD_API_URL.M_CITY + OPD_API_URL.M_STATE_VALUE + result.id
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.cityPrimaryList = res;
          if (this.selectedPatient) {
            const cityres = this.cityPrimaryList.find(
              (i) =>
                i.code ==
                (this.selectedPatient.extendedAddress
                  ? this.selectedPatient.extendedAddress.city
                  : "")
            );
            this.cityValue = cityres ? cityres.code : null;
            if (this.isAddeassByPostCode == true) {
              this.cityValue = this.getCityValue.code;
              this.getSubDistrictPrimaryList(this.getCityValue);
            } else {
              this.getSubDistrictPrimaryList(cityres);
            }
          } else {
            const cityres = this.cityPrimaryList.find(
              (i) => i.code == this.cityValue
            );
            this.cityValue = cityres ? cityres.code : null;
            if (this.isAddeassByPostCode == true) {
              this.cityValue = this.getCityValue.code;
              this.getSubDistrictPrimaryList(this.getCityValue);
            } else {
              this.getSubDistrictPrimaryList(cityres);
            }
          }
        });
    }
  }

  /**
   *Passing cityCode to get the subDistrictList
   *
   * @param {*} cityCode
   * @memberof PatientRegistrationComponent
   */
  getSubDistrictPrimaryList(cityCode) {
    if (cityCode) {
      const result = this.cityPrimaryList.find((i) => i.code == cityCode.code);

      if(result) {
        this.CityCode = {
          field: OPD_API_URL.M_CITY_VALUE,
          value: result.id,
        };
      }

      this.masterApiService
        .getTypeMaster(
          OPD_API_URL.M_AREA + OPD_API_URL.M_AREA_VALUE + result.id
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.subDistrictPrimaryList = res;
          if (this.selectedPatient) {
            const distres = this.subDistrictPrimaryList.find(
              (i) =>
                i.code ==
                (this.selectedPatient.extendedAddress
                  ? this.selectedPatient.extendedAddress.area
                  : "")
            );
            this.districtValue = distres ? distres.code : null;
            if (this.isAddeassByPostCode)
              this.districtValue = this.getDistrictValue.code;
          } else {
            const districres = this.subDistrictPrimaryList.find(
              (i) => i.code == this.districtValue
            );
            this.districtValue = districres ? districres.code : null;
            if (this.isAddeassByPostCode)
              this.districtValue = this.getDistrictValue.code;
          }
        });
    }
  }

  /**
   *Passing countryCode to get the stateSecondaryList
   *
   * @param {*} countryCode
   * @memberof PatientRegistrationComponent
   */
  getStateSecondaryList(countryCode, isCode) {
    if (countryCode) {
      if (this.isSameAddress) {
        this.stateSecondaryValue =
          this.formRegistration.controls.statePrimary.value.code;
      } else if (this.selectedPatient) {
        this.stateSecondaryValue = this.selectedPatient.correspondingAddress
          ? this.selectedPatient.correspondingAddress.state
          : "";
      }
      let result: any = "";
      if (isCode)
        result = this.countrySecondaryList.find((i) => i.code == countryCode);
      else
        result = this.countrySecondaryList.find(
          (i) => i.code == countryCode.code
        );
      this.secondaryCountryCode = {
        field: OPD_API_URL.M_COUNTRYM,
        value: result.id,
      };
      this.masterApiService
        .getTypeMaster(
          OPD_API_URL.M_STATE + OPD_API_URL.M_COUNTRY_VALUE + result.id
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.stateSecondaryList = res;
          const stateres = this.stateSecondaryList.find(
            (i) => i.code == this.stateSecondaryValue
          );

          this.getCitySecondaryList(stateres);
        });
    }
  }

  /**
   *Passing stateCode to get the citySeocndaryList
   *
   * @param {*} cityCode
   * @memberof PatientRegistrationComponent
   */
  getCitySecondaryList(stateCode) {
    if (stateCode) {
      if (this.isSameAddress) {
        this.citySecondaryValue =
          this.formRegistration.controls.cityPrimary.value.code;
      } else if (this.selectedPatient) {
        this.citySecondaryValue = this.selectedPatient.correspondingAddress
          ? this.selectedPatient.correspondingAddress.city
          : "";
      }
      const result = this.stateSecondaryList.find(
        (i) => i.code == stateCode.code
      );
      this.secondaryStateCode = {
        field: OPD_API_URL.M_STATEM,
        value: result.id,
      };
      this.masterApiService
        .getTypeMaster(
          OPD_API_URL.M_CITY + OPD_API_URL.M_STATE_VALUE + result.id
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.citySecondaryList = res;
          const cityres = this.citySecondaryList.find(
            (i) => i.code == this.citySecondaryValue
          );

          this.getSubDistrictSecondaryList(cityres);
        });
    }
  }

  /**
   *Passing cityCode to get the subDistrictSecondaryList
   *
   * @param {*} cityCode
   * @memberof PatientRegistrationComponent
   */
  getSubDistrictSecondaryList(cityCode) {
    if (cityCode) {
      const result = this.citySecondaryList.find(
        (i) => i.code == cityCode.code
      );
      this.secondaryDistrictCode = {
        field: OPD_API_URL.M_CITY_VALUE,
        value: result.id,
      };
      this.masterApiService
        .getTypeMaster(
          OPD_API_URL.M_AREA + OPD_API_URL.M_AREA_VALUE + result.id
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.subDistrictSecondaryList = res;
        });
    }
    if (this.isSameAddress)
      this.districtSeoncdaryValue = this.formRegistration.controls
        .subDistrictPrimary.value
        ? this.formRegistration.controls.subDistrictPrimary.value.code
        : "";
    else if (this.selectedPatient)
      this.districtSeoncdaryValue = this.selectedPatient.correspondingAddress
        ? this.selectedPatient.correspondingAddress.area
        : "";
  }

  /**
   *Passing countryCode to get the stateList
   *
   * @param {*} countryCode
   * @memberof PatientRegistrationComponent
   */
  getStateEmploymentList(countryCode, isCode) {
    if (countryCode) {
      let result: any = "";
      if (isCode)
        result = this.countryEmploymentList.find((i) => i.code == countryCode);
      else
        result = this.countryEmploymentList.find(
          (i) => i.code == countryCode.code
        );
      this.employmentCountryCode = {
        field: OPD_API_URL.M_COUNTRYM,
        value: result.id,
      };
      this.masterApiService
        .getTypeMaster(
          OPD_API_URL.M_STATE + OPD_API_URL.M_COUNTRY_VALUE + result.id
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.stateEmploymentList = res;
          if (this.selectedPatient) {
            const stateres = this.stateEmploymentList.find(
              (i) =>
                i.code ==
                (this.formRegistration.controls.employmentState.value
                  ? this.formRegistration.controls.employmentState.value
                  : "")
            );
            this.stateEmploymentValue = stateres ? stateres.code : null;
            this.getCityEmploymentList(stateres);
          } else {
            const stateres = this.stateEmploymentList.find(
              (i) => i.code == this.stateEmploymentValue
            );
            this.stateEmploymentValue = stateres ? stateres.code : null;
            const result = this.stateEmploymentList.find(
              (i) => i.code == this.stateEmploymentValue
            );
            this.employmentStateCode = {
              field: OPD_API_URL.M_STATEM,
              value: result.id,
            };
            this.getCityEmploymentList(stateres);
          }
        });
    }
  }

  /**
   *Passing stateCode to get the cityList
   *
   * @param {*} stateCode
   * @memberof PatientRegistrationComponent
   */
  getCityEmploymentList(stateCode) {
    if (stateCode) {
      const result = this.stateEmploymentList.find(
        (i) => i.code == (stateCode.code ? stateCode.code : stateCode)
      );
      this.employmentStateCode = {
        field: OPD_API_URL.M_STATEM,
        value: result.id,
      };

      this.masterApiService
        .getTypeMaster(
          OPD_API_URL.M_CITY + OPD_API_URL.M_STATE_VALUE + result.id
        )
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res) => {
          this.cityEmploymentList = res;
          if (this.selectedPatient) {
            const cityres = this.cityEmploymentList.find(
              (i) =>
                i.code ==
                (this.formRegistration.controls.employmentCity.value
                  ? this.formRegistration.controls.employmentCity.value
                  : "")
            );
            this.cityEmploymentValue = cityres ? cityres.code : null;
          } else {
            const cityres = this.cityEmploymentList.find(
              (i) => i.code == this.cityEmploymentValue
            );
            this.cityEmploymentValue = cityres ? cityres.code : null;
          }
        });
    }
  }

  /**
   *Passing CountryCode to get the kinStateList
   *
   * @param {*} countryCode
   * @memberof PatientRegistrationComponent
   */
  getNOKStateList(countryCode) {
    if (countryCode) {
      this.nokCountryCode = {
        field: OPD_API_URL.M_COUNTRYM,
        value: countryCode.id,
      };
    }
  }

  /**
   *Passing cityCode to get the kinCityList
   *
   * @param {*} cityCode
   * @memberof PatientRegistrationComponent
   */
  getNOKCityList(cityCode) {
    if (cityCode) {
      this.nokStateCode = {
        field: OPD_API_URL.M_STATEM,
        value: cityCode.id,
      };
    }
  }

  /**
   *It is the source of payer data.
   *
   * @memberof PatientRegistrationComponent
   */
  getPayerList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_COMPANY)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.payerList = data;
        // this.payerFilteredList = data;
          this.dataSourcePayers.data[0].payerList = []
          this.dataSourcePayers.data[0].payerList = data;
          this.dataSourcePayers.data[0].payerFilteredList = data;
          
      });
  }

  /**
   *It is the source of payerType data.
   *
   * @memberof PatientRegistrationComponent
   */
  getPayerTypeList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_COMPANYTYPE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.payerTypeList = data;
        this.payerTypeFilteredList = data;
        let res = this.payerTypeList.find((s) => s.isSelf == true);
        if(this.dataSourcePayers.data.length>0)
        this.dataSourcePayers.data[0].payerTypeCode = res.code;
      });
  }

  /**
   *It is the source of getDocTypeList data.
   *
   * @memberof PatientRegistrationComponent
   */
  getDocTypeyList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_DOC_TYPE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.docTypeList = data;
        this.docTypeFilteredList = data;
      });
  }

  /**
   *Passing payerCode and index to get the contractList
   *
   * @param {*} payerCode
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  getPayerListData(payerTypeCode, index) {
    let payerListByType = [];
    this.payerList.forEach((element) => {
      if (element.companyTypeMaster.code == payerTypeCode)
        payerListByType.push(element);
    });
    this.dataSourcePayers.data[index].payerList = payerListByType;
  }

  /**
   *
   *
   * @param {*} code
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  validatePayer(element, index) {
    let payerIndex = this.dataSourcePayers.data.findIndex((data, i) => {
      return data.payerCode == element.payerCode && i != index;
    });
    if (payerIndex >= 0) {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.Payerisalready"),
        this.langService.get("CommonAppoinment.validation"),
        {
          duration: 1000,
        }
      );
      this.dataSourcePayers.data[index].payerCode = "";
    } else this.getGlDetailsByPayer(this.selectedPatient.mrn, element, index);
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getContractListData() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_CONTRACT)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.contractList = data;
        this.contractCodeList = data;
        this.dataSourcePayers.data[0].contractList = [];
        this.dataSourcePayers.data[0].contractList = data;
        this.dataSourcePayers.data[0].contractCodeList = data;
      });
  }

  /**
   *It is the source of gradeList data.
   *
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  getGradeList(index) {
    let row = this.dataSourcePayers.data[index];
    let grades = row.contractList["configurationList"]
      ? row.contractList["configurationList"]
      : [];
    this.dataSourcePayers.data[index].gradeList = grades;
  }

  /**
   *It is get the tariffMaster Data
   *
   * @memberof PatientRegistrationComponent
   */
  getTariffmaster() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_TARIFF)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        this.tariffList = res;
        this.tariffFilteredList = res;
      });
  }

  /**
   *It is the source of tariffList data
   *
   * @param {string} patientCategoryCode
   * @memberof PatientRegistrationComponent
   */
  getTariffList(i?) {
    if (this.isPatientCategory) {
      let payload = this.selectedPatient.patientCategory || null;
      this.masterApiService
        .getTypeMaster(OPD_API_URL.PATIENT_CATEGORY_MASTER + payload)
        .pipe(takeUntil(this._onDestroy))
        .subscribe((res: any) => {
          if(res.payload.patientCategoryDTOResponse && res.payload.patientCategoryDTOResponse.tariffResponseList){
          this.tariffList = res.payload.patientCategoryDTOResponse.tariffResponseList;
          this.tariffFilteredList=this.tariffList;
          }
          let defaultTariff = this.tariffList.find(i => i.isDefault === true);
          if (i === undefined) {
            if (this.dataSourcePayers.data.length > 0) {
              if (!this.dataSourcePayers.data[0].tariffCode) {
                this.dataSourcePayers.data[0].tariffCode = defaultTariff ? defaultTariff.code : null;
              }
            }
          } else {
            let contractTariff = this.tariffList.find(i => i.code === this.contractValue?.tariffMaster?.code);
            if (contractTariff) {
              this.dataSourcePayers.data[i].tariffCode = contractTariff.code;
            }
          }
        });
    } else {
      let contractTariff = this.tariffList.find(i => i.code === this.contractValue?.tariffMaster?.code);
      if (contractTariff) {
        this.dataSourcePayers.data[i].tariffCode = contractTariff.code;
      }
    }
  }

  /**
   *It is the source of getGLTypeList data.
   *
   * @memberof PatientRegistrationComponent
   */
  getGLTypeList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_GLTYPE)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.glTypeList = data;
      });
  }

  /**
   *It is the source of getAssociateList data.
   *
   * @memberof PatientRegistrationComponent
   */
  getAssociatedCompanyList() {
    this.masterApiService
      .getTypeMaster(OPD_API_URL.M_ASSOCIATECOMPANY)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((data: any) => {
        this.associatedCompanyList = data;
        this.associatedCompanyCodeList = data;
      });
  }

  /**
   *Upload Image
   *
   * @memberof PatientRegistrationComponent
   */
  uploadImageDialog(): void {
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      width: "400px",
      data: { name: "" },
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        try {
          if (result.res == "yes") {
            this.patientImage = result.picdata;
            this.patientImage;
          } else {
            this.patientImage = "";
          }
        } catch (e) {}
      })
    );
  }

  /**
   *
   *
   * @param {*} patientImage
   * @memberof PatientRegistrationComponent
   */
  uploadImageDialogForEdit(patientImage) {
    const dialogRef = this.dialog.open(CameraDialogComponent, {
      width: "400px",
      data: { name: patientImage },
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        try {
          if (result.res == "yes") {
            this.patientImage = result.picdata;
          } else {
            this.patientImage = "";
          }
        } catch (e) {}
      })
    );
  }

  /**
   *onchanges chekcing multiple conditions
   *
   * @memberof PatientRegistrationComponent
   */
  onChanges() {
    this.subscriptionList.add(
      this.formRegistration.controls.dateOfBirth.valueChanges.subscribe(
        (val) => {
          let newDate;
          if (val instanceof Date) {
            newDate = new Date(val);
          } else {
            if (val) newDate = new Date(val);
          }
          this.patientAge = this.utilsService.calculateCompleteAge(newDate);
        }
      )
    );
    // this.subscriptionList.add(
    //   this.formRegistration.controls.secondaryId.valueChanges.subscribe(
    //     (val) => {
    //       this.secondartyIdentificationOnChange(val);
    //     }
    //   )
    // );
    this.subscriptionList.add(
      this.formEncounter.controls.visitToDepartment.valueChanges.subscribe(
        (isVisitToDepartment) => {
          let doctorControl = this.formEncounter.controls.doctor;
          this.formEncounter.controls.doctor.setValue("");
          if (isVisitToDepartment) {
            doctorControl.clearValidators();
            doctorControl.disable();
            this.isDepartment = false;
          } else {
            doctorControl.enable();
            doctorControl.setValidators([Validators.required]);
            this.isDepartment = true;
          }
          doctorControl.updateValueAndValidity();
          if(this.isGetClinicElement){
            if(isVisitToDepartment){
              this.isClinicReqired=false;
            }else{
              this.isClinicReqired=true;
            }
          }
        }
      )
    );
    this.subscriptionList.add(
      this.doctorSearch.valueChanges.subscribe((value) => {
        this.doctorFilteredList = this.docList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.secondaryIdSearch.valueChanges.subscribe((value) => {
        this.secondaryIdFilteredList = this.secondaryIdList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.dipartmentSearch.valueChanges.subscribe((value) => {
        this.departmentFilterList = this.departmentList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.formRegistration.controls.secondaryExpirydate;

    this.subscriptionList.add(
      this.primaryIdSearch.valueChanges.subscribe((value) => {
        this.primaryIdFilteredList = this.primaryIdList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.raceMasterSearch.valueChanges.subscribe((value) => {
        this.raceFilterList = this.nationalityWiseRace.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );

    this.subscriptionList.add(
      this.reportResonMrdSearch.valueChanges.subscribe((value) => {
        this.reportReasonFilterdList = this.reportReasonList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );

    this.subscriptionList.add(
      this.payerTypeSearch.valueChanges.subscribe((value) => {
        this.payerTypeList = this.payerTypeFilteredList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.payerSearch.valueChanges.subscribe((value) => {
        this.dataSourcePayers.data.forEach((element, index) => {
        element.payerList = element.payerFilteredList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    })
    );
    this.subscriptionList.add(
      this.contractCodeSearch.valueChanges.subscribe((value) => {
        this.dataSourcePayers.data.forEach((element, index) => {
          element.contractList = element.contractCodeList.filter((i) =>
            i.desc.toString().toLowerCase().includes(value.toLowerCase())
          );
        });
      })
    );
    this.subscriptionList.add(
      this.associatedCompanySearch.valueChanges.subscribe((value) => {
        this.associatedCompanyList = this.associatedCompanyCodeList.filter(
          (i) => i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.tariffSearch.valueChanges.subscribe((value) => {
        this.tariffList = this.tariffFilteredList.filter((i) =>
          i.desc.toString().toLowerCase().includes(value.toLowerCase())
        );
      })
    );
    this.subscriptionList.add(
      this.formNextOfKin.controls.state.valueChanges.subscribe((val) => {
        this.getNOKCityList(val);
      })
    );
    this.subscriptionList.add(
      this.formNextOfKin.controls.country.valueChanges.subscribe((val) => {
        this.getNOKStateList(val);
      })
    );

    this.subscriptionList.add(
      this.formRegistration.controls.employerName.valueChanges.subscribe(
        (res) => {
          if (res == "Others") {
            this.employer = true;
          } else {
            this.employer = false;
          }
        }
      )
    );
    if(this.isNationDependant){
      this.formRegistration.controls.nation.valueChanges.subscribe(value => {
        value === undefined ? this.nationalityWiseRace = [] : null;
      });
    }
  }

  /**
   *secondaryIdentification onchange check multiple conditions
   *
   * @param {string} secIdTypeCode
   * @memberof PatientRegistrationComponent
   */
  secondartyIdentificationOnChange(secIdTypeCode: any) {
    let formRegistration = this.formRegistration.controls;
    if (secIdTypeCode) {
      let secondaryIdCode = this.secondaryIdFilteredList.find(
        (s) => s.code == secIdTypeCode.value
      );
      // let secondaryIdCode = formRegistration.secondaryId.value;
      if (secondaryIdCode) {
        if (secondaryIdCode.isMyKad == true) {
          formRegistration.nation.setValue("MALAYSIAN");
          this.secondaryIdeRegex = "^[0-9 -]+$";
        } else {
          this.secondaryIdeRegex = secondaryIdCode.validation
            ? secondaryIdCode.validation
            : "";
        }
      }
      if (!this.selectedPatient) {
        if (secondaryIdCode.isExpireReq) {
          formRegistration.secondaryExpirydate.enable();
          this.isSeondaryExpiryDate = true;
        } else {
          formRegistration.secondaryExpirydate.setValue("");
          formRegistration.secondaryExpirydate.disable();
          this.isSeondaryExpiryDate = false;
        }

        this.secondaryNumberOnKeyUp(formRegistration.secondaryNumber.value);
      }
    }
  }

  /**
   *primaryIdentification onchange check multiple conditions
   * @param idTypeCode
   */
  primaryIdentificationOnChange(idTypeCode: any) {
    let formRegistration = this.formRegistration.controls;
    if (idTypeCode) {
      let primaryIdCode = this.primaryIdFilteredList.find(
        (s) => s.code == idTypeCode.value
      );
      // let primaryIdCode = formRegistration.primaryId.value;
      if (primaryIdCode) {
        if (primaryIdCode.isMyKad == true) {
          formRegistration.nation.setValue("MALAYSIAN");
          this.primaryIdRegex = "^[0-9 -]+$";
        } else {
          this.primaryIdRegex = primaryIdCode.validation
            ? primaryIdCode.validation
            : "";
        }
      }
      // if (!this.selectedPatient) {
      if (primaryIdCode.isExpireReq) {
        formRegistration.expiryDate.enable();
        this.isexpiryDate = true;
      } else {
        formRegistration.expiryDate.setValue("");
        formRegistration.expiryDate.disable();
        this.isexpiryDate = false;
      }

      this.primaryNumberOnKeyUp(formRegistration.primaryNumber.value);
      // }
      this.mandatoryField.issuingCountry = primaryIdCode ? primaryIdCode.isCountryRequired : false;
    }
    this.primaryIdSelect(idTypeCode.value);
  }

  /**
   * primaryNumber onchange check multiple conditions
   * @param value
   */
  primaryNumberOnKeyUp(value: string) {
    try {
      let formRegistration = this.formRegistration.controls;
      let primaryIdCode = formRegistration.primaryId.value;

      value = value.replace(/\s/g, '');
      formRegistration.primaryNumber.setValue(value);
      if (primaryIdCode) {
        if (primaryIdCode.isMyKad) {
        }

        let primaryIdentify = this.primaryIdList.find(
          (i) => i.code == primaryIdCode
        );
        if (primaryIdentify) {
          if (primaryIdentify.isMyKad) {
            var r = /(\D+)/g,
              npa = "",
              nxx = "",
              last4 = "";
            value = value.replace(r, "");
            var c: string = value;
            npa = value.substr(0, 6);
            nxx = value.substr(6, 2);
            last4 = value.substr(8, 4);
            value = npa + "-" + nxx + "-" + last4;
            formRegistration.primaryNumber.setValue(value ? value : "");
            this.primaryIdRegex = "^[0-9 -]+$";
          } else if (primaryIdentify.isMyKad == false) {
            this.primaryIdRegex = "[A-Za-z0-9]*";
            formRegistration.primaryNumber.setValue(value ? value : "");
          }

          if (value.length >= 12 && primaryIdentify.isMyKad) {
            const array = Array.from(c);
            const yearSuffix = parseInt(array[0] + array[1], 10);
            const cal_month = parseInt(array[2] + array[3], 10);
            const cal_day = parseInt(array[4] + array[5], 10);
            const contry = array[6] + array[7];
            const gen_val = array[11];
  
            BirthPlaceList.forEach(element => {
              if (element.birthPlaceNumber == contry) {
                this.formRegistration.patchValue({
                  birthplace: element.birthPlaceName
                });
              }
            });

            const lastDigit = Number(gen_val);
            let genderVal = lastDigit % 2 == 0 ? STATICCODESERVICE.GENDER_MASTER_FEMALE : STATICCODESERVICE.GENDER_MASTER_MALE;
            this.genderValue = this.patientRegService.getCodeByStaticCode(this.genderList, genderVal);
  
            var currentYear = new Date().getFullYear();
            var currentYearSuffix = currentYear % 100;
  
            let yr;
            if (yearSuffix > currentYearSuffix) {
              yr = 1900 + yearSuffix;
            } else {
              yr = 2000 + yearSuffix;
            }
  
            var birthDate = new Date(yr, cal_month - 1, cal_day);
            var today = new Date();
  
            if (birthDate > today && !this.isSameDate(birthDate, today)) {
              yr -= 100;
              birthDate = new Date(yr, cal_month - 1, cal_day);
            }
            
            if (this.isValidDate(yr, cal_month, cal_day)) {
              formRegistration.dateOfBirth.setValue(birthDate);
              formRegistration.realdob.setValue(true);
            } else {
              this.snackBar.open(
                this.langService.get("CommonAppoinment.InvalidNRIC"),
                this.langService.get("CommonAppoinment.validation"),
                { duration: 1000 }
              );
              formRegistration.primaryNumber.setValue(null);
              formRegistration.birthplace.setValue(null);
              this.genderValue = "";
              formRegistration.dateOfBirth.setValue(null);
              formRegistration.realdob.setValue(false);
            }
          }
        }
      }
    } catch (ex) {}
  }

  /**
   *
   *
   * @param {*} date1
   * @param {*} date2
   * @return {*} 
   * @memberof PatientRegistrationComponent
   */
  isSameDate(date1, date2) {
    return date1.getFullYear() == date2.getFullYear() &&
           date1.getMonth() == date2.getMonth() &&
           date1.getDate() == date2.getDate();
  }

  /**
   *
   *
   * @param {*} year
   * @return {*} 
   * @memberof PatientRegistrationComponent
   */
  isLeapYear(year) {
    return (year % 4 == 0 && year % 100 != 0) || (year % 400 == 0);
  }
  
  /**
   *
   *
   * @param {*} year
   * @param {*} month
   * @param {*} day
   * @return {*} 
   * @memberof PatientRegistrationComponent
   */
  isValidDate(year, month, day) {
    if (month < 1 || month > 12) {
      return false;
    }
  
    let daysInMonth;
    if (month == 2) {
      daysInMonth = this.isLeapYear(year) ? 29 : 28;
    } else if ([4, 6, 9, 11].includes(month)) {
      daysInMonth = 30;
    } else {
      daysInMonth = 31;
    }
    return day > 0 && day <= daysInMonth;
  }

  /**
   *
   *
   * @param {string} value
   * @memberof PatientRegistrationComponent
   */
  secondaryNumberOnKeyUp(value: string) {
    try {
      let formRegistration = this.formRegistration.controls;
      let secondaryIdCode = formRegistration.secondaryId.value;
      value = value.replace(/\s/g, '');
      formRegistration.secondaryNumber.setValue(value);
      if (secondaryIdCode) {
        if (secondaryIdCode.isMyKad) {
        }

        let secondaryIdentify = this.secondaryIdList.find(
          (i) => i.code == secondaryIdCode
        );
        if (secondaryIdentify) {
          if (secondaryIdentify.isMyKad == true) {
            var r = /(\D+)/g,
              npa = "",
              nxx = "",
              last4 = "";
            value = value.replace(r, "");
            var c: string = value;
            npa = value.substr(0, 6);
            nxx = value.substr(6, 2);
            last4 = value.substr(8, 4);
            value = npa + "-" + nxx + "-" + last4;
            formRegistration.secondaryNumber.setValue(value ? value : "");
            this.secondaryIdeRegex = "^[0-9 -]+$";
          } else if (secondaryIdentify.isMyKad == false) {
            this.secondaryIdeRegex = "[A-Za-z0-9]*";
            formRegistration.secondaryNumber.setValue(value ? value : "");
          }
        }
      }else{
        this.secondaryIdeRegex = "([a-zA-Z0-9,'\-._/!@#$%^&*\s]+)";
      }
    } catch (ex) {}
  }

  /**
   *set gender value
   *
   * @param {*} prefixvalue
   * @memberof PatientRegistrationComponent
   */
  getGender(prefixvalue) {
    let formRegistration = this.formRegistration.controls;
    let res =
      prefixvalue && prefixvalue.genderMaster
        ? prefixvalue.genderMaster.code
        : "";
    if (res) {
      this.genderValue = res;
      formRegistration.gender.disable();
    } else {
      this.genderValue = this.defaultGender ? this.defaultGender.code :this.genderValue ;
      formRegistration.gender.enable();
    }
  }

  /**
   * It will be triggered on value set to primary id
   * @param event selected value
   * @memberof PatientRegistrationComponent
   */
  checkforExpiry(event) {
    let formRegistration = this.formRegistration.controls;
    if (event.code && event.code.isExpireReq) {
      formRegistration.expiryDate.enable();
      this.isexpiryDate = true;
    } else {
      formRegistration.expiryDate.setValue("");
      formRegistration.expiryDate.disable();
      this.isexpiryDate = false;
    }
  }

  /**
   *validation primary number
   *
   * @param {string} value
   * @memberof PatientRegistrationComponent
   */
  onFocusOutEvent(value: string) {
    this.validationOnPrimarynumber(value);
    if (this.patientId) {
      if (this.selectedPatient.id) this.primaryIdentifierListModified = true;
    }

    let primaryIdCode = this.formRegistration.get("primaryId").value;
    if (primaryIdCode) {
      if (primaryIdCode.isMyKad && value.length < 14)
        this.formRegistration.controls["primaryNumber"].setErrors({
          incorrect: true,
        });
    }
  }

  onFocusOutEventOnKey(value: string) {
    let mrn = this.selectedPatient ? this.selectedPatient.mrn : "";
    let tokenNo = value ? value : "";
    if (mrn == "" && tokenNo == "") return false;
    this.generalApiService
      .getTypeApi(
        OPD_API_URL.PATIENT_INTEGRATION_SERVICE,
        "tokenNo=" + tokenNo + "&mrnNo=" + mrn
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res: any) => {
        if (res.message !== "") {
          this.snackBar.open("Info", res.message, {
            duration: 3000,
          });
          this.isToken = false;
          this.tokenNoValue = "";
        } else {
          this.isToken = true;
        }
      });
  }

  /**
   *patient notes
   *
   * @param {string} value
   * @memberof PatientRegistrationComponent
   */
  onFocusOutEventUpdate(value: string) {
    if (this.patientId) {
      if (this.selectedPatient.id && value == "")
        this.secondaryIdentifierListModified = true;
      else this.patientNotesUpdated = true;
    }
  }

  /**
   *validation primary number
   *
   * @param {string} value
   * @memberof PatientRegistrationComponent
   */
  validationOnPrimarynumber(value: string) {
    if (value) {
      let input = value;
      this.output = input.replace(/-/g, '');
    }
    if (!this.selectedPrimaryId || this.selectedPrimaryId !==  this.output) {
    // if (this.patientId == "" && value.length > 0) {
      let primaryIdCode = this.formRegistration.controls.primaryId.value;
      if (primaryIdCode) {
        this.generalApiService
          .getTypeApi(
            OPD_API_URL.OPD_SERVICE,
            OPD_API_URL.OPD_DUPLICATE + primaryIdCode + "&value=" + value.replace(/-/g, '')
          )
          .pipe(takeUntil(this._onDestroy))
          .subscribe((response:any) => {
            if(response && response.status==409){
              this.formRegistration.controls.primaryNumber.setValue(null);
            }else{
              this.formRegistration.controls.primaryNumber.setValue(value);
            }
          });
      }
    }
  }

  /**
   *calculate DOB
   *
   * @param {string} age
   * @param {string} month
   * @memberof PatientRegistrationComponent
   */
  // calculateDob(value: number, type: string) {
  //   let formRegistration = this.formRegistration.controls;
  //   if (type == "year") this.patientAge.years = value;

  //   if (type == "month") this.patientAge.months = value;

  //   if (type == "day") this.patientAge.days = value;

  //   if (
  //     this.patientAge.years > 0 &&
  //     this.patientAge.years < 151 &&
  //     this.patientAge.months > 0 &&
  //     this.patientAge.months < 13 &&
  //     this.patientAge.days > 0 &&
  //     this.patientAge.days < 32
  //   ) {
  //     var birthDate = moment()
  //       .subtract(Number(this.patientAge.years), "years")
  //       .subtract(Number(this.patientAge.months), "months")
  //       .subtract(Number(this.patientAge.days), "days");
  //     let normalDate = birthDate.toDate();
  //     formRegistration.dateOfBirth.setValue(normalDate);
  //     formRegistration.realdob.setValue(true);
  //   }else{
  //     formRegistration.dateOfBirth.setValue(null);
  //   }
  // }

  /**
   *delete kin details
   *
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  deleteNextOfKin(element) {
    if (element.id) {
      let id = element.id ? element.id : "";
      this.appBaseService.setResourceURL(OPD_API_URL.OPD_SERVICE);
      this.subscriptionList.add(
        this.appBaseService
          .getResource(OPD_API_URL.DELETE_NOK + "id=" + id)
          .subscribe((res) => {
            this.snackBar.open("Next of Kin Deleted", "Success", {
              duration: 2000,
            });
            const index: number = this.nokList.indexOf(element);

            if (index != -1) {
              this.nokList.splice(index, 1);
              this.dataSourceNextOfKin = new MatTableDataSource(this.nokList);
            }
          })
      );
    } else {
      const index: number = this.nokList.indexOf(element);

      let list = this.dataSourceNextOfKin.data;
      list.splice(index, 1);
      this.dataSourceNextOfKin = new MatTableDataSource<any>(list);
    }
  }

  /**
   *mobile format
   *
   * @param {string} value
   * @param {string} name
   * @memberof PatientRegistrationComponent
   */
  mobileFormat(value: string, name: string) {
    var r = /(\D+)/g,
      npa = "",
      last4 = "";
    value = value.replace(r, "");
    npa = value.substr(0, 3);
    last4 = value.substr(3, 10);
    value = npa + "-" + last4;
    if (value.replace("-", "").trim() == "") value = "";
    if (name == "mobile") {
      this.formRegistration.controls.mobileNumber.setValue(value);
    } else if (name == "home") {
      this.formRegistration.controls.homeContact.setValue(value);
    } else if (name == "work") {
      this.formRegistration.controls.workContact.setValue(value);
    }
  }

  /**
   * To load passcode on district select
   *
   * @param {object} event
   */
  primaryDistrictSelected(event) {
    if (event.postCode)
      this.formRegistration.patchValue({
        pincodePrimary: event.postCode,
      });
  }

  /**
   * To load passcode on district select
   *
   * @param {object} event
   */
  secondaryDistrictSelected(event) {
    if (event.postCode)
      this.formRegistration.patchValue({
        pincodeSecondary: event.postCode,
      });
  }

  /**
   * To reset registeration form
   */
  resetRegisterationForm() {
    this.patientForm = !this.patientForm;
    this.formRegistration.reset();
    this.patientImage = "";
    this.genderValue = null;
    this.raceValue = null;
    this.religionValue = null;
    this.countryValue = null;
    this.stateValue = null;
    this.cityValue = null;
    this.countrySecondaryValue = null;
    this.stateSecondaryValue = null;
    this.citySecondaryValue = null;
    this.districtSeoncdaryValue = null;
    this.initSearchFilter();
  }

  /**
   *Passing cityCode to get the subDistrictList
   *
   * @param {*} city as object
   * @memberof PatientRegistrationComponent
   */
  getSubDistrictForNOK(city) {
    if (city) {
      this.nokCityCode = {
        field: OPD_API_URL.M_CITY_VALUE,
        value: city.id,
      };
    }
  }

  /**
   * To load pincode based on district
   * @param district as object
   * @memberof PatientRegistrationComponent
   */
  NOKDistrictSelected(district) {
    this.formNextOfKin.patchValue({
      postcode: district.postCode,
    });
  }

  /**
   *Validation of Add Health Tourist tab
   *
   * @return {*}
   * @memberof PatientRegistrationComponent
   */
  addItemValidate() {
    var isvalid = true;

    this.formHealthTourist.controls.identificationNumber.markAsTouched();
    this.formHealthTourist.controls.agentName.markAsTouched();
    this.formHealthTourist.controls.startDate.markAsTouched();
    this.formHealthTourist.controls.endDate.markAsTouched();
    this.formHealthTourist.controls.identificationNumber.markAsTouched();
    this.formHealthTourist.controls.contactNo.markAsTouched();

    if (!this.formHealthTourist.valid) {
      this.snackBar.open(
        this.langService.get("CommonAppoinment.pleasefillallmandatory"),
        this.langService.get("CommonAppoinment.validation"),
        {
          duration: 1000,
        }
      );
      isvalid = false;
    }
    if (!this.formHealthTourist.controls.emailId.valid) {
      this.snackBar.open(this.langService.get("CommonAppoinment.PleaseEnterValid"),  this.langService.get("CommonAppoinment.validation"), {
        duration: 2000,
      });
      isvalid = false;
    }
    return isvalid;
  }

  /**
   *get nok list after search
   *
   * @memberof PatientRegistrationComponent
   */
  getNOKList() {
    this.nokList = [];
    this.appBaseService.setResourceURL(OPD_API_URL.OPD_SERVICE);
    this.subscriptionList.add(
      this.appBaseService
        .getResource(OPD_API_URL.GET_NOK_LIST + "patientId=" + this.patientId)
        .subscribe((res) => {
          if (res) {
            res.forEach((element) => {
              if (element.active == true) {
                let nextOfKin: PatientNextOfKin =
                  this.patientRegService.getNextOfKinValues(this, element);
                this.nokList.push(nextOfKin);
              }
            });
            this.dataSourceNextOfKin = new MatTableDataSource<any>(
              this.nokList
            );
          }
        })
    );
  }

  /**
   *
   *
   * @param {*} event
   * @param {*} value
   * @memberof PatientRegistrationComponent
   */
  getNokforSave(event, value) {
    if (event.checked) {
      value.active = true;
      this.kinData.push(value);
    } else {
      value.active = false;
      const indexToRemove = this.kinData.findIndex((nok) => nok.name === value.name);
      if (indexToRemove !== -1) {
        this.kinData.splice(indexToRemove, 1);
      }
    }
    const existingIndex = this.dataSourceNextOfKin.data.findIndex((nok) => nok.name === value.name);
    if (existingIndex !== -1) {
      this.dataSourceNextOfKin.data[existingIndex] = value;
    } else {
      this.dataSourceNextOfKin.data.push(value);
    }
  }
  
  /**
   *
   *
   * @param {*} element
   * @memberof PatientRegistrationComponent
   */
  setGuarantorValue(element) {
    if(element.checked)
    element.isGuarantor=true;
       else {
        this.snackBar.open(this.langService.get("CommonAppoinment.PleaseSelectGuarenter"), this.langService.get("CommonAppoinment.validation"), {
          duration: 2000,
        });
      }
  }

  /**
   *Add Values In Health Tourist Tab
   *
   * @param {*} value
   * @memberof PatientRegistrationComponent
   */
  addHealthTourist(value) {
    if (this.addItemValidate()) {
      if (value) {
        const currentDate = new Date();
        const formattedCurrentDate = currentDate.toLocaleString();
        value.currentDate = formattedCurrentDate;
        this.healthTouristList.push(value);
        this.healthTouristSource = new MatTableDataSource<any>(
          this.healthTouristList
        );
        this.formHealthTourist.reset();
      }
    }
    return this.formHealthTourist.valid;
  }

  /**
   *
   *
   * @param {*} value
   * @memberof PatientRegistrationComponent
   */
  deleteHealthTourist(value) {
    if (this.patientId) {
      this.appBaseService.setResourceURL(OPD_API_URL.OPD_SERVICE);
      this.subscriptionList.add(
        this.appBaseService
          .deleteResource(
            OPD_API_URL.DELETE_HEALTH_TOURIST +
              "healthTouristId=" +
              value.healthTouristId +
              "&id=" +
              this.patientId
          )
          .subscribe((res) => {
            this.snackBar.open(
              res.message,
              this.langService.get("CommonAppoinment.success"),
              {
                duration: 1000,
              }
            );
            const index: number = this.healthTouristList.indexOf(value);

            if (index != -1) {
              this.healthTouristList.splice(index, 1);
              this.healthTouristSource = new MatTableDataSource(
                this.healthTouristList
              );
            }
          })
      );
    } else {
      const index: number = this.healthTouristList.indexOf(value);

      if (index != -1) {
        this.healthTouristList.splice(index, 1);
        this.healthTouristSource = new MatTableDataSource(
          this.healthTouristList
        );
      }
    }
  }

  /**
   *
   *
   * @param {number} epoch
   * @return {*}  {string}
   * @memberof PatientRegistrationComponent
   */
  epochToDateConverter(epoch: number): string {
    try {
      const epochString = epoch.toString().replace(/\D/g, "");
      const length = epochString.length;
      if (length == 10) {
        var d = new Date(0);
        d.setUTCSeconds(epoch);
      } else {
        var d = new Date(0);
        d.setUTCMilliseconds(epoch);
      }
      const datePipe = new DatePipe("en-US");
      const formattedDate = datePipe.transform(
        d,
        "EEE, LLLL dd, yyyy , hh:mm a"
      );
      return formattedDate;
    } catch (ex) {
      return "";
    }
  }

  /**
   *
   *
   * @param {*} value
   * @memberof PatientRegistrationComponent
   */
  selectNationality(value) {
    this.appBaseService.setResourceURL(OPD_API_URL.MASTER_SERVICE);
    this.appBaseService
      .getResource(MASTERSSERVICES.NATIONALITY_WISE_RACE_MASTER + value.code)
      .subscribe((res) => {
        this.nationalityWiseRace = res;
      });
      if(this.raceValue)
      {
      this.formRegistration.patchValue({
        race: this.raceValue
      });
    }
  }

  /**
   *
   *
   * @param {*} event
   * @memberof PatientRegistrationComponent
   */
  searchByPostCode(event) {
    if (event.checked) {
      this.isGetAddress = event.checked;
    } else {
      this.isGetAddress = false;
    }
  }

  /**
   *get Addess By PostCode
   *
   * @param {*} value
   * @memberof PatientRegistrationComponent
   */
  getAddressByPostCode(value) {
    let stringValue = value.toString();
    if (stringValue.length >= 4 && this.isGetAddress) {
      let postCode = value ? value : 0;
      this.appBaseService.setResourceURL(OPD_API_URL.MASTER_SERVICE);
      this.appBaseService
        .getResource(OPD_API_URL.GET_ADDRESS_BY_POSTCODE + postCode)
        .subscribe((res) => {
          if (res.payload != null) {
            this.isAddeassByPostCode = true;
            this.getCountryValue = res.payload.country
              ? res.payload.country.code
              : "";
            this.countryValue = this.getCountryValue;
            this.getStateValue = res.payload.state ? res.payload.state : "";
            this.stateValue = this.getStateValue.code;
            this.getCityValue = res.payload.city ? res.payload.city : "";
            this.getDistrictValue = res.payload.area ? res.payload.area : "";

            this.getStatePrimaryList(this.getCountryValue, true);
          } else {
            if(this.formRegistration.controls.statePrimary.value ||
              this.formRegistration.controls.cityPrimary.value)
            this.checkAddressRemove();
          else
            this.snackBar.open("Passcode Not Found.", "Error", { duration: 1000 });
          }
        });
    }
  }
  checkAddressRemove(){
    const dialogRef = this.dialog.open(ConfirmDynamicDialogComponent, {
      width: "15%",
      data: { displayText: "Passcode not found. Do you want to clear the address field state, city and area?"},
    });
    this.subscriptionList.add(
      dialogRef.afterClosed().subscribe((result) => {
        if (result && result.confirm == "yes") {
          this.countryValue = 0;
          this.stateValue = null;
          this.cityValue = null;
          this.districtValue = null;
          this.formRegistration.controls.statePrimary.reset();
          this.formRegistration.controls.cityPrimary.reset();
          this.formRegistration.controls.subDistrictPrimary.reset();
        }
      })
    );
  }
  /**
   *
   *
   * @param {*} mrn
   * @memberof PatientRegistrationComponent
   */
  getPreviousPayerList(mrn) {
    this.existingPayerList = [];
    let glattachmentList = [];
   
    this.appBaseService.setResourceURL(OPD_API_URL.OPD_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.OPD_PAYER_ALL_DETAILS + mrn+"&visitType="+"OP")
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res.length > 0) {
          res.forEach((ele) => {
            let referralNoList = [];
            glattachmentList = ele.glAttachmentList
              ? ele.glAttachmentList
              : null;
            if (glattachmentList && glattachmentList.length > 0) {
              glattachmentList.forEach((element) => {
                let list = {
                  glrefno: element.glAttachmentNo ? element.glAttachmentNo : "",
                  glbalance: element.availableBal ? element.availableBal : 0,
                  effectiveDate: element.effectiveDate
                    ? element.effectiveDate
                    : "",
                  expiryDate: element.expiryDate ? element.expiryDate : "",
                
                  glTypeCode: element.glType ? element.glType.code : "",
                  polictyNo: element.glReferenceNo ? element.glReferenceNo : "",
                };
                referralNoList.push(list);
              });
            }
            let obj = {
              payerTypeCode: ele.payerType ? ele.payerType.code : "",
              payerCode: ele.payer ? ele.payer.code : "",
              tariffCode: ele.tariff ? ele.tariff.code : "",
              glTypeCode:
              ele.company && ele.company.isAutoApproval
                  ? statisGlApprovalType.AG
                  : statisGlApprovalType.PG,
              effectiveDate:
                referralNoList && referralNoList.length > 0
                  ? referralNoList[0].effectiveDate
                  : "",
              expiryDate:
                referralNoList && referralNoList.length > 0
                  ? referralNoList[0].expiryDate
                  : "",
              isexpirypayerDate:true,
              glAmount:
                referralNoList && referralNoList.length > 0
                  ? referralNoList[0].glbalance
                  : "",
              glReferenceNo:
                referralNoList && referralNoList.length > 0
                  ? referralNoList[0].glrefno
                  : "",
              policyNumber:
                referralNoList && referralNoList.length > 0
                  ? referralNoList[0].polictyNo
                  : "",
              contractCode: ele.contract ? ele.contract.code : "",
              associatedCompanyCode: ele.associatedCompany
                ? ele.associatedCompany.code
                : "",
              isSelf: ele.payerType.code === "SELF" ? true : false,
              referralNoList: referralNoList,
              priority:ele.priority
            };
            this.existingPayerList.push(obj);
          });
          this.dataSourcePayers = new MatTableDataSource<PatientEncounterPayer>(
            this.existingPayerList
          );
          this.getContractListData();
          this.getPayerList();
          this.existingPayerList.forEach((element ,i)=> {
          this.getPayerTypePayer(element.payerTypeCode,i);
          });
        }
      });
  }

  /**
   *
   *
   * @param {*} glReferenceNo
   * @param {*} index
   * @param {*} refrenceList
   * @param {*} isFromGlchange
   * @memberof PatientRegistrationComponent
   */
  glDetails(glReferenceNo, index, refrenceList,isFromGlchange) {
    if (glReferenceNo) {
      let data =
        refrenceList && refrenceList.length > 0
          ? refrenceList.find((s) => s.glrefno == glReferenceNo)
          : null;
      this.dataSourcePayers.data[index].referralNoList = refrenceList;
      this.dataSourcePayers.data[index].glTypeCode =
        data && data.glTypeCode ? data.glTypeCode : "";
      this.dataSourcePayers.data[index].effectiveDate =
        data && data.effectiveDate ? data.effectiveDate : null;
      this.dataSourcePayers.data[index].expiryDate =
        data && data.expiryDate ? data.expiryDate : null;
      this.dataSourcePayers.data[index].glAmount =
        data && data.glbalance ? data.glbalance : 0;
      this.dataSourcePayers.data[index].policyNumber =
        data && data.polictyNo ? data.polictyNo : "";
        if(!isFromGlchange)
        this.dataSourcePayers.data[index].glReferenceNo=
        refrenceList && refrenceList.length > 0
          ? refrenceList[0].glrefno
          : ""
    } else {
      this.dataSourcePayers.data[index].glTypeCode = statisGlApprovalType.PG;
      this.dataSourcePayers.data[index].effectiveDate = null;
      this.dataSourcePayers.data[index].expiryDate = null;
      this.dataSourcePayers.data[index].glAmount = 0;
      this.dataSourcePayers.data[index].policyNumber = "";
    }
  }

  /**
   *
   *
   * @param {*} mrn
   * @param {*} payerCode
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  getGlDetailsByPayer(mrn, element, index) {
    let glattachmentList = [];
    let referralNoList = [];
    this.appBaseService.setResourceURL(OPD_API_URL.OPD_SERVICE);
    this.appBaseService
      .getResource(
        OPD_API_URL.OPD_PAYER_ALL_DETAILS_PAYERCODE +
          mrn +
          "&payerCode=" +
          element.payerCode+"&visitType="+"OP"
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if (res.length > 0) {
          res.forEach((ele) => {
            glattachmentList = ele.glAttachmentList
              ? ele.glAttachmentList
              : null;
            if (glattachmentList && glattachmentList.length > 0) {
              glattachmentList.forEach((element) => {
                let list = {
                  glrefno: element.glAttachmentNo ? element.glAttachmentNo : "",
                  glbalance: element.availableBal ? element.availableBal : 0,
                  effectiveDate: element.effectiveDate
                    ? element.effectiveDate
                    : "",
                  expiryDate: element.expiryDate ? element.expiryDate : "",
                  glTypeCode: element.glType ? element.glType.code : "",
                  polictyNo: element.glReferenceNo ? element.glReferenceNo : "",
                };
                referralNoList.push(list);
              });
              this.glDetails(referralNoList[0].glrefno, index, referralNoList,false);
            } else {
              let selectedPayer=element.payerList.find(e => e.code == element.payerCode );
              this.dataSourcePayers.data[index].glTypeCode = selectedPayer?.isAutoApproval ? statisGlApprovalType.AG :statisGlApprovalType.PG;
              this.dataSourcePayers.data[index].effectiveDate = null;
              this.dataSourcePayers.data[index].expiryDate = null;
              this.dataSourcePayers.data[index].glAmount = 0;
              this.dataSourcePayers.data[index].policyNumber = "";
              this.dataSourcePayers.data[index].referralNoList = [];
            }
          });
        }
      });
  }

  /**
   *
   *
   * @param {*} contractCode
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  getTariffContract(contractCode,index){
    this.contractValue = this.dataSourcePayers.data[index].contractList.find(i => i.code === contractCode);
    if (this.contractValue.tariffMaster) {
      this.getTariffList(index); 
    }
  }

  /**
   *
   *
   * @param {*} payerCode
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  getContractPayer(payerCode,index){
    this.dataSourcePayers.data[index].contractList = [];
    this.dataSourcePayers.data[index].contractCodeList = [];
    let payerId =  this.dataSourcePayers.data[index].payerList.find(i=> i.code ==payerCode);
    this.appBaseService.setResourceURL(OPD_API_URL.MASTER_SERVICE);
    this.appBaseService
      .getResource(
        OPD_API_URL.GET_PAYERTYPE_TYPE  +
        "payerId="+payerId.id+"&visitTypeCode=OP"
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        let data = res;
        this.dataSourcePayers.data[index].contractList = data;
        this.dataSourcePayers.data[index].contractCodeList = data;
      })
  }

  /**
   *
   *
   * @param {*} payerCode
   * @param {*} index
   * @memberof PatientRegistrationComponent
   */
  getPayerTypePayer(payerCode,index){
    this.dataSourcePayers.data[index].payerList = []
    this.dataSourcePayers.data[index].payerFilteredList = []
    let payerTypeId = this.payerTypeList.find(i=>i.code == payerCode);
   
    this.appBaseService.setResourceURL(OPD_API_URL.MASTER_SERVICE);
    this.appBaseService
      .getResource(
        OPD_API_URL.GET_CONTRACT_PAYER  +
        payerTypeId.id
      )
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        let data = res;
        this.dataSourcePayers.data[index].payerList = data;
        this.dataSourcePayers.data[index].payerFilteredList = data;
      })
  }

  /**
   *
   *
   * @param {*} event
   * @memberof PatientRegistrationComponent
   */
  setPatternToMobileNumber(event){
    if (event.regex) {
      const regexPattern = event.regex.replace(/^\/|\/$/g, '');
      this.formRegistration.controls.mobileNumber.setValidators([
        Validators.required,
        Validators.pattern(new RegExp(regexPattern))
      ]);
      const matches = event.regex.toString().match(/\{(\d+),(\d+)\}/);
      if (matches && matches.length > 1) {
        this.mobileNoMax = Number(matches[2]);
        this.mobileNoMin = Number(matches[1]);
      }else{
      this.formRegistration.controls.mobileNumber.clearValidators();
      }
    } else {
      this.formRegistration.controls.mobileNumber.clearValidators();
      this.formRegistration.controls.mobileNumber.setValidators([
        Validators.required,
      ]);
    }
    this.formRegistration.controls.mobileNumber.updateValueAndValidity();
  }

  // checkNumberValid(){
  //   if(!this.formRegistration.controls.mobileNumber.valid){
  //     this.snackBar.open("Please enter valid mobile number","validation",{duration:1000});
  //   }
  // }
  
  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  checkValidEmployee(){
    if(this.isStaffActive){
      let employyeType=this.formRegistration.controls.secondaryId.value
      let employeeNumber=this.formRegistration.controls.secondaryNumber.value;

      if(employyeType == secondIdType.Staff || employyeType == secondIdType.Doctor){
        if (employyeType && employeeNumber) {
          this.appBaseService.setResourceURL(OPD_API_URL.MASTER_SERVICE);
          this.appBaseService
            .getResource(OPD_API_URL.CHECK_EMPLOYEE_VALID+"type="+employyeType+"&employeeNo="+employeeNumber)
            .pipe(takeUntil(this._onDestroy))
            .subscribe((res) => {
            if(!res.payload){
              this.snackBar.open(res.message,"Error",{duration: 2000,});
              this.isEncounterAllow=true; 
              document.getElementById("validate").style.border=" 1px solid red";
              document.getElementById("validate").style.borderRadius="5px";
            }else{
              this.isEncounterAllow=false;
              document.getElementById("validate").style.border="";
              this.formRegistration.controls.active.setValue('true');
            }
          });
        } else{
          this.isEncounterAllow=false;
          this.formRegistration.controls.active.setValue('true');
          document.getElementById("validate").style.border="";
          this.formRegistration.controls.secondaryId.setValue('');
          this.formRegistration.controls.secondaryNumber.setValue('');
        }
      } else{
        this.isEncounterAllow=false;
        document.getElementById("validate").style.border="";
        this.formRegistration.controls.active.setValue('true');
      }
    }
  }
  
  /**
   *
   *
   * @param {*} value
   * @param {*} isCountryId
   * @memberof PatientRegistrationComponent
   */
  getCountryCode(value,isCountryId){
    let id;
    if(isCountryId == true){
      id=value.id;
    }else{
    let selectedCountry= this.countryPrimaryList.find(x=>x.code==value)
    id=selectedCountry.id;
    }
    if(id){
    this.appBaseService.setResourceURL(OPD_API_URL.MASTER_SERVICE);
    this.appBaseService
      .getResource(OPD_API_URL.GET_COUNTRY_CODE+id)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        if(res && res.length>0){
          this.countryCodeValue=res[0].code;
          this.setPatternToMobileNumber(res[0])
        }else{
          this.countryCodeValue="";
        }
      });
    }
  }
  
  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getPatientAuditTrails(){
    this.admissionReqload = this.PatientData;
    localStorage.setItem(
      OPD_CONST.PatientAudit,
      JSON.stringify(this.PatientData)
    );
    this.router.navigate([opdNavigation.PATIENT_AUDIT]);
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getDocTypeListForRegistration(){
    this.appBaseService.setResourceURL(MICROSERVICES.DOC_SERVICE);
    this.appBaseService
      .getResource(APPLICATIONSERVICE.DOCUMENT_TYPE_CATEGORY_PAT)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.docListRegistration = res;
      });
  }

  /**
   *
   *
   * @memberof PatientRegistrationComponent
   */
  getDocTypeListForEncounter(){
    this.appBaseService.setResourceURL(MICROSERVICES.DOC_SERVICE);
    this.appBaseService
      .getResource(APPLICATIONSERVICE.DOCUMENT_TYPE_CATEGORY_VIS)
      .pipe(takeUntil(this._onDestroy))
      .subscribe((res) => {
        this.docListEncounter = res;
      });
  }

  /**
   *
   *
   * @param {*} element
   * @memberof PatientRegistrationComponent
   */
  viewFile(element){
    const fileExtension = element.fileName.split('.').pop();
    if(fileExtension ==="xlsx" || fileExtension ==="doc"){
      this.downloadFile(element)
    }else{
      const dialogRef =this.dialog.open(ViewDocumentComponent, {
        height: "80vh",
        width: "50%",
        data:{value:element.id}
      });
      this.subscriptionList.add(
        dialogRef.afterClosed().subscribe(() => {})
      );
    }
  }

  /**
   *
   *
   * @param {*} event
   * @memberof PatientRegistrationComponent
   */
  deceasedRemove(event){
    if(!event.checked){
      this.isEncounterAllow=event.checked;
    }
  }

  /**
   *
   *
   * @param {MatTabChangeEvent} e
   * @memberof PatientRegistrationComponent
   */
  onTabChange(e: MatTabChangeEvent){
    if(e.index == 0){
      this.showDefaultDeposit = true;
    }
    else{
      this.showDefaultDeposit = false;
    }
  }
  primaryIdSelect(value){
    this.isPrimaryNoEditable=value?true:false;
  }
}

// async function generateReports(reportList, encounterid) {
//   try {
//     let promises = reportList.map(async (element) => {
//       let reqPayload = {
//         transactionId: encounterid,
//         type: OPD_REPORT_TYPE.pdf,
//         reportName: element.code,
//         isReports: false,
//       };

//       await setLocalStorageItem(element.desc, reqPayload);
//       window.open(opdNavigation.REPORT_NAVIGATE);
//     });

//     // Wait for all promises to be resolved
//     await Promise.all(promises);
//   } catch (error) {
//     console.error("Error generating reports:", error);
//   }
// }

// const setLocalStorageItem = (key: string, value: any) => {
//   return new Promise<void>((resolve) => {
//     localStorage.setItem(key, JSON.stringify(value));
//     resolve();
//   });
// };

interface Website {
  value: string;
  viewValue: string;
}
