export const QUEUE_STATUS = {
    NOSHOW: "NOSHOW",
    WAITING: "WAITING",
    SERVING: "SERVING",
    CLOSED: "CLOSED",
    HOLD: "HOLD",
    FORWARDED: "FORWARDED"
};


export const QUEUE_STATUS_CODE = {
    NOSHOW: "NS",
    WAITING: "WAT",
    SERVING: "SER",
    CLOSED: "CLS",
    HOLD: "HLD",
    FORWARDED: "FOW"
};

export const QUEUE_STEPS = {
   STEP1:"Step 1",
   SCHEDULE:'schedule',
   CHECKED:'checked',
   WALKIN_PRINT:'walkIn-PrintToken',
   SELECT_DOCTOR:'select-doctor',
   SELECT_SERVICE:'select-service',
   SELECT_DEPARTMENT:'select-department',
   PATIENT_DETAILS:'patient-details',
   NEW_PATIENT:'new-patient',
   CHECKIN_PRINT:"checkIn-PrintToken"
};
export const QUEUE_GREEET_MSG = {
    GM:"Good Morning",
    GA:"Good Afternoon",
    GE:"Good Evening",
    GN:"Good Night"
 };
 